import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert'
import XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import { SheetJSFT } from './Types';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import {CgSpinnerTwoAlt} from "react-icons/cg"
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import { toastr } from 'react-redux-toastr'

class ExcelReader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: {},
            data: [],
            cols: [],
            showSpinner: false,
            response: {
                errorMessage: "",
                failedStudents: [],
                updateStudents: []
            },
            showAddStudentModal: false,
            newStudent: { Tussenv: ""},
            classes: this.props.classes
        }
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
        this.getActiveClasses()
    }

    getActiveClasses() {
        fetch(process.env.REACT_APP_API_URL + "class",
        {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token')
            }
        }).then(response => response.json())
        .then(data => {
            console.log("Classes are collected, amount is " + data.length)
            this.setState({ classes: data })
        })
        .catch(error => {
            console.log(error)
        });
    }

    handleAddStudentModalShowHide() {
        this.setState({ showAddStudentModal: !this.state.showAddStudentModal })
    }

    handleChange(e) {
        const files = e.target.files;
        if (files && files[0]) this.setState({ file: files[0] });
    };

    handleFile() {
        this.setState({showSpinner: true})
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            /* Update state */
            this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
                console.log(this.state.data.length)

                const classes = this.state.classes
                const students = this.state.data


                var imports = students.filter(student => {
                    const matchingItem = classes.find(klas => klas.name === student.Klas)

                    return matchingItem && matchingItem.name === student.Klas
                })

                if(imports.length > 0) {
                    toastr.info('Importeren', 'Er worden ' + imports.length + ' studenten, uit ' + classes.length + ' klassen geimporteerd')
                    this.importStudents(JSON.stringify(this.state.data, null, 2))
                } else {  
                    toastr.warning('Importeren', 'Er geen studenten gevonden, uit ' + classes.length + ' klassen')
                }

                //
                // console.log(JSON.stringify(this.state.data, null, 2));
            });
        };

        if (rABS) {
            reader.readAsBinaryString(this.state.file);
        } else {
            reader.readAsArrayBuffer(this.state.file);
        };
    }

    importStudents(importJson) {
        fetch(process.env.REACT_APP_API_URL + "import", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            },
            body: importJson
        }).then(data => {
            return data.json()
        })
            .then(result => {
                console.log(result)    
                this.setState({showSpinner: false, response: result, newStudent: {Tussenv: ""}})                
                // toastr.success('Importeren', 'Import is succesvol gegaan. Er zijn ' + result.length + " student geupdate.")

            }).catch(err => {
                toastr.error('Importeren', 'Er is iets fouts gegan met het importeren.')
                this.setState({showSpinner: false})  
            });
    }

    onChangeAddStudent = e => {
        const name = e.target.name
        const val = e.target.value

        var newStudent = this.state.newStudent

        newStudent[name] = val

        this.setState({newStudent: newStudent})
    }

    addNewStudentSubmitHandler = (event) => {
        event.preventDefault();
        const student = this.state.newStudent

        var fakeArray = []
        fakeArray.push(student)
        console.log(fakeArray)
        this.importStudents(JSON.stringify(fakeArray))
        this.handleAddStudentModalShowHide()

    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                <Form>
                    <Form.Group as={Row}>
                        <Form.Label>Upload hier het studentenbestand</Form.Label>
                        <Form.Control
                            type="file"
                            // className="custom-file-label"
                            id="file"
                            label={this.state.file.name}
                            onChange={this.handleChange}
                            accept={SheetJSFT}
                        />
                    </Form.Group>
                </Form>
                <Button type='submit' className="w-100"
                    onClick={this.handleFile} disabled={this.state.showSpinner}>Importeren {
                        this.state.showSpinner && (<CgSpinnerTwoAlt className="icon-spin mr-2"/>)
                      }
                </Button>
                </Col>
                    <Col><Button className="float-right" onClick={() => this.handleAddStudentModalShowHide()}>Voeg een student toe met de hand</Button></Col>
                </Row>
                <hr/>
                {
                    this.state.response.failedStudents.length > 0 || 
                    this.state.response.updateStudents.length > 0 ||
                    this.state.response.errorMessage !== "" ? (<div>
                <Row>
                    <Col>
                    <Alert variant="success">
                    <Alert.Heading>Resultaten importeren</Alert.Heading>
                        <p>
                            {
                                this.state.response.errorMessage === null || this.state.response.errorMessage === "" ? 
                                    "Er zijn totaal " + this.state.response.updateStudents.length + " aantal studenten geimporteerd. Daarnaast zijn er " + this.state.response.failedStudents.length + " fout gegaan."
                                 : (
                                    "Er is een foutopgetreden: " + this.state.response.errorMessage
                                )
                            }
                        </p>
                    </Alert>
                    </Col>
                </Row>
                <Row>
                            {
                                (this.state.response.failedStudents.length > 0) ? (

                    <Col>
                        <h4>Studenten waarbij het niet goed is gegaan</h4>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Studentnummer</th>
                                    <th>Studentnaam</th>
                                    <th>Klas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.response.failedStudents.map(student => 
                                    <tr>
                                        <td>{student["student nummer"]}</td>
                                <td>{student.roepnaam} {student.tussenv} {student.achternaam}</td>
                                        <td>{student.klas}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                    </Col>
                        ) : (
                            <Col>
                            <h4>Geen fouten van studenten</h4>
                            </Col>
                        ) 
                        }
                </Row></div>
                ) : ("")
            }


            <Modal show={this.state.showAddStudentModal}>
                <Form onSubmit={this.addNewStudentSubmitHandler}>
                    <Modal.Header closeButton onClick={() => this.handleAddStudentModalShowHide()}>
                        <Modal.Title>Student toevoegen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form.Group as={Row} controlId="studentId">
                            <Form.Label column sm={7}>
                                Student nummer*
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control type="text" required  name="student nummer" onChange={this.onChangeAddStudent} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="Roepnaam">
                            <Form.Label column sm={7}>
                                Roepnaam*
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control type="text" required  name="Roepnaam" onChange={this.onChangeAddStudent} />
                            </Col>
                        </Form.Group><Form.Group as={Row} controlId="Tussenv">
                            <Form.Label column sm={7}>
                                Tussenvoegsel
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control type="text"   name="Tussenv" onChange={this.onChangeAddStudent} />
                            </Col>
                        </Form.Group><Form.Group as={Row} controlId="Achternaam">
                            <Form.Label column sm={7}>
                                Achternaam*
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control type="text" required  name="Achternaam" onChange={this.onChangeAddStudent} />
                            </Col>
                        </Form.Group><Form.Group as={Row} controlId="Adres + Huisnr">
                            <Form.Label column sm={7}>
                                Adres en huisnummer
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control type="text"  name="Adres + Huisnr" onChange={this.onChangeAddStudent} />
                            </Col>
                        </Form.Group><Form.Group as={Row} controlId="Postcode">
                            <Form.Label column sm={7}>
                                Postcode
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control type="text"  name="Postcode" onChange={this.onChangeAddStudent} />
                            </Col>
                        </Form.Group><Form.Group as={Row} controlId="Plaats">
                            <Form.Label column sm={7}>
                                Plaats
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control type="text"  name="Plaats" onChange={this.onChangeAddStudent} />
                            </Col>
                        </Form.Group><Form.Group as={Row} controlId="Email Adres">
                            <Form.Label column sm={7}>
                                Schoolmail
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control type="text"  name="Email Adres" onChange={this.onChangeAddStudent} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="Telefoon mobiel">
                            <Form.Label column sm={7}>
                                Mobielnummer
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control type="text"  name="Telefoon mobiel" onChange={this.onChangeAddStudent} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="Telefoon thuis">
                            <Form.Label column sm={7}>
                                Telefoonnummer
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control type="text"  name="Telefoon thuis" onChange={this.onChangeAddStudent} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="klas">
                            <Form.Label column sm={7}>
                                Klascode*
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control type="text" className="text-uppercase" required  name="klas" onChange={this.onChangeAddStudent} />
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleAddStudentModalShowHide()}>
                            Sluit
                    </Button>
                        <Form.Group>
                            <Button type='submit' >
                                Toevoegen
                             </Button>
                        </Form.Group>
                    </Modal.Footer>
                </Form>
            </Modal>



            </div>

        )
    }
}

export default ExcelReader;