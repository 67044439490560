import React, { Component, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import { toastr } from 'react-redux-toastr'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { MdDeleteForever } from 'react-icons/md';
import { BiEdit } from 'react-icons/bi'
import PresentionsButtons from '../../components/PresentionButtons'
import PresentionModal from '../../components/presentionModal'
import LoadSymbol from '../../components/LoadSymbol'
import {Typeahead} from 'react-bootstrap-typeahead'
import Comments from '../../components/student/Comments'
import JsCookie from 'js-cookie'

class ClassDetail extends Component {

    constructor(props) {
        super(props);
        var array = window.location.pathname.split('/');

        this.state = {
            classId: array.pop(),
            class: {
                name: "",
                periods: [],
                criterions: [],
                minPointsPass: 0
            },
            students: [
                {
                    student: {},
                    joins: [],
                    tests: [],
                    sportdays: []
                }
            ],
            selectedJoin: null,
            showPresentionJoinModal: false,
            showCommentModal: false,
            showClinicJoinModal: false,
            clinics: [],
            updateClinicPeriod: {
                name: ""
            },
            updateClinicId: null,
            updateClinicSubscription: false,
            updateSelectedStudentId: null,
            DigitalTests: [],
            PhysicalTests: [],
            viewJoins: true,
            viewTests: true,
            viewDeactives: false,
            viewSportdays: true,
            sportdays: [],
            showDeactiveStudentModal: false,
            selectedDeactivateStudentId: "",
            updateJoins: [],
            showDeleteJoinModal: false,
            selectDeleteJoinId: "",
            selectedStudentId: "",
            viewPeriods: true,
            viewSportChoiceByPeriod : false
        }
    }

    componentDidMount() {
        this.getClassInfo()
        this.getAllStudents()
        this.getAllClinic()
        this.postVisit()
    }

    postVisit() {
        fetch(process.env.REACT_APP_API_URL + "monitor/lastviewclass/" + this.state.classId + "/employee/" + JsCookie.get("guid"),
            {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            })
            .then((resp) => resp.json())
            .then(data => {
                console.log(data)
            });
    }

    getClassInfo() {
        fetch(process.env.REACT_APP_API_URL + "class/" + this.state.classId,
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({ class: data })
            })
            .catch(error => {
                // localStorage.clear();
                // location.href = '/'
            });
    }

    getAllClinic() {
        fetch(process.env.REACT_APP_API_URL + "clinic/v1/all",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ clinics: data })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    getAllStudents() {
        fetch(process.env.REACT_APP_API_URL + "class/" + this.state.classId + "/students/all",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                console.log(data)

                this.setState({ students: data.data }, () => this.findSportdays())
            })
            .catch(error => {
                // localStorage.clear();
                // location.href = '/'
            });
    }

    findSportdays() {
        var students = this.state.students
        
        var sportdays =  []

        students.map(obj => {
            obj.sportdays.map(sportdayIndex => {
                if(sportdays.filter(x => x.id === sportdayIndex.sportday.id).length <= 0){
                    sportdays.push(sportdayIndex.sportday)
                }
            })
        })
        console.log(sportdays)
        this.setState({ sportdays: sportdays});
    }

    onChangeTypeAheadClinc = (e, id) =>{
        let addJoin = { ...this.state.addJoin };
        var clinic = e.pop();

        console.log(clinic)
        // addJoin["clinicId"] = clinic.id
        this.setState({updateClinicId: clinic.id });

    }

    //2 possible subjects TJLS fysiek(Physical), TJLS digitaal(Digital)
    //Return two variables presentionType
    getTestPresentionType(subject, studentId) {
        var tests = this.state.tests
        tests = tests.filter(x => x.student.id === studentId)

        if (subject == "TJLS fysiek") {
            tests = tests.filter(x => x.testType === "Physical")
        }
        if (subject == "TJLS digitaal") {
            tests = tests.filter(x => x.testType === "Digital")
        }
        // console.log(tests)
        if (tests.length > 0) {
            return (tests.pop()).presentionType
        }
    }

    getJoinByStudentPeriod(studentId, periodId) {
        fetch(process.env.REACT_APP_API_URL + "join/" + studentId + "/" + periodId + "/student",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(responseData => {
                var join = responseData.pop()
                if (typeof (join) === "undefined") {
                    toastr.warning('Probleem', 'Er is geen sportkeuze gekoppeld.')
                }

                this.setState({ showPresentionJoinModal: true, selectedJoin: join })
            })
            .catch(error => {
                // localStorage.clear();
                // location.href = '/'
            });
    }

    handleShowPresentionJoinModal(obj) {

        this.setState({
            periods: this.state.periods,
            showPresentionJoinModal: !this.state.showPresentionJoinModal,
            selectedJoin: null
        })
    }

    handlerPresentions() {
        this.handleShowPresentionJoinModal()
    }

    handleShowClinicJoinModal(studentId, period, joins) {
        this.setState({ updateSelectedStudentId: studentId, showClinicJoinModal: !this.state.showClinicJoinModal, updateClinicPeriod: period, updateJoins: joins, updateClinicSubscription: false })
    }

    handleShowDeactivateStudentModal(studentId) {
        console.log("show/hide modal")
        this.setState({ showDeactiveStudentModal: !this.state.showDeactiveStudentModal, selectedDeactivateStudentId: studentId})
    }

    handleShowDeleteJoinModal(id) {
        this.setState({ showDeleteJoinModal: !this.state.showDeleteJoinModal, selectDeleteJoinId: id })
    }

    handleShowCommentsModal(id) {
        this.setState({ showCommentModal: !this.state.showCommentModal, selectedStudentId: id })
    }

    deleteJoin() {
        var joinId = this.state.selectDeleteJoinId
        console.log("delete join: " + joinId)
        if (joinId != null) {
            fetch(process.env.REACT_APP_API_URL + "join/" + joinId,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('access_token')
                    }
                }).then(response => response.json())
                .then(data => {
                    if (data) {
                        toastr.success('Verwijderd', 'Je hebt een sportkeuze verwijderd.')
                        this.handleShowDeleteJoinModal()
                        this.handleShowClinicJoinModal("", "", [])
                        this.getAllStudents()
                    }
                })
                .catch(error => {
                    console.log(error);
                    // localStorage.clear();
                    // location.href = '/'
                });
        }
    }

    onClickUpdateJoin() {
        var clinics = this.state.clinics

        var clinic = clinics.filter(x => x.id === this.state.updateClinicId).pop()

        var obj = {
            clinic: clinic,
            isSubscription: this.state.updateClinicSubscription,
            period: this.state.updateClinicPeriod
        }

        fetch(process.env.REACT_APP_API_URL + "join/" + this.state.updateSelectedStudentId + "/student", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        }).then(data => {
            return data.json()
        }).then(result => {
            this.setState({
                periods: this.state.periods,
                showClinicJoinModal: !this.state.showClinicJoinModal
            }, () => this.getAllStudents())

            toastr.success('Gewijzigd', 'Je hebt een nieuwe sportkeuze toegewezen')

        }).catch((error) => {
            console.error(error);
        });
    }

    onChangeClinicJoinCheckbox = e => {
        var nam = e.target.name
        var val = e.target.checked

        this.setState({ [nam]: val })
    }

    onChangeViewJoins = e => {
        this.setState({ viewJoins: !this.state.viewJoins })
    }

    onChangeViewDeactivate = e => {
        this.setState({ viewDeactives: !this.state.viewDeactives })
    }

    onChangeViewTests = e => {
        this.setState({ viewTests: !this.state.viewTests })
    }

    onChangeViewSportdays = e => {
        this.setState({ viewSportdays: !this.state.viewSportdays })
    }

    onChangeViewPeriods = e => {
        this.setState({ viewPeriods: !this.state.viewPeriods })
    }

    onChangeViewSportChoiceByPeriod = e => {
        this.setState({ viewSportChoiceByPeriod: !this.state.viewSportChoiceByPeriod })
    }

    onClickDeactivateStudent(studentId) {
        fetch(process.env.REACT_APP_API_URL + "student/" + studentId + "/deactivate",
            {
                method: 'PUT',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                console.log(data)

                var studentsIndex = this.state.students
                var idx = studentsIndex.findIndex(x => x.student.id === studentId)
                studentsIndex[idx].student = data

                this.setState({ students: studentsIndex }, () => {this.handleShowDeactivateStudentModal("")})


                toastr.success('Gedeactiveerd', 'Je hebt een student gedeactiveerd')
            })
            .catch(error => {
                // localStorage.clear();
                // location.href = '/'
            });
    }

    handlerOnChangePresentionTypePhysicalTest = (id, type) => {
        console.log("Retrieve id: " + id + " changing to " + type + " for test: Physical")

        var ids = id.split('&')
        
        var obj = {
            testType: "Physical",
            presentionType: type,
            schoolYear: this.state.class.schoolYear
        }

        if(Number.isInteger(+ids[1]) === false) {
            obj.id = ids[1]
            this.updateTest(obj, ids[0])
        } else {
            console.log("New")
            this.handleAddTest(obj, ids[0])
        }

    }

    handlerChangePresentionTypeDigitalTest = (id, type) => {
        console.log("Retrieve id: " + id + " changing to " + type + " for test: Digital")

        var ids = id.split('&')
        
        var obj = {
            testType: "Digital",
            presentionType: type,
            schoolYear: this.state.class.schoolYear
        }

        if(Number.isInteger(+ids[1]) === false) {
            obj.id = ids[1]
            this.updateTest(obj, ids[0])
        } else {
            console.log("New")
            this.handleAddTest(obj, ids[0])
        }
    }

    handleAddTest(test, studentId) {
        console.log(test)
        delete test.id
        fetch(process.env.REACT_APP_API_URL + "test/" + studentId, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(test)
        }).then(data => {
            return data.json()
        }).then(result => {
            console.log(result)
            toastr.success('Verwerkt', 'De presentie is verwerkt.')
            this.getAllStudents()
        }).catch((error) => {
            console.error(error);
        });
    }

    updateTest(test, studentId) {
        fetch(process.env.REACT_APP_API_URL + "test/" + test.id + "/" + studentId, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(test)
        }).then(data => {
            return data.json()
        }).then(result => {
            console.log(result)
            toastr.success('Verwerkt', 'De presentie is verwerkt.')
            this.getAllStudents()
        }).catch((error) => {
            console.error(error);
        });
    }

    handlerOnChangePresentionTypeSportday = (idx, presention) => {
        var students = this.state.students

        var obj = idx.split('&') //[0] = index [1] = id of sportdayindex

        var student = students[obj[0]]

        var sportdayIndex = student.sportdays.filter(x => x.id === obj[1])[0]

        sportdayIndex.presentionType = presention

        this.updateSportdayPresention(sportdayIndex)
    }
    
    updateSportdayPresention(sportdayIndex) {
        fetch(process.env.REACT_APP_API_URL + "sportday/" + sportdayIndex.id + "/join", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(sportdayIndex)
        }).then(data => {
            return data.json()
        }).then(result => {
            toastr.success('Gewijzigd', 'De presentie is verwerkt.')

        }).catch((error) => {
            console.error(error);
        });

    }

    render() {

        function calcGraduatedPercentage(students, studentClass) {
            var percentage = 0
            var totalStudents = students.length
            var graduatedStudents = 0

            students.forEach(element => {
                var totalPoints = calcTotalPoints(element)
                if(totalPoints >= studentClass.minPointsPass){
                    graduatedStudents += 1
                }

            });

            return graduatedStudents / totalStudents * 100
        }
        function calcTotalPoints(studentIndex) {
            var points = 0

            //Joins
            studentIndex.joins.filter(x => x.period.isActive).map(elm => {
                points += elm.sportVisits.filter(y => y.presentionType === "IsPresent" || y.presentionType === "IsPresentWith").length
            })

            //Sportdays
            studentIndex.sportdays.filter(x => (x.presentionType === "IsPresent" || x.presentionType === "IsPresentWith") && x.schoolYear.active).map(elm => {
                points += elm.sportday.points
            })

            //Test je Leefstijl
            studentIndex.tests.filter(x => (x.presentionType === "IsPresent" || x.presentionType === "IsPresentWith") && x.schoolYear.active).map(elm => {
                points += 1
            })


            return points
        }

        function calcPoints(periodId, joins) {
            var points = 0

            joins.filter(x => x.period.id === periodId).map(elm => {
                points += elm.sportVisits.filter(y => y.presentionType === "IsPresent" || y.presentionType === "IsPresentWith").length
            })

            return points
        }
        return <Container fluid style={{width: "max-content", "min-width": "100%"}}>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100" style={{width: "max-content"}}>
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Live overzicht van klas {this.state.class.name}</h1>
                    <Row>
                        <Col>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Toon inschrijvingen"
                                checked={this.state.viewJoins}
                                onChange={this.onChangeViewJoins}
                            />
                            <Form.Check
                                type="switch"
                                id="custom-switch2"
                                label="Toon Test je Leefstijl"
                                checked={this.state.viewTests}
                                onChange={this.onChangeViewTests}
                            />               
                            <Form.Check
                                type="switch"
                                id="custom-switch3"
                                label="Toon sportdagen"
                                checked={this.state.viewSportdays}
                                onChange={this.onChangeViewSportdays}
                            />               
                            <Form.Check
                                type="switch"
                                id="custom-switch-periods"
                                label="Toon punten"
                                checked={this.state.viewPeriods}
                                onChange={this.onChangeViewPeriods}
                            />
                            <Form.Check
                                type="switch"
                                id="custom-switch-sportchoicebyperiod"
                                label="Toon punten per blok per sportkeuze"
                                checked={this.state.viewSportChoiceByPeriod}
                                onChange={this.onChangeViewSportChoiceByPeriod}
                            />              
                            <Form.Check
                                type="switch"
                                id="custom-switch1"
                                label="Toon gedeactiveerde studenten"
                                checked={this.state.viewDeactives}
                                onChange={this.onChangeViewDeactivate}
                            />
                        </Col>
                        <Col>
                            <h5>Algemene info</h5>
                            Aantal studenten: {this.state.students.length} <br/>
                            Minimaal aantal punten te behalen: {this.state.class.minPointsPass}<br/>
                            Percentage studenten die Sport op Maat hebben afgerond: {calcGraduatedPercentage(this.state.students, this.state.class)}%

                        </Col>
                    </Row>
                    <Row>

                        <Col>
                        {
                            this.state.students.length < 1 ? (<LoadSymbol/>) : (
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>Naam</th>
                                        <th>Opmerkingen</th>

                                        <th>Totaal punten</th>
                                        
                                        {
                                        this.state.viewPeriods ? (
                                        this.state.class.periods.map(periodIndex => {
                                            return (
                                                <th>{periodIndex.period.name}</th>
                                            )
                                        })
                                        ) : ""

                                        }
                                        {
                                            this.state.viewJoins ? (
                                                this.state.class.periods.map(periodIndex => {
                                                    return (
                                                        <th>Keuze {periodIndex.period.name}</th>
                                                    )
                                                })) : ""
                                        }

                                        {
                                            this.state.viewTests ? (
                                            this.state.class.criterions.map(criteria => {
                                                return (criteria.criteria.subject === 'TJLS fysiek' ? <th>{criteria.criteria.subject}</th> : (criteria.criteria.subject === 'TJLS digitaal' ? <th>{criteria.criteria.subject}</th> : ""))
                                            })
                                            ) : ("")
                                        }

                                        {
                                            this.state.viewSportdays ? (
                                            this.state.sportdays.filter(x => x.active).map(sportday => {
                                                return (<th>{sportday.name}</th>)
                                            })) : ("")
                                        }

                                        <th>Deactiveer</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.students.map((studentIndex, idx) => {
                                            var student = studentIndex.student
                                            var tests = studentIndex.tests
                                            var sportdayIndex = studentIndex.sportdays
                                            return (
                                                <tr className={!student.active ? (this.state.viewDeactives ? ("deactiveRowStyle") : "hidden") : ""}>
                                                    <td><a href={"/student/" + student.id} target='_blank'>{student.lastName} {student.insert} {student.firstName}</a></td>

                                                    {
                                                        //
                                                        // Comment
                                                        //
                                                    }
                                                    <td><BiEdit style={{ cursor: "pointer" }} onClick={() => this.handleShowCommentsModal(student.id)}/></td>

                                                    {
                                                        //
                                                        // Total points
                                                        //
                                                        <td>{calcTotalPoints(studentIndex)}</td>
                                                    }

                                                    {
                                                    //
                                                    // Points by period
                                                    //
                                                    this.state.viewPeriods ? (
                                                    this.state.class.periods.map(periodIndex => {
                                                        return (
                                                            <td className="editClassRowElement">
                                                                {
                                                                    (this.state.viewSportChoiceByPeriod == false) ? (
                                                                        <span>
                                                                   { calcPoints(periodIndex.period.id, studentIndex.joins)}
                                                                    <BiEdit style={{ cursor: "pointer", display: "none" }} className="float-right editor" onClick={() =>this.getJoinByStudentPeriod(student.id, periodIndex.period.id) } />
                                                                    </span>
                                                                    ) : (
                                                                
                                                                        studentIndex.joins.filter(x => x.period.id == periodIndex.period.id).map((obj, i) => {
                                                                            return (<span>{obj.sportVisits.length} {obj.clinic.name} <BiEdit style={{ cursor: "pointer", display: "none" }} className="float-right editor" onClick={() => this.setState({ showPresentionJoinModal: true, selectedJoin: obj })} />
                                                                            <br/></span>)
                                                                        })
                                                                    )
                                                                }
                                                                

                                                            </td>
                                                        )
                                                    })
                                                    ): ""
                                                }
                                                    {
                                                        //
                                                        //JOINS
                                                        //
                                                        this.state.viewJoins ? (
                                                            this.state.class.periods.map(periodIndex => {
                                                                return (
                                                                    <td className="editClassRowElement">
                                                                        {
                                                                            studentIndex.joins.filter(x => x.period.id === periodIndex.period.id).length <= 0 ? "Geen keuze" : (
                                                                                studentIndex.joins.filter(x => x.period.id === periodIndex.period.id).map(join => {
                                                                                    return(<p>
                                                                                        {join.clinic.name} {join.isSubscription ? " | Abonnement" : ""}                                                                        
                                                                                    </p>)
                                                                                })
                                                                                // studentIndex.joins.filter(x => x.period.id === periodIndex.period.id)[0]?.clinic.name
                                                                            
                                                                            )
                                                                        }
                                                                        <BiEdit style={{ cursor: "pointer", display: "none" }} className="float-right editor" onClick={() => this.handleShowClinicJoinModal(student.id, periodIndex.period, studentIndex.joins.filter(x => x.period.id === periodIndex.period.id))} />

                                                                    </td>
                                                                )
                                                            })) : ""
                                                    }

                                                        {
                                                            //
                                                            //TESTS
                                                            //
                                                            this.state.viewTests ? (
                                                                this.state.class.criterions.map(criteria => {
                                                                    return (criteria.criteria.subject === 'TJLS digitaal' ?
                                                                        (
                                                                            studentIndex.tests.filter(x => x.testType === "Digital").length >= 1 ?
                                                                               <td style={{minWidth: "250px"}}>
                                                                                   <PresentionsButtons
                                                                                        id={student.id + "&" + tests.filter(x => x.testType === "Digital").pop().id}
                                                                                        value={tests.filter(x => x.testType === "Digital").pop().presentionType}
                                                                                        handlerChangePresentionType={this.handlerChangePresentionTypeDigitalTest} />
                                                                                </td>
                                                                            : <td style={{minWidth: "250px"}}>
                                                                            <PresentionsButtons
                                                                                 id={student.id + "&10"}
                                                                                 value=""
                                                                                 handlerChangePresentionType={this.handlerChangePresentionTypeDigitalTest} /></td>
                                                                                )
                                                                        : 
                                                                            (criteria.criteria.subject === 'TJLS fysiek' ?
                                                                            (
                                                                                studentIndex.tests.filter(x => x.testType === "Physical").length >= 1 ?
                                                                                    <td style={{minWidth: "250px"}}>
                                                                                    <PresentionsButtons
                                                                                         id={student.id + "&" + tests.filter(x => x.testType === "Physical").pop().id}
                                                                                         value={tests.filter(x => x.testType === "Physical").pop().presentionType}
                                                                                         handlerChangePresentionType={this.handlerOnChangePresentionTypePhysicalTest} /></td>
                                                                                : <td style={{minWidth: "250px"}}><PresentionsButtons
                                                                                id={student.id + "&10"}
                                                                                value=""
                                                                                handlerChangePresentionType={this.handlerOnChangePresentionTypePhysicalTest} /></td>
                                                                                    )
                                                                            : "") 
                                                                        )
                                                                })
                                                            ) : ("")
                                                        }

                                                        {
                                                            //Sportdays
                                                            this.state.viewSportdays ? (
                                                            this.state.sportdays.filter(x => x.active).map(sportday => {
                                                                return (
                                                                        <td>
                                                                            {
                                                                                (sportdayIndex.filter(x => x.sportday.id === sportday.id && x.schoolYear.active).length > 0) ? (
                                                                                    <PresentionsButtons 
                                                                                        id={idx + "&" + sportdayIndex.filter(x => x.sportday.id === sportday.id)[0].id}
                                                                                        value={sportdayIndex.filter(x => x.sportday.id === sportday.id)[0]?.presentionType}
                                                                                        handlerChangePresentionType={this.handlerOnChangePresentionTypeSportday}
                                                                                    />
                                                                                ) : ("")
                                                                            }
                                                                        </td>
                                                                )
                                                            })) : ("")
                                                        }

                                                    <td> {
                                                        student.active ? (<MdDeleteForever onClick={() => this.handleShowDeactivateStudentModal(student.id)} />) : ""
                                                    }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>)
                        }
                        </Col>
                    </Row>
                </Col>
            </Row>

            <PresentionModal
                handlerPresentions={this.handlerPresentions.bind(this)}
                handleShowPresentionJoinModal={this.handleShowPresentionJoinModal.bind(this)}
                show={this.state.showPresentionJoinModal}
                join={this.state.selectedJoin} />

            <Modal show={this.state.showClinicJoinModal} >

                <Modal.Header>
                    <Modal.Title>Sportkeuze wijzigen voor {typeof (this.state.updateClinicPeriod) === "undefined" ? "" : this.state.updateClinicPeriod.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Table>
                        <thead>
                            <tr>
                                <th>Sportkeuze naam</th>
                                <th>Verwijderen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.updateJoins.map(join => {
                                return (
                                    <tr>
                                        <td>{join.clinic.name}</td>
                                        <td><MdDeleteForever onClick={() => this.handleShowDeleteJoinModal(join.id)}/></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>

                    <hr/>




                    <Form.Group controlId="formGridState">
                    <Form.Label>Sportkeuze toevoegen</Form.Label>
                                <Typeahead
                                        id="basic-typeahead-single"
                                        labelKey="name"
                                        onChange={this.onChangeTypeAheadClinc}
                                        options={this.state.clinics}
                                        placeholder="Selecteer een sportkeuze...."
                                        searchText="Zoeken..."
                                        emptyLabel="Helaas, er zijn geen resultaten"
                                    />
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="student heeft abonnement" name="updateClinicSubscription" onChange={this.onChangeClinicJoinCheckbox} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.handleShowClinicJoinModal("","",[])}>
                        Sluiten
        </Button>
                    <Button onClick={() => this.onClickUpdateJoin()}>
                        Toevoegen
        </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.showDeactiveStudentModal} onHide={false}>

                    <Modal.Header closeButton>
                        <Modal.Title>Student deactiveren</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Weet je het zeker dat deze student wilt deactiveren?
                        </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleShowDeactivateStudentModal("")}>
                            Sluiten
                            </Button>
                        <Button variant="danger" onClick={() => this.onClickDeactivateStudent(this.state.selectedDeactivateStudentId)}>
                            Deactiveren
                            </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.showDeleteJoinModal} onHide={false}>

            <Modal.Header closeButton>
                <Modal.Title>Koppeling met sportkeuze verwijderen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Weet je het zeker dat deze sportkeuze wilt verwijderen?<br />
                Hierbij gaan ook alle sportbezoeken verloren.<br />
                Deze mutatie kan niet meer ongedaan worden gemaakt!
                </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.handleShowDeleteJoinModal()}>
                    Sluiten
                    </Button>
                <Button variant="danger" onClick={() => this.deleteJoin()}>
                    Verwijderen
                    </Button>
            </Modal.Footer>
            </Modal>

            <Modal show={this.state.showCommentModal} size="lg">

                <Modal.Header>
                    <Modal.Title>Opmerkingen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Comments studentId={this.state.selectedStudentId}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.handleShowCommentsModal("")}>
                        Sluiten
        </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    }
}

export default ClassDetail;

