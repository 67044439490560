import * as React from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvent } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
});
export const Maps = (props) => {
  function MyComponent() {
    const map = useMapEvent('click', () => {
      map.setView(props.center, 18)
    })
    map.setView(props.center, 18)
    return null
  }
  return (
    <MapContainer center={props.center} zoom={18} scrollWheelZoom={false} style={{height: '350px'}}>
      <MyComponent />
  <TileLayer
    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker position={props.center}>
  </Marker>
</MapContainer>
  )
}