import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import {Maps} from '../components/MapContainer'

/*
Input:
object of clinic
Output:
updateClinicCallback
*/
class UpdateClinic extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clinic: this.props.clinic,
            employees: [],
            comments: this.props.clinic.comments,
            commentIndex: 0,
            coordinates: [this.props.clinic.latitude,this.props.clinic.longitude]
        }
    }

    componentWillMount() {
        this.getEmployees();
    }

    handleAddClinicChange = event => {
        const clinic = { ...this.state.clinic };

        let nam = event.target.name;
        let val = event.target.value;

        clinic[nam] = val

        if(nam == "isOnlyGPS" || nam === "isFullYear" || nam === "isVisble") {
            clinic[nam] = event.target.checked
        }


        if(clinic.zipCode !== "" && clinic.place !== "" && clinic.street !== "" && clinic.number !== ""){
            this.getGPSCoordinates(clinic.zipCode, clinic.place, clinic.street + " " + clinic.number)
        }

        this.setState({ clinic: clinic });
    }

    onChangeEmployee = e => {
        let val = event.target.value;
        if(val === "select") {return}

        const clinic = { ...this.state.clinic };

        this.state.employees.map(employee => {
            if(employee.id === val) {
                clinic.employee = employee;
                clinic.employeeId = val
                this.setState({clinic: clinic})
            }
        })
    }

    getEmployees() {
        fetch(process.env.REACT_APP_API_URL + "employee",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => this.setState({ employees: data }))
            .catch(error => {
                localStorage.clear();
                location.href = '/'
            });
    }

    addComment = event => {
        let array = this.state.comments

        array.push({ comment: "", id: this.state.commentIndex++ })

        this.setState({ comments: array });
    }

    removeComment(index) {
        console.log('test: ' + index)
        let newArray = this.state.comments.filter(item => item.id !== index)
        console.log(newArray)
        this.setState({ comments: newArray });
    }

    updateClinic = event => {
        this.setState({ updateClinicValidation: true })
        var isValid = true
        const clinic = { ...this.state.clinic };
        let comments = this.state.comments

        comments.map(item => {
            delete item.id
        })
        clinic.comments = comments

        if (clinic.name === "") { isValid = false }
        if (clinic.street === "") { isValid = false }
        if (clinic.number === "") { isValid = false }
        if (clinic.zipCode === "") { isValid = false }
        if (clinic.minSportTime === "") { isValid = false }
        if (clinic.contactPersonMail === "") { isValid = false }
        if (clinic.contactPersonName === "") { isValid = false }
        if (clinic.employee.id === "select") { isValid = false }

        if (isValid) {
            let employee = this.state.employees.filter(x => x.id == clinic.employee.id)[0]
            clinic.employee = employee

            fetch(process.env.REACT_APP_API_URL + "clinic/v1/" + clinic.id, {
                method: "PUT",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(clinic)
            }).then(data => {
                return data.json()
            }).then(result => {
                console.log(result)
                this.props.updateClinicCallback(result)
            }).catch((error) => {
                console.error(error);
            });
        }

        console.log(clinic)
    }

    handleClinicCommentChange(i, event) {
        let comments = [...this.state.comments];
        comments[i] = { comment: event.target.value, id: event.target.id };
        this.setState({ comments });
    }

    getGPSCoordinates(zipCode, place, address){
        fetch("https://eu1.locationiq.com/v1/search.php?key=de6c3bbad41f0d&street="+ address+"&city=" + place +"&postalcode=" + zipCode +"&format=json", {
            method: "GET",
        }).then(data => {
            return data.json()
        }).then(result => {
            var coordinates = []
            coordinates.push(result[0].lat)
            coordinates.push(result[0].lon)


            const clinic = { ...this.state.clinic };
            clinic.latitude = result[0].lat 
            clinic.longitude = result[0].lon

            this.setState({coordinates: coordinates, clinic: clinic}, () => console.log(this.state.clinic))


            console.log(result)
        }).catch((error) => {
            console.error(error);
        });
    }

    render() {
        return (
            <Form noValidate validated={this.state.updateClinicValidation}>
                        <Row>
                            <Col>
                                <Form.Group controlId="formAddClinicName">
                                    <Form.Label>Naam clinic</Form.Label>
                                    <Form.Control type="text" placeholder="Clinic naam" name="name" value={this.state.clinic.name} onChange={this.handleAddClinicChange} required />
                                </Form.Group>
                                <Form.Group controlId="formAddClinicStreet">
                                    <Form.Label>Straatnaam</Form.Label>
                                    <Form.Control type="text" placeholder="Straatnaam" name="street" value={this.state.clinic.street} onChange={this.handleAddClinicChange} required />
                                </Form.Group>
                                <Form.Group controlId="formAddClinicStreetNumber">
                                    <Form.Label>Nummer</Form.Label>
                                    <Form.Control type="text" placeholder="nummer" name="number" value={this.state.clinic.number} onChange={this.handleAddClinicChange} required />
                                </Form.Group>

                                <Form.Group controlId="formAddClinicZipCode">
                                    <Form.Label>Postcode</Form.Label>
                                    <Form.Control type="text" placeholder="1234 AB" name="zipCode" value={this.state.clinic.zipCode} onChange={this.handleAddClinicChange} required />
                                </Form.Group>
                                <Form.Group controlId="formAddClinicPlace">
                                    <Form.Label>Plaats</Form.Label>
                                    <Form.Control type="text" placeholder="Plaatsnaam" name="place" value={this.state.clinic.place} onChange={this.handleAddClinicChange} required />
                                </Form.Group>
                                <Form.Group controlId="formAddClinicWebsite">
                                    <Form.Label>Website</Form.Label>
                                    <Form.Control type="text" placeholder="https://Website.nl" name="website" value={this.state.clinic.website} onChange={this.handleAddClinicChange} />
                                </Form.Group>
                                <Form.Group controlId="formAddClinicIsVisible">
                                    <Form.Check type="checkbox" label="Clinic zichtbaar voor studenten?" checked={this.state.clinic.isVisble} name="isVisble" onChange={this.handleAddClinicChange} />
                                </Form.Group>
                                <Form.Group controlId="formAddClinicIsFullYear">
                                    <Form.Check type="checkbox" label="QR-code voor het gehele schooljaar?" checked={this.state.clinic.isFullYear} name="isFullYear" onChange={this.handleAddClinicChange} />
                                </Form.Group>
                                <Form.Group controlId="formAddClinicIsOnlyGPS">
                                    <Form.Check type="checkbox" label="Alleen met GPS inchecken?" name="isOnlyGPS" checked={this.state.clinic.isOnlyGPS} onChange={this.handleAddClinicChange} />
                                </Form.Group>
                                <Form.Group controlId="formAddClinicSportTime">
                                    <Form.Label>Minimale sporttijd in minuten</Form.Label>
                                    <Form.Control type="number" value="60" name="minSportTime" label='Minuten' value={this.state.clinic.minSportTime} onChange={this.handleAddClinicChange} required />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Label>Opmerking</Form.Label>
                                <Button className='float-right mb-2' onClick={this.addComment}>+</Button>
                                {
                                    this.state.comments.map((val, i) => {
                                        return (
                                            <Form.Row>
                                                <Col xs="auto" className="my-1">
                                                    <Form.Control type="text" placeholder="Opmerking" name="formAddClinicComment" value={val.comment} id={val.id} onChange={this.handleClinicCommentChange.bind(this, i)} />
                                                </Col>
                                                <Col xs="auto" className="my-1">
                                                    <Button className='mb-2' onClick={() => this.removeComment(val.id)} variant="danger">-</Button>
                                                </Col>
                                            </Form.Row>
                                        )
                                    })
                                }
                            </Col>

                            <Col>
                                <Form.Group controlId="formAddClinicContactPerson">
                                    <Form.Label>Contactpersoon sportbureau </Form.Label>
                                    <Form.Control name="employee.Id" onChange={this.onChangeEmployee} value={this.state.clinic.employee.id} as="select" required custom>
                                        <option value="select">Selecteer</option>
                                        {
                                            this.state.employees.map(item => {
                                                return (
                                                    <option value={item.id}>{item.fullName}</option>
                                                )
                                            })
                                        }
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        Geef hier aan wie de contactpersoon vanuit het sportbureau is
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="formAddClinicContactPersonClinicName">
                                    <Form.Label>Naam contactpersoon clinic</Form.Label>
                                    <Form.Control type="text" placeholder="Naam contactpersoon clinic" name="contactPersonName" value={this.state.clinic.contactPersonName} onChange={this.handleAddClinicChange} required />
                                    <Form.Text className="text-muted">
                                        Geef hier de naam van de contactpersoon van de clinic
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="formAddClinicContactPersonClinicEmail">
                                    <Form.Label>E-mail contactpersoon clinic</Form.Label>
                                    <Form.Control type="email" placeholder="email contactpersoon clinic" name="contactPersonMail" value={this.state.clinic.contactPersonMail} onChange={this.handleAddClinicChange} required />
                                </Form.Group>
                                <Form.Group controlId="formAddClinicContactPersonClinicEmail">
                                <Maps center={this.state.coordinates}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                    <Button className="float-right" onClick={this.updateClinic}>Wijzigen</Button>
                            </Col>
                        </Row>
                    </Form>
        )
    }
}

export default UpdateClinic;