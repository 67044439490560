import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import PeriodsBox from '../../components/dashboard/PeriodsBox'
import SchoolYears from '../../components/dashboard/OverviewSchoolYears'
import OwnClassesBox from '../../components/dashboard/OwnClassesBox'
import LastViewedStudents from '../../components/dashboard/LastViewedStudents'
import LastViewedClasses from '../../components/dashboard/LastViewedClasses'
import LastCriticalErrorMessages from '../../components/dashboard/LastCriticalErrorMessages'

class Dashboard extends Component {

    render() {
        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Dashboard</h1>
                    <Row>
                        <Col sm={3}>
                            <PeriodsBox/>
                        </Col>
                        <Col sm={3}>
                            <OwnClassesBox/>
                        </Col>
                        <Col sm={3}>
                            <LastViewedStudents/>
                        </Col>
                        <Col sm={3}>
                            <LastViewedClasses/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            <SchoolYears />
                        </Col>
                        <Col sm={4}>
                            <LastCriticalErrorMessages />
                        </Col>
                    </Row>
                        {/* <ReleaseNotesBox /> */}
                </Col>
            </Row>
        </Container>
    }
}

export default Dashboard;