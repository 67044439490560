import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import NoImage from '../../images/no_image.jpg';
import { toastr } from 'react-redux-toastr'

class GeneralStudentInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            student: {
                firstName: "",
                insert: "",
                lastName: ""

            },
            base64Image: null,
            fileLabel: "Voeg je document toe"
        }
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        fetch(process.env.REACT_APP_API_URL + "student/" + this.props.studentId,
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({ student: data })
            })
            .catch(error => {
                console.log(error)
                // localStorage.clear();
                // location.href = '/'
            });

        fetch(process.env.REACT_APP_API_URL + "student/" + this.props.studentId + "/image",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                },
            }).then(response => response.arrayBuffer())
            .then(buffer => {
                console.log("Result image")
                console.log(buffer)
                this.setState({ base64Image: this.arrayBufferToBase64(buffer) })
            })
            .catch(error => {
                console.log(error)
                // localStorage.clear();
                // location.href = '/'
            });
    }

    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = [].slice.call(new Uint8Array(buffer));

        bytes.forEach((b) => binary += String.fromCharCode(b));
        return window.btoa(binary);
    }

    onChangeImageHandler = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        this.setState({ fileLabel: file.name })
        const formData = new FormData();
        formData.append('file', file)

        fetch(process.env.REACT_APP_API_URL + "student/" + this.props.studentId + "/image", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token')
            },
            body: formData
        }).then(data => {
            return data.arrayBuffer()
        })
            .then(result => {
                this.setState({ base64Image: this.arrayBufferToBase64(result) })

                toastr.success('Afbeelding gewijzigd', 'Je hebt de afbeelding gewijzigd.')
            });
    }

    render() {
        return (<Col>
            <Row>
                <Col>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Studentnummer</Form.Label>
                            <Form.Control type="text" value={this.state.student.studentId} disabled />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Naam student</Form.Label>
                            <Form.Control type="text" value={this.state.student.firstName + " " + this.state.student.insert + " " + this.state.student.lastName} disabled />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Adres</Form.Label>
                            <Form.Control type="text" value={this.state.student.address} disabled />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control type="text" value={this.state.student.zipCode} disabled />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><a href={"https://teams.microsoft.com/l/chat/0/0?users=" + this.state.student.schoolMail} target="_blank">Contact Microsoft Teams</a></Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><a href={"mailto:" + this.state.student.schoolMail} target="_blank">Contact Mail</a></Form.Label>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label><a href={"/student/control/" + this.state.student.id}>Monitoren meldingen</a></Form.Label>
                        </Form.Group>
                    </Form>
                </Col>
                <Col>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Woonplaats</Form.Label>
                            <Form.Control type="text" value={this.state.student.place} disabled />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>School email</Form.Label>
                            <Form.Control type="text" value={this.state.student.schoolMail} disabled />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Mobiel nummer</Form.Label>
                            <Form.Control type="text" value={this.state.student.mobileNumber} disabled />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Telefoonnummer</Form.Label>
                            <Form.Control type="text" value={this.state.student.phoneNumber} disabled />
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs lg="3">
                    <Row>
                        {
                            this.state.base64Image == "" ? (
                                <img src={NoImage} alt={NoImage} className="w-75 h-75 border" />
                            ) : (
                                    <img src={`data:image/jpeg;base64,${this.state.base64Image}`} className="w-75 h-75 border" />
                                )
                        }
                    </Row>
                    <Row>
                        <Form className="ml-auto mr-auto mt-2">
                            <Form.File
                                id="custom-file-translate-html"
                                label={this.state.fileLabel}
                                data-browse="Bestand kiezen"
                                className=""
                                custom
                                onChange={this.onChangeImageHandler}
                            />
                        </Form>
                    </Row>
                </Col>
            </Row>
        </Col>
        )
    }
}

export default GeneralStudentInfo;