import React from 'react'
import { Redirect } from 'react-router-dom'
import JsCookie from 'js-cookie'
import Cookies from 'universal-cookie';
import * as QueryString from "query-string"

// import {
//     AuthorizationServiceConfiguration,
//     RedirectRequestHandler,
//     AuthorizationNotifier,
//     BaseTokenRequestHandler,
//     TokenRequest,
//     GRANT_TYPE_AUTHORIZATION_CODE,
//     FetchRequestor, LocalStorageBackend, DefaultCrypto
// } from '@openid/appauth';

// import { NoHashQueryStringUtils } from './noHashQueryStringUtils';

//AppAuth installation: https://dev.to/kdhttps/appauth-js-integration-in-react-1m3e
//TODO beter examples : https://ui.dev/react-router-v4-protected-routes-authentication/
//https://blog.netcetera.com/how-to-create-guarded-routes-for-your-react-app-d2fe7c7b6122

class ProtectedEmployeeRoute extends React.Component {

    constructor(props) {
        super(props);
        console.log("Congratulations we are in the router of Employee")
        const cookies = new Cookies();

        const parsed = QueryString.parse(location.search);

        // var token = parsed.token
        // var id = parsed.id
        // var name = parsed.name
        if (localStorage.getItem('access_token') === undefined || localStorage.getItem('access_token') === null) {
            // const authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new NoHashQueryStringUtils(), window.location, new DefaultCrypto());
            // const notifier = new AuthorizationNotifier();
            // authorizationHandler.setAuthorizationNotifier(notifier);

            // const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
            // console.log("Start authorizationlistener");

            // notifier.setAuthorizationListener((request, response, error) => {
            //     console.log('Authorization request complete ', request, response, error);
            //     if (response) {
            //         // console.log(`Authorization Code  ${response.code}`);

            //         let extras = null;
            //         if (request && request.internal) {
            //             extras = {};
            //             extras.code_verifier = request.internal.code_verifier;
            //         }

            //         // A. First, you need to create a token request object
            //         const tokenRequest = new TokenRequest({
            //             client_id: process.env.REACT_APP_CLIENTID,
            //             redirect_uri: process.env.REACT_APP_REDIRECT_URL,
            //             grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
            //             code: response.code,
            //             extras
            //         });

            //         // B. Again get configuration information
            //         AuthorizationServiceConfiguration.fetchFromIssuer(process.env.REACT_APP_ISSUER, new FetchRequestor())
            //             .then((oResponse) => {
            //                 const configuration = oResponse;
            //                 // C. Hit `/token` endpoint and get token
            //                 return tokenHandler.performTokenRequest(configuration, tokenRequest);
            //             })
            //             .then((oResponse) => {
            //                 localStorage.setItem('access_token', oResponse.accessToken);
            //                 // do operation now as per your need
            //                 // props.history.push('/profile');
            //                 // console.log(oResponse.accessToken)

            //                 const fetchToken = localStorage.getItem('access_token');
            //                 fetchUserInfo(fetchToken)
            //                     .then((info) => {
            //                         console.log(info);
            //                         JsCookie.set('name', info.fullName);
            //                         JsCookie.set('id', info.id)
            //                         JsCookie.set('isSuperUser', info.roll === 'SuperUser')
            //                     })
            //             })
            //             .catch(oError => {
            //                 localStorage.clear();
            //                 location.href = '/'
            //             });
            //     }
            // });


            // authorizationHandler.completeAuthorizationRequestIfPossible()


            // const fetchUserInfo = async (token) => {
            //     const res = await fetch(process.env.REACT_APP_API_URL + "auth/v1/me", {
            //         headers: {
            //             authorization: `Bearer ${token}`
            //         }
            //     })
            //     .catch(oError => {
            //         localStorage.clear();
            //         location.href = '/'
            //     });;
            //     return res.json();
            // };
        }
    }



    render() {


        // const cookies = new Cookies();
        const Component = this.props.component;
        var isAuthenticated = localStorage.getItem('access_token') != '' //|| localStorage.getItem('access_token') !== undefined;

        return isAuthenticated ? (
            <Component />
        ) : (
                <Redirect to={{ pathname: '/' }} />
            );
    }
}

export default ProtectedEmployeeRoute;