import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'
import DatePicker from '../../components/DatePicker'
import JsCookie from 'js-cookie'

class UnlockCode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            codes: [],
            startDate: null,
            endDate: null,
            code: null
        }
    }

    componentDidMount() {
        this.getCodes()
    }

    onChangeHandlerDate = e => {
        var name = e.target.name
        var val = e.target.value

        this.setState({ [name]: val })

    }

    getCodes() {
        fetch(process.env.REACT_APP_API_URL + "unlock",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                this.setState({ codes: responseJson })
            })
            .catch((error) => {
                console.error(error);
                localStorage.clear();
                location.href = '/'
            });
    }

    onSubmitUnlock = e => {
        event.preventDefault();

        var obj = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            employee: {
                id: JsCookie.get('guid')
            }
        }

        if (moment(this.state.endDate).isAfter(moment()) === false) {
            toastr.warning('Ontgrendel code', 'Je kunt niks in het verleden aanmaken')
            return
        }

        fetch(process.env.REACT_APP_API_URL + "unlock", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(data => {
            return data.json()
        })
            .then(result => {
                var codes = this.state.codes
                console.log(result)
                codes.push(result)
                this.setState({ codes: codes, code: result.code })

                toastr.success('Ontgrendel code', 'Je hebt een nieuwe ontgrendel code toegevoegd!')
            });
    }

    onChangeStartDate = (isoDate) => {
        this.setState({startDate : isoDate})
    }
    onChangeEndDate = (isoDate) => {
        this.setState({endDate : isoDate})
    }

    render() {
        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Ontgrendel code</h1>
                    <Row>
                        <Col>
                            <h4>Genereer ontgrendel code</h4>
                            <Form onSubmit={this.onSubmitUnlock}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="w-100">Start datum/tijd</Form.Label>
                                    <DatePicker 
                                        onChange={this.onChangeStartDate}
                                        dateFormat="dd-MM-yyyy HH:mm"
                                        placeholder="dd-mm-yyyy hh:mm"
                                        showTime={true} autoComplete="off"/>
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label className="w-100">Eind datum/tijd</Form.Label>
                                    <DatePicker 
                                    onChange={this.onChangeEndDate}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    placeholder="dd-mm-yyyy hh:mm"
                                    showTime={true}/>
                                    
                                </Form.Group>
                                <Button className="w-100" type="submit">
                                    Genereer
                                </Button>
                            </Form>
                            {
                                this.state.code === null ? ("") : (
                                    <Alert variant="success" className="mt-5">
                                        <h4 style={{ fontSize: "72px" }}>{this.state.code}</h4>
                                    </Alert>
                                )
                            }
                        </Col>
                        <Col>
                            <h4>Actieve ontgrendelcodes</h4>

                            <Table>
                                <thead>
                                    <tr>
                                        <td>Code</td>
                                        <td>Geldigheid</td>
                                        <td>Aangemaakt door</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.codes.map(code => {
                                            return (
                                                <tr>
                                                    <td>{code.code}</td>
                                                    <td>{moment(code.startDate).format("DD-MM-YYYY HH:mm")} - {moment(code.endDate).format("DD-MM-YYYY HH:mm")}</td>
                                                    <td>{code.employee?.fullName}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container >
    }
}

export default UnlockCode;