import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import JsCookie from 'js-cookie'
import Cookies from 'universal-cookie';
import * as QueryString from "query-string"

class CallBack extends Component {

    componentDidMount() {
        const cookies = new Cookies();

        const parsed = QueryString.parse(location.search);

        var token = parsed.token
        var id = parsed.id
        var name = parsed.name

        console.log(token)
        console.log(id)
        console.log(name)
        
        if(token !== undefined )//&& JsCookie.get('som.extern.token') !== undefined
        {
            // cookies.set('somexterntoken', token, { path: '/' });
            //console.log("Set token")
            // cookies.set('som.extern.token', token,  { path: "/", httpOnly: false });
            // cookies.set('test.som.extern.token', token,  { path: "/", httpOnly: false });
            // console.log(document.cookie)
        }

        location.href = '/index-extern?id=' + id + '&name=' + name

        //Below for teste
    //     fetch(process.env.REACT_APP_API_URL + "extern/" + JsCookie.get('id') + "/withoutabonnement",
    //   {
    //     method: "GET",
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json',
    //       'Cache': 'no-cache'
    //     },
    //     credentials: 'include'
    //   })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     this.setState({ students: responseJson, showCheckinWithoutAbonSpinner: false })
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

    }

    render() {
        return <div>
            Even geduld AUB 
        </div>
    }
}
export default CallBack;