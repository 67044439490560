import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import { toastr } from 'react-redux-toastr'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Table from 'react-bootstrap/Table'
import moment from 'moment'

class OpenJoins extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allStudents: true,
            students: [],
            startDate: "",
            endDate: "",
        }
    }

    onChangeDate = e => {
        var nam = e.target.name
        var val = e.target.value

        this.setState({ [nam]: val });
    }

    searchHandler = e => {
        const value = e.target.value;

        if (value.length >= 3) {
            this.searchStudent(value)
        }
    }

    searchStudent(keyword) {
        fetch(process.env.REACT_APP_API_URL + "student/" + keyword + "/search",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ students: data })
            })
            .catch(error => {
                console.log(error)
            });
    }

    onChangeAllStudents = e => {
        if (this.state.allStudents) {
            this.setState({ allStudents: false })
        } else {
            this.setState({ allStudents: true })
        }
    }

    onChangeCheckBoxStudent = e => {
        var id = e.target.id
        var value = e.target.checked

        var students = this.state.students
        console.log(students)
        var index = students.findIndex(x => x.id === id)
        students[index].checked = value

        this.setState({ students: students })
    }

    openJoinsStudents() {
        var students = this.state.students

        var checkedStudents = students.filter(x => x.checked === true)

        checkedStudents.map(student => {
            student.startDate = moment(this.state.startDate, "DD-MM-YYYY hh:mm").format()
            student.endDate = moment(this.state.endDate, "DD-MM-YYYY hh:mm").format()
        })

        fetch(process.env.REACT_APP_API_URL + "joinclinic/open", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(checkedStudents)
        }).then(data => {
            return data.json()
        })
            .then(result => {
                toastr.success('Inschrijvingen', 'Je hebt de inschrijvingen geopend op de opgegeven datum.')
            });
    }

    onSubmitJoin = e => {
        event.preventDefault();

        if (this.state.allStudents) {
            this.openJoinAllStudents()
        } else {
            this.openJoinsStudents()
        }
    }

    openJoinAllStudents() {
        var obj = {
            startDate: moment(this.state.startDate, "DD-MM-YYYY hh:mm").format(),
            endDate: moment(this.state.endDate, "DD-MM-YYYY hh:mm").format()
        }

        fetch(process.env.REACT_APP_API_URL + "joinclinic/open/all", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(data => {
            return data.json()
        })
            .then(result => {
                toastr.success('Inschrijvingen', 'Je hebt de inschrijvingen geopend op de opgegeven datum.')
            });
    }

    selectAll = e => {
        var students = this.state.students

        students.map(student => {
            student.checked = e.target.checked
        })

        this.setState({ students: students })
    }

    render() {
        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Inschrijvingen openen</h1>
                    <Row>
                        <Col>
                            <h3>Datum's invullen</h3>
                            <Form onSubmit={this.onSubmitJoin}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Open inschrijvingen vanaf</Form.Label>
                                    <Form.Control name="startDate" type="datetime-local" placeholder="dd-mm-yyyy hh:mm" onChange={this.onChangeDate} required />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Eindigen van de inschrijving</Form.Label>
                                    <Form.Control name="endDate" type="datetime-local" placeholder="dd-mm-yyyy hh:mm" onChange={this.onChangeDate} required />
                                </Form.Group>
                                <Button className="w-100" type="submit">
                                    Inschrijvingen openen
                                </Button>
                            </Form>
                        </Col>
                        <Col>
                            <h3>Studenten zoeken</h3>

                            <Row>
                                <Col>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="Alle studenten"
                                        checked={this.state.allStudents}
                                        onChange={this.onChangeAllStudents}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                {
                                    this.state.allStudents ? ("") : (
                                        <Col>
                                            <InputGroup size="sm" className="mt-2">
                                                <FormControl
                                                    placeholder="Zoek student naam, klas"
                                                    aria-label="text"
                                                    aria-describedby="basic-addon2"
                                                    onChange={this.searchHandler}
                                                />
                                                <InputGroup.Append style={{ backgroundColor: '#0064AD', }} >
                                                    <InputGroup.Text id="basic-addon2" style={{ backgroundColor: '#0064AD', borderColor: '#0064AD', color: '#fff' }}>Zoek</InputGroup.Text>
                                                </InputGroup.Append>
                                            </InputGroup>

                                            <Table>
                                                <thead>
                                                    <th>Studentnummer</th>
                                                    <th>Studentnaam</th>
                                                    <th>Klas</th>
                                                    <th>Selecteer <input type="checkbox" onChange={this.selectAll} /></th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.students.map(student => {
                                                            return (
                                                                <tr key={student.id}>
                                                                    <td>{student.studentId}</td>
                                                                    <td>{student.firstName}</td>
                                                                    <td>{student.classes[0] != null ? (student.classes[0].class.name) : "Niet bekend"}</td>
                                                                    <td>
                                                                        <input type="checkbox" id={student.id} onChange={this.onChangeCheckBoxStudent} checked={student.checked} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </Col>
                                    )
                                }
                            </Row>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    }
}

export default OpenJoins;