
import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from './history';

import ProtectedExternRouter from './ProtectedExternRoute';
import ProtectedEmployeeRouter from './ProtectedEmployeeRoute';

import Login from './containers/Login'
import Verify from './containers/externPersons/verifiy'
import IndexExtern from './containers/externPersons/index-extern'

//Pages
import Dashboard from "./containers/employee/dashboard";
import Employee from "./containers/employee/employee";
import Notification from "./containers/employee/notification";
import Callback from "./containers/employee/CallBack";
import CallbackExtern from "./containers/externPersons/callBack";
import Clinic from "./containers/employee/Clinic";
import SchoolYear from "./containers/employee/SchoolYear";
import StartSchoolYear from "./containers/employee/StartSchoolYear";
import ManageClass from "./containers/employee/manageClass";
import Imports from "./containers/employee/imports";
import StudentInfoPage from "./containers/employee/StudentInfoPage";
import Students from "./containers/employee/students";
import Sportdays from "./containers/employee/Sportdays";
import SportdayJoin from "./containers/employee/SportdayJoin";
import UnlockCode from "./containers/employee/UnlockCode";
import OpenJoins from "./containers/employee/OpenJoins";
import StudentImages from "./containers/employee/StudentImages";
import ContentManagment from "./containers/employee/ContentManagment"
import Classes from "./containers/employee/Classes"
import ClassDetail from "./containers/employee/ClassDetail"
import ClinicPresention from "./containers/employee/clinicPresention"
import StudentControlPage from "./containers/employee/StudentControlPage"

export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={Login} />
                    <ProtectedExternRouter path="/index-extern" component={IndexExtern} />
                    <Route path="/callback-extern" component={CallbackExtern} />
                    <Route path="/verify" component={Verify} />

                    <Route path="/callback" component={Callback} />

                    <ProtectedEmployeeRouter path="/dashboard" component={Dashboard}/>
                    <ProtectedEmployeeRouter path="/employee" component={Employee} />
                    <ProtectedEmployeeRouter path="/notification" component={Notification} />
                    <ProtectedEmployeeRouter path="/clinic" component={Clinic} />
                    <ProtectedEmployeeRouter path="/schoolyear" component={SchoolYear} />
                    <ProtectedEmployeeRouter path="/startschoolyear" component={StartSchoolYear} />
                    <ProtectedEmployeeRouter path="/manageclass" component={ManageClass} />
                    <ProtectedEmployeeRouter path="/imports" component={Imports} />
                    <ProtectedEmployeeRouter exact path="/student/:studentId" component={StudentInfoPage} />
                    <ProtectedEmployeeRouter path="/students" component={Students} />
                    <ProtectedEmployeeRouter path="/sportdays" component={Sportdays} />
                    <ProtectedEmployeeRouter path="/unlock" component={UnlockCode} />
                    <ProtectedEmployeeRouter exact path="/sportday/:sportdayId/:sportdayName" component={SportdayJoin} />
                    <ProtectedEmployeeRouter path="/openjoins" component={OpenJoins} />
                    <ProtectedEmployeeRouter path="/studentimages" component={StudentImages} />
                    <ProtectedEmployeeRouter path="/contentmanagement" component={ContentManagment} />
                    <ProtectedEmployeeRouter path="/classes" component={Classes} />
                    <ProtectedEmployeeRouter exact path="/class/:classId" component={ClassDetail} />
                    <ProtectedEmployeeRouter exact path="/clinicPresention/:name/:clinicId" component={ClinicPresention} />
                    <ProtectedEmployeeRouter exact path="/student/control/:studentId" component={StudentControlPage} />
                </Switch>
            </Router>
        )
    }
}