import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import JsCookie from 'js-cookie'
import { toastr } from 'react-redux-toastr'
import Table from 'react-bootstrap/Table'
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsPencil } from "react-icons/bs"
import linq from "linq";
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PresentionModal from '../presentionModal'
import {Typeahead} from 'react-bootstrap-typeahead'

class Joins extends Component {
    constructor(props) {
        super(props);

        this.state = {
            joins: [],
            showAddJoinModal: false,
            showDeleteJoinModal: false,
            showPresentionJoinModal: false,
            clinics: [],
            periods: [],
            addJoin: {
                periodId: "select",
                clinicId: "select",
                isSub: 0
            },
            selectDeleteJoinId: null,
            selectedJoin: null,
            schoolYears: [],
            selectedSchoolYear: null,
            selectedClinic: null
        }

        this.handlerPresentions = this.handlerPresentions.bind(this)
        this.handleShowPresentionJoinModal = this.handleShowPresentionJoinModal.bind(this)
    }

    handlerPresentions(someArg) {
        this.handleShowPresentionJoinModal()
    }

    handleShowAddJoinModal() {
        this.setState({ showAddJoinModal: !this.state.showAddJoinModal })
    }
    handleShowDeleteJoinModal(id) {
        this.setState({ showDeleteJoinModal: !this.state.showDeleteJoinModal, selectDeleteJoinId: id })
    }

    handleShowPresentionJoinModal(obj) {
        this.setState({ showPresentionJoinModal: !this.state.showPresentionJoinModal, selectedJoin: obj })
    }
    componentWillMount() {
        this.getJoins()
        this.getClinics()
        this.getPeriods()
        this.getSchoolYears()
    }

    getJoins() {
        fetch(process.env.REACT_APP_API_URL + "join/" + this.props.studentId + "/student",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ joins: data })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    getPeriods() {
        fetch(process.env.REACT_APP_API_URL + "period/v1",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ periods: data })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    getClinics() {
        fetch(process.env.REACT_APP_API_URL + "clinic/v1/all",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ clinics: data })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    getSchoolYears() {
        fetch(process.env.REACT_APP_API_URL + "schoolyear",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                var first = data[0]
                this.setState({ schoolYears: data, selectedSchoolYear: first }, () => { console.log(this.state.selectedSchoolYear) })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    handleOnChangeAddJoin = e => {
        let addJoin = { ...this.state.addJoin };
        const name = e.target.name;
        const value = e.target.value;
        addJoin[name] = value

        this.setState({ addJoin: addJoin });
    }

    onSubmitAddJoin = e => {
        e.preventDefault();
        let addJoin = { ...this.state.addJoin };
        if (addJoin.periodId != "select" && addJoin.clinicId != "select") {
            var clinic = linq.from(this.state.clinics).firstOrDefault(x => x.id == addJoin.clinicId)
            var period = linq.from(this.state.periods).firstOrDefault(x => x.id == addJoin.periodId)

            var json = {
                clinic: clinic,
                period: period,
                isSubscription: (addJoin.isSub == 1)
            }

            fetch(process.env.REACT_APP_API_URL + "join/" + this.props.studentId + "/student", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            }).then(data => {
                return data.json()
            }).then(result => {
                var array = this.state.joins
                array.push(result)

                this.setState({ joins: array })

                toastr.success('Toegevoegd', 'Je hebt een nieuwe clinic toegewezen')

            }).catch((error) => {
                console.error(error);
            });

        }
    }

    deleteJoin() {
        var joinId = this.state.selectDeleteJoinId
        if (joinId != null) {
            fetch(process.env.REACT_APP_API_URL + "join/" + joinId,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('access_token')
                    }
                }).then(response => response.json())
                .then(data => {
                    if (data) {
                        toastr.success('Verwijderd', 'Je hebt een clinic verwijderd.')

                        let newArray = this.state.joins.filter(item => item.id !== joinId)
                        this.setState({ joins: newArray });
                        this.handleShowDeleteJoinModal()
                    }
                })
                .catch(error => {
                    console.log(error);
                    // localStorage.clear();
                    // location.href = '/'
                });
        }
    }

    handlerOnChangeSchoolYear = e => {
        var value = e.target.value
        var schoolYear = this.state.schoolYears.filter(x => x.id == value)[0]

        this.setState({ selectedSchoolYear: schoolYear })
    }

    onChangeTypeAheadClinc = (e) =>{
        let addJoin = { ...this.state.addJoin };
        var clinic = e.pop();

        console.log(clinic)
        addJoin["clinicId"] = clinic.id
        this.setState({addJoin: addJoin, selectedClinic: clinic });

    }

    render() {
        return (
            <Col>
                <Row><h5>Clinic</h5>
                    <Form.Group as={Col} controlId="formGridState" xs={2}>
                        <Form.Control as="select" defaultValue={this.state.selectedSchoolYear} size="sm" onChange={this.handlerOnChangeSchoolYear}>
                            {
                                this.state.schoolYears.map(item => {
                                    return (
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row>
                    <Table>
                        <thead>
                            <th>Blok</th>
                            <th>Clinic</th>
                            <th>Abonnement</th>
                            <th>Sportbezoeken</th>
                            <th>Presentie</th>
                            {JsCookie.get('isSuperUser') ? (<th>Verwijderen</th>) : ("")}
                        </thead>
                        <tbody>
                            {
                                this.state.joins.map(join => {
                                    var sportVisits = linq.from(join.sportVisits)
                                        .where(item =>
                                            item.presentionType != "IsAbsent"
                                        ).count()
                                    return (
                                        (this.state.selectedSchoolYear === null) ? "" :
                                            (join.period.schoolYear.id === this.state.selectedSchoolYear.id) ?
                                                <tr key={join.id}>
                                                    <td>{join.period.name}</td>
                                                    <td>{join.clinic.name}</td>
                                                    <td>{(join.isSubscription ? "Ja" : "Nee")}</td>
                                                    <td>{sportVisits}</td>
                                                    <td><BsPencil onClick={() => this.handleShowPresentionJoinModal(join)} /></td>
                                                    {JsCookie.get('isSuperUser') ?
                                                        (<td><RiDeleteBin6Line onClick={() => this.handleShowDeleteJoinModal(join.id)} /></td>)
                                                        : ("")
                                                    }
                                                </tr>
                                                : ""
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Row>
                <Row>
                    <Button className='mb-2' onClick={() => this.handleShowAddJoinModal()}>+</Button>
                </Row>
                <Modal show={this.state.showDeleteJoinModal} onHide={false}>

                    <Modal.Header closeButton>
                        <Modal.Title>Koppeling met clinic verwijderen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Weet je het zeker dat deze clinic wilt verwijderen?<br />
                        Hierbij gaan ook alle sportbezoeken verloren.<br />
                        Deze mutatie kan niet meer ongedaan worden gemaakt!
                        </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleShowDeleteJoinModal()}>
                            Sluiten
                            </Button>
                        <Button variant="danger" onClick={() => this.deleteJoin()}>
                            Verwijderen
                            </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showAddJoinModal} onHide={() => this.handleShowAddJoinModal()}>

                    <Modal.Header closeButton>
                        <Modal.Title>Koppel student aan clinic</Modal.Title>
                    </Modal.Header>

                    <Form onSubmit={this.onSubmitAddJoin}>
                        <Modal.Body>
                            <Form.Group controlId="addJoin.periodId">
                                <Form.Label>Selecteer een blok</Form.Label>
                                <Form.Control name="periodId" as="select" onChange={this.handleOnChangeAddJoin} required>
                                    <option value="select">Selecteer periode</option>
                                    {
                                        this.state.periods.map(period => {
                                            return (
                                                <option value={period.id}>{period.name}</option>
                                            )
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="addJoin.clinicId">
                                <Form.Label>Selecteer een clinic</Form.Label>
                                <Typeahead
                                        id="basic-typeahead-single"
                                        labelKey="name"
                                        onChange={this.onChangeTypeAheadClinc}
                                        options={this.state.clinics}
                                        placeholder="Selecteer een clinic...."
                                        searchText="Zoeken..."
                                        emptyLabel="Helaas, er zijn geen resultaten"
                                    />
                            </Form.Group>
                            <Form.Group controlId="addJoin.isSub">
                                <Form.Label>Heeft abonnement</Form.Label>
                                <Form.Control name="isSub" as="select" onChange={this.handleOnChangeAddJoin} required>
                                    <option value="1">Ja</option>
                                    <option value="0" selected>Nee</option>
                                </Form.Control>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.handleShowAddJoinModal()}>
                                Sluiten
                        </Button>
                            <Button variant="primary" type="submit">
                                Opslaan
                        </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                <PresentionModal
                    handlerPresentions={this.handlerPresentions.bind(this)}
                    handleShowPresentionJoinModal={this.handleShowPresentionJoinModal.bind(this)}
                    show={this.state.showPresentionJoinModal}
                    join={this.state.selectedJoin} />
            </Col>
        )
    }
}

export default Joins;