import React, { Component } from 'react';
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

class OverviewSchoolYears extends Component {


    constructor(props) {
        super(props);

        this.state = {
            schoolYears: []
        }
    }

    componentDidMount() {
        this.getSchoolYears()
    }

    getSchoolYears() {

        fetch(process.env.REACT_APP_API_URL + "schoolyear",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            })
            .then((resp) => resp.json())
            .then(data => {
                console.log(data)
                this.setState({ schoolYears: data });
            });
    }

    render() {
        return (
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Schooljaren:</Card.Title>
                            <Card.Text>
                                    <Table borderless>
                                {
                                    this.state.schoolYears.map(schoolyear => {

                                        return (
                                            <tr key={schoolyear.id}>
                                                <td>
                                                    {schoolyear.name} 
                                                </td>
                                                <td>
                                                    {schoolyear.active === true ? ("Actief") : ("")} 
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                    </Table>
                                {
                                    this.state.schoolYears.length <= 0 ? ("Er zijn geen schooljaren") : ""
                                }
                                
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default OverviewSchoolYears;