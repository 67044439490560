import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup'
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead'; // ES2015

class SearchBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      options: [],
      isLoading: false
    }
  }

  handleSearch = (query) => {
    this.setState({ isLoading: true })

    fetch(process.env.REACT_APP_API_URL + "search/" + query,
      {
        method: 'GET',
        headers: {
          Authorization: "Bearer " + localStorage.getItem('access_token')
        }
      })
      .then((resp) => resp.json())
      .then(data => {
        const options = data.map((i) => ({
          type: i.type === "CLASS" ? "Klas" : "Student",
          id: i.id,
          name: i.name,
        }))

        this.setState({ options: options, isLoading: false });
      });
  }

  // handleSearch = (query) => {
  //   this.setState({ isLoading: true })
  //   var SEARCH_URI = 'https://api.github.com/search/users';
  //   fetch(`${SEARCH_URI}?q=${query}+in:login&page=1&per_page=50`)
  //     .then((resp) => resp.json())
  //     .then(({ items }) => {
  //       const options = items.map((i) => ({
  //         avatar_url: i.avatar_url,
  //         id: i.id,
  //         name: i.login,
  //       }));

  //       this.setState({ options: options, isLoading: false })
  //       // setOptions(options);
  //       // setIsLoading(false);
  //     });
  // }
  handleChange(selectedOptions) {
    var option = selectedOptions.pop();

    console.log(option)
    if (option.type == "Klas") {
      window.location.href = '/class/' + option.id
    } else {
      window.location.href = '/student/' + option.id
    }
  }

  render() {
    const filterBy = () => true;
    return (
      <>
        <AsyncTypeahead
          onChange={this.handleChange}
          filterBy={filterBy}
          {...this.state}
          id="async-example"
          labelKey="id"
          style={{ width: "60vh" }}
          onSearch={this.handleSearch}
          promptText="Typ voor zoeken"
          searchText="Zoeken..."
          emptyLabel="Helaas, er zijn geen resultaten"
          placeholder="Zoek voor student of klas"
          renderMenuItemChildren={option => (
            <div key={option.id}>
              <span></span>
              <span>{option.name}</span>
            </div>
          )}
        />
        <InputGroup.Append style={{ backgroundColor: '#0064AD', }} >
          <InputGroup.Text id="basic-addon2" style={{ backgroundColor: '#0064AD', borderColor: '#0064AD', color: '#fff' }}>Zoek</InputGroup.Text>
        </InputGroup.Append>
        {/* <InputGroup size="sm" style={{width : "100vh"}} >
                            <FormControl
                                placeholder="Zoek student, clinic, klas"
                                aria-label="text"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append style={{ backgroundColor: '#0064AD', }} >
                                <InputGroup.Text id="basic-addon2" style={{ backgroundColor: '#0064AD', borderColor: '#0064AD', color: '#fff' }}>Zoek</InputGroup.Text>
                            </InputGroup.Append>

                            test
                </InputGroup> */}


      </>
    )
  }
}

export default SearchBar;