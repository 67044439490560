import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import GeneralStudentInfo from '../../components/student/GeneralStudentInfo'
import Joins from '../../components/student/Joins'
import Classes from '../../components/student/Classes'
import Tests from '../../components/student/Tests'
import Sportdays from '../../components/student/Sportdays'
import Points from '../../components/student/Points'
import Comments from '../../components/student/Comments'
import Card from 'react-bootstrap/Card'
import JsCookie from 'js-cookie'


class StudentInfoPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            studentId: window.location.pathname.split('/').pop(),
        };
    }
    componentDidMount() {
        this.postVisit()
    }

    postVisit() {
        fetch(process.env.REACT_APP_API_URL + "monitor/lastviewstudent/" + this.state.studentId + "/employee/" + JsCookie.get("guid"),
            {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            })
            .then((resp) => resp.json())
            .then(data => {
                console.log(data)
            });
    }

    render() {

        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Student informatie</h1>

                    <Card border="info">
                        <Card.Body>
                            <Card.Title></Card.Title>
                            <GeneralStudentInfo studentId={this.state.studentId} as={Row}/>
                        </Card.Body>
                    </Card>
                    <hr />
                    <Card border="info">
                        <Card.Body>
                            <Card.Title></Card.Title>
                            <Comments studentId={this.state.studentId} as={Row}/>
                        </Card.Body>
                    </Card>
                    <hr />
                    <Card border="info">
                        <Card.Body>
                            <Card.Title></Card.Title>
                        <Points studentId={this.state.studentId} as={Row}/>
                        </Card.Body>
                    </Card>
                    <hr />

                    <Card border="info">
                        <Card.Body>
                            <Card.Title></Card.Title>
                        <Joins studentId={this.state.studentId} as={Row}/>
                        </Card.Body>
                    </Card>
                    <hr />
                    <Card border="info">
                        <Card.Body>
                            <Card.Title></Card.Title>
                            <Sportdays studentId={this.state.studentId} as={Row}/>
                        </Card.Body>
                    </Card>
                    <hr />
                    <Card border="info">
                        <Card.Body>
                            <Card.Title>Test je leefstijl</Card.Title>
                            <Tests studentId={this.state.studentId} as={Row}/>
                        </Card.Body>
                    </Card>
                    <hr />

                    <Card border="info">
                        <Card.Body>
                            <Card.Title></Card.Title>
                            <Classes studentId={this.state.studentId} as={Row}/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    }
}

export default StudentInfoPage;

