import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import history from '../../history'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Logo from '../../images/icon_som_80px.png'
import Image from 'react-bootstrap/Image'
import queryString from 'query-string';
import { CgSpinnerTwoAlt } from "react-icons/cg"

import BootstrapTable from 'react-bootstrap-table-next';// From: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/getting-started.html
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';


class IndexExtern extends Component {
  constructor(props) {
    super(props);

    this.state = {
      students: [],
      studentsAbon: [],
      totalSportVisits: [],
      totalSportVisitsAbon: [],
      showCheckinWithoutAbonSpinner: true,
      showAmountsWithoutAbonSpinner: true,
      showCheckinWithAbonSpinner: true,
      showAmountWithAbonSpinner: true,
      otherClinics: [],
      clinicName: ""
    };
  }

  componentWillMount() {
    this.getNameOfClinic()
    this.renderMyData();
  }

  renderMyData() {
    this.getOtherClinics()
    let params = queryString.parse(location.search)
    let clinicId = params["id"]

    fetch(process.env.REACT_APP_API_URL + "extern/" + clinicId + "/withoutabonnement",
      {
        method: 'GET',
        credentials: 'include',
        withCredentials: true
      })
      .then((response) => {
        if(response.status == 401) {
          this.logOut()
      }
        return response.json()
      })
      .then((responseJson) => {
        var array = []
        responseJson.map(item => {
          var obj = this.mapToRow(item)
          array = array.concat(obj)
        })
        console.log(array)

        this.setState({ students: array, showCheckinWithoutAbonSpinner: false })
      })
      .catch((error) => {
        console.error(error);
      });

    fetch(process.env.REACT_APP_API_URL + "extern/" + clinicId + "/withabonnement",
      {
        credentials: 'include',
        withCredentials: true
      })
      .then((response) => {
        if(response.status == 401) {
          this.logOut()
      }
        return response.json()
      })
      .then((responseJson) => {
        var array = []
        responseJson.map(item => {
          var obj = this.mapToRow(item)
          array = array.concat(obj)
        })
        this.setState({ studentsAbon: array, showCheckinWithAbonSpinner: false })
      })
      .catch((error) => {
        console.error(error);
      });

    fetch(process.env.REACT_APP_API_URL + "extern/" + clinicId + "/withoutabonnement/total",
      {
        credentials: 'include',
        withCredentials: true
      })
      .then((response) => {
        if(response.status == 401) {
          this.logOut()
      }
        return response.json()
      })
      .then((responseJson) => {
        this.setState({ totalSportVisits: responseJson, showAmountsWithoutAbonSpinner: false })
      })
      .catch((error) => {
        console.error(error);
      });

    fetch(process.env.REACT_APP_API_URL + "extern/" + clinicId + "/withabonnement/total",
      {
        credentials: 'include',
        withCredentials: true
      })
      .then((response) => {
        if(response.status == 401) {
          this.logOut()
      }
        return response.json()
      })
      .then((responseJson) => {
        this.setState({ totalSportVisitsAbon: responseJson, showAmountWithAbonSpinner: false })
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getNameOfClinic(){
    let params = queryString.parse(location.search)
    let name = params["name"]
    this.setState({clinicName: name})
  }

  getOtherClinics() {
    let params = queryString.parse(location.search)
    let id = params["id"]

    fetch(process.env.REACT_APP_API_URL + "extern/" + id + "/others",
      {
        method: 'GET',
        credentials: 'include',
        withCredentials: true
      })
      .then((response) => {
        if(response.status == 401) {
          this.logOut()
      }
        return response.json()
      })
      .then((responseJson) => {
        console.log(responseJson)
        this.setState({ otherClinics: responseJson})
      })
      .catch((error) => {
        console.error(error);
      });

  }

  mapToRow(join) {
    var array = []

    join.sportVisits.map(visit => {
      if(visit.presentionType === "IsPresent"){
          var obj = {
            id: visit.id,
            fullName: join.student.firstName + " " + join.student.insert + " " + join.student.lastName,
            periodName: join.period.name,
            month: this.getMonthString(visit.outCheckDateTime),
            inCheckTime: visit.inCheckDateTime,
            outCheckTime: visit.outCheckDateTime,
            schoolYear: join.period.schoolYear.name
          }

          array.push(obj)
        }
    })

    return array
  }

  getMonthString(dateStr) {
    var date = new Date(dateStr)
    var number = date.getMonth()

    var month = ""
    switch (number) {
      case 0:
        month = "Januari";
        break;
      case 1:
        month = "Februari";
        break;
      case 2:
        month = "Maart";
        break;
      case 3:
        month = "April";
        break;
      case 4:
        month = "Mei";
        break;
      case 5:
        month = "Juni";
        break;
      case 6:
        month = "Juli";
        break;
        case 7:
          month = "Augustus";
          break;
          case 8:
            month = "September";
            break;
            case 9:
              month = "Oktober";
              break;
              case 10:
                month = "November";
                break;
                case 11:
                  month = "december";
                  break;
    }
    return month
  }

  logOut() {
    Cookies.remove('som.extern.token')
    history.push('/')
  }

  render() {
    const columns = [{
      dataField: 'id',
      text: 'Studentnummer',
      sort: false,
      hidden: true
    }, {
      dataField: 'fullName',
      text: 'Studentnaam',
      sort: true
    }, {
      dataField: 'periodName',
      text: 'Blok',
      sort: true
    },  {
      dataField: 'schoolYear',
      text: 'Schooljaar',
      sort: true
    }, {
      dataField: 'month',
      text: 'Maand',
      sort: true
    }, {
      dataField: 'inCheckTime',
      text: 'Incheck datum/tijd',
      type: 'date',
      sort: true,
      formatter: (cell) => {
        let dateObj = cell;
        if (typeof cell !== 'object') {
          dateObj = new Date(cell);
          var hr = (dateObj.getUTCHours() + 2) + ""
          var min = dateObj.getUTCMinutes() + ""
          var sec = dateObj.getUTCSeconds() + ""

          if (hr.length == 1) {
            hr = "0" + hr
          }
          if (min.length == 1) {
            min = "0" + min
          }
          if (sec.length == 1) {
            sec = "0" + sec
          }
        }
        return `${('0' + dateObj.getDate()).slice(-2)}-${('0' + (dateObj.getMonth() + 1)).slice(-2)}-${dateObj.getFullYear()} ${hr}:${min}:${sec}`;
      }
    },
      {
        dataField: 'outCheckTime',
        text: 'Uitcheck datum/tijd',
        sort: true,
        formatter: (cell) => {
          let dateObj = cell;
          if (typeof cell !== 'object') {
            dateObj = new Date(cell);
            var hr = (dateObj.getUTCHours() + 2) + ""
            var min = dateObj.getUTCMinutes() + ""
            var sec = dateObj.getUTCSeconds() + ""

            if (hr.length == 1) {
              hr = "0" + hr
            }
            if (min.length == 1) {
              min = "0" + min
            }
            if (sec.length == 1) {
              sec = "0" + sec
            }
          }
          return `${('0' + dateObj.getDate()).slice(-2)}-${('0' + (dateObj.getMonth() + 1)).slice(-2)}-${dateObj.getFullYear()} ${hr}:${min}:${sec}`;
        }
      }
    ];

    const defaultSorted = [{
      dataField: 'inCheckTime', // if dataField is not match to any column you defined, it will be ignored.
      order: 'desc' // desc or asc
    }];

    function logOut() {
      Cookies.remove('som.extern.token')
      history.push('/')
    }
    return (
      <div>
        <Navbar bg="dark" variant="dark">

          <Navbar.Brand href="#home">
            <Image
              alt=""
              src={Logo}
              style={{ width: "30px", height: "30px" }}
              className="d-inline-block align-top"
              roundedCircle
            />{' '}
            {this.state.clinicName}
          </Navbar.Brand>
          <Nav className="mr-auto">
            {
              this.state.otherClinics.map(clinic => 
              <Nav.Link href={"/index-extern?id=" + clinic.id + "&name=" + clinic.name}>{clinic.name}</Nav.Link>
              )
            }
          </Nav>
          <Nav>
            <Nav.Link href="#logout" onClick={logOut}>Uitloggen</Nav.Link>
          </Nav>
        </Navbar>
        <Container fluid>
          <br></br>
          <Row>
            <Col>
              <h3>Studenten zonder abonnement</h3>
            </Col>
            <Col>
              <h3>Studenten zonder abonnement(Totaal)</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              {
                this.state.showCheckinWithoutAbonSpinner ?
                  (
                    <CgSpinnerTwoAlt className="icon-spin w-100" size={80} />
                  ) :
                  (
                    <BootstrapTable bootstrap4 keyField='id' data={this.state.students} columns={columns} defaultSorted={defaultSorted} pagination={paginationFactory()} rowStyle={{ height: '20px' }} />
                  )
              }
            </Col>
            <Col>
              <Card>
                <Card.Body>

                  {
                    this.state.showAmountsWithoutAbonSpinner ? (
                      <CgSpinnerTwoAlt className="icon-spin w-100" size={80} />
                    )
                      : (
                        <Table responsive="xl" borderless>
                          <thead>
                            <tr>
                              <th>Schooljaar</th>
                              <th>Blok</th>
                              <th>Aantal studenten</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.totalSportVisits.map(period =>
                                <tr>
                                  <td>{period.period.schoolYear.name}</td>
                                  <td>{period.period.name}</td>
                                  <td>{period.totalPoints}</td>
                                </tr>
                              )
                            }
                          </tbody>
                        </Table>
                      )
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <hr></hr>

          <Row>
            <Col>
              <h3>Studenten met abonnement</h3>
            </Col>
            <Col>
              <h3>Studenten met abonnement(Totaal)</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              {
                this.state.showCheckinWithAbonSpinner ? (
                  <CgSpinnerTwoAlt className="icon-spin w-100" size={80} />
                ) : (
                    <BootstrapTable bootstrap4 keyField='id' data={this.state.studentsAbon} columns={columns} defaultSorted={defaultSorted} pagination={paginationFactory()} />
                  )
              }
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  {
                    this.state.showAmountWithAbonSpinner ? (
                      <CgSpinnerTwoAlt className="icon-spin w-100" size={80} />
                    ) : (
                        <Table responsive="xl" borderless>
                          <thead>
                            <tr>
                              <th>Schooljaar</th>
                              <th>Blok</th>
                              <th>Aantal studenten</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.totalSportVisitsAbon.map(period =>
                                <tr>
                                  <td>{period.period.schoolYear.name}</td>
                                  <td>{period.period.name} </td>
                                  <td>{period.totalPoints}</td>
                                </tr>
                              )
                            }
                          </tbody>
                        </Table>
                      )
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default IndexExtern;
