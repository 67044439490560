import React, { Component } from 'react';
import JsCookie from 'js-cookie'
import LoadSymbol from '../../components/LoadSymbol'


import {
    AuthorizationServiceConfiguration,
    RedirectRequestHandler,
    AuthorizationNotifier,
    BaseTokenRequestHandler,
    TokenRequest,
    GRANT_TYPE_AUTHORIZATION_CODE,
    FetchRequestor, LocalStorageBackend, DefaultCrypto
} from '@openid/appauth';

import { NoHashQueryStringUtils } from '../../noHashQueryStringUtils';

class CallBack extends Component {

    componentDidMount() {
        const authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new NoHashQueryStringUtils(), window.location, new DefaultCrypto());
        const notifier = new AuthorizationNotifier();
        authorizationHandler.setAuthorizationNotifier(notifier);

        const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
        console.log("Start authorizationlistener");

        notifier.setAuthorizationListener((request, response, error) => {
            console.log('Authorization request complete ', request, response, error);
            if (response) {
                 console.log(`Authorization Code  ${response.code}`);

                let extras = null;
                if (request && request.internal) {
                    extras = {};
                    extras.code_verifier = request.internal.code_verifier;
                }

                // A. First, you need to create a token request object
                const tokenRequest = new TokenRequest({
                    client_id: process.env.REACT_APP_CLIENTID,
                    redirect_uri: process.env.REACT_APP_REDIRECT_URL,
                    grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                    code: response.code,
                    extras
                });
               
                // B. Again get configuration information
                AuthorizationServiceConfiguration.fetchFromIssuer(process.env.REACT_APP_ISSUER, new FetchRequestor())
                    .then((oResponse) => {
                        const configuration = oResponse;
                        // C. Hit `/token` endpoint and get token
                        return tokenHandler.performTokenRequest(configuration, tokenRequest);
                    })
                    .then((oResponse) => {
                        localStorage.setItem('access_token', oResponse.accessToken);
                        // do operation now as per your need
                        // props.history.push('/profile');
                        // console.log(oResponse.accessToken)

                        const fetchToken = localStorage.getItem('access_token');

                        fetchUserInfo(oResponse.accessToken.toString())
                            .then((info) => {
                                console.log(info);
                                JsCookie.set('name', info.fullName);
                                JsCookie.set('id', info.id)
                                JsCookie.set('guid', info.guidId)
                                JsCookie.set('isSuperUser', info.roll === 'SuperUser')

                                location.href = '/Dashboard'
                            })
                    })
                    .catch(oError => {
                        console.log(oError)
                        // localStorage.clear();
                        // location.href = '/'
                    });
            }
        });


        authorizationHandler.completeAuthorizationRequestIfPossible()

        const fetchUserInfo = async (token) => {
            const res = await fetch(process.env.REACT_APP_API_URL + "auth/v1/me", {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
                .catch(oError => {
                    console.log(oError)
                    // localStorage.clear();
                    // location.href = '/'
                });;
            return res.json();
        };
    }


    render() {
        return (
            <div style={{
                position: 'absolute',
                // display: 'flex',
                justifyContent: 'center',
                height: '98vh',
                width: '98vw',
                top: '25%',
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'white',
            }}
            >
                <LoadSymbol/><br/>
                <center>U wordt geverifieerd. Dit duurt maximaal 10 seconden. U wordt automatisch doorgestuurd!</center>
          </div>
        );
    }
}
export default CallBack;