import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import { FiEye } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { toastr } from 'react-redux-toastr'

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employees: [],
            showHide: false,
            showHideUpdateModel: false,
            formLoginName: "",
            formFullName: "",
            formIsSuperUser: false,
            formUpdateLoginName: "",
            formUpdateFullName: "",
            formUpdateIsSuperUser: false,
            addAlertIsHidden: true,
            alertMessage: '',
            showToast: false,
            formUpdateGuid: '',
            showDeleteEmployeeModal: false,
            selectedEmployeeToDelete: { fullName: ""}
        };
    }

    componentWillMount() {
        this.getEmployees()
    }

    getEmployees() {
        fetch(process.env.REACT_APP_API_URL + "employee",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => this.setState({ employees: data }))
            .catch(error => {
                localStorage.clear();
                location.href = '/'
            });
    }

    handleModalShowHide() {
        this.setState({ showHide: !this.state.showHide })
    }

    handleUpdateModalShowHide() {
        this.setState({ showHideUpdateModel: !this.state.showHideUpdateModel })
    }
    handleDeleteEmployeeModal(employee) {
        console.log(employee)
        this.setState({ showDeleteEmployeeModal: !this.state.showDeleteEmployeeModal, selectedEmployeeToDelete: employee })
    }

    update = (element) => {
        console.log(element)
        this.setState({
            showHideUpdateModel: !this.state.showHideUpdateModel,
            formUpdateFullName: element.fullName,
            formUpdateLoginName: element.loginName,
            formUpdateEmail: element.email,
            formUpdateIsSuperUser: element.isSuperUser,
            formUpdateGuid: element.id
        })
    }

    handleUpdateEmployeeChange = event => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }

    handleUpdateCheckBox = e => {
        let nam = event.target.name;
        let val = event.target.checked;
        console.log(val)

        this.setState({ [nam]: val });

    }

    addEmployee = event => {
        this.setState({
            alertMessage: "",
            addAlertIsHidden: true
        })

        let fullName = this.state.formFullName
        let loginName = this.state.formLoginName
        let email = this.state.formEmail
        let isSuperUser = this.state.formIsSuperUser === "on"

        if (fullName === "") {
            this.setState({
                alertMessage: "Volledige naam mag niet leeg zijn",
                addAlertIsHidden: false
            })
        }
        if (loginName === "") {
            this.setState({
                alertMessage: "Inlognaam mag niet leeg zijn",
                addAlertIsHidden: false
            })
        }

        if (loginName !== "" && fullName !== "") {
            fetch(process.env.REACT_APP_API_URL + "employee", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fullName: fullName,
                    loginName: loginName,
                    email: email,
                    isSuperUser: isSuperUser
                })
            }).then(data => {
                return data.json()
            })
                .then(result => {
                    var newArray = this.state.employees.slice();
                    newArray.push(result);
                    this.setState({ employees: newArray })

                    this.setState({
                        showHide: !this.state.showHide,
                        formFullName: '',
                        formLoginName: '',
                        formIsSuperUser: false,
                        addAlertIsHidden: true
                    })



                    toastr.success('Medewerker toegevoegd', 'Je hebt een nieuwe medewerker toegevoegd!')
                });
        }
    }

    updateEmployee = event => {
        this.setState({
            alertMessage: "",
            addAlertIsHidden: true
        })

        let fullName = this.state.formUpdateFullName
        let loginName = this.state.formUpdateLoginName
        let email = this.state.formUpdateEmail
        let isSuperUser = this.state.formUpdateIsSuperUser

        if (fullName === "") {
            this.setState({
                alertMessage: "Volledige naam mag niet leeg zijn",
                addAlertIsHidden: false
            })
        }
        if (loginName === "") {
            this.setState({
                alertMessage: "Inlognaam mag niet leeg zijn",
                addAlertIsHidden: false
            })
        }

        if (loginName !== "" && fullName !== "") {
            fetch(process.env.REACT_APP_API_URL + "employee/" + this.state.formUpdateGuid, {
                method: "PUT",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: this.state.formUpdateGuid,
                    fullName: fullName,
                    loginName: loginName,
                    email: email,
                    isSuperUser: isSuperUser
                })
            }).then(data => {
                return data.json()
            })
                .then(result => {
                    // var newArray = this.state.employees.slice();
                    // newArray.push(result);
                    // this.setState({ employees: newArray })
                    const index = this.state.employees.findIndex(element => element.id == this.state.formUpdateGuid)
                    var newArray = this.state.employees.slice();

                    newArray[index] = result

                    this.setState({ employees: newArray })

                    this.state.employees.map(it => (it.id === this.state.formUpdateGuid ? { ...it, result } : it))

                    this.setState({
                        showHideUpdateModel: !this.state.showHideUpdateModel,
                        formUpdateFullName: '',
                        formUpdateLoginName: '',
                        formUpdateGuid: '',
                        formUpdateIsSuperUser: false,
                        addAlertIsHidden: true
                    })



                    toastr.success('Medewerker gewijzigd', 'Je hebt ' + fullName + ' gewijzigd.')
                });
        }
    }

    removeEmployee = element => {
        fetch(process.env.REACT_APP_API_URL + "employee/" + element.id, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            }
        }).then(data => {
            return data.json()
        })
            .then(result => {
                console.log(result)
                const index = this.state.employees.findIndex(element => element.id == this.state.formUpdateGuid)
                const newArray = this.state.employees.splice(index, 1);

                this.setState({ employees: this.state.employees }, () => this.handleDeleteEmployeeModal( { fullName: ""}))

                toastr.success('Medewerker verwijderd', 'Je hebt ' + element.fullName + ' verwijderd.')
            });
    }

    handleAddEmployeeChange = event => {
        let nam = event.target.name;
        let val = event.target.value;

        this.setState({ [nam]: val });
    }


    render() {
        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Medewerkers</h1>

                    <Row>
                        <Col>
                            <Button onClick={() => this.handleModalShowHide()}>Medewerker toevoegen</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={8}>
                            <Table responsive="sm">
                                <thead>
                                    <tr>
                                        <th>Inlognaam:</th>
                                        <th>Naam:</th>
                                        <th>Email:</th>
                                        <th>Superuser:</th>
                                        <th>Informatie:</th>
                                        <th>Verwijder:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.employees.map(employee =>
                                            <tr id={employee.id}>
                                                <td>{employee.loginName}</td>
                                                <td>{employee.fullName}</td>
                                                <td>{employee.email}</td>
                                                <td>{employee.isSuperUser ? "Ja" : "Nee"}</td>
                                                <td><a href='#' style={{ color: "#000" }} onClick={() => this.update(employee)}><FiEye /></a></td>
                                                <td><a href='#' style={{ color: "#000" }} onClick={() => this.handleDeleteEmployeeModal(employee)}><RiDeleteBin6Line /></a></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table></Col>
                        <Col></Col>
                    </Row>
                </Col>
            </Row>

            <Modal show={this.state.showHide}>
                <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                    <Modal.Title>Medewerker toevoegen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formLoginName">
                            <Form.Label>Inlognaam</Form.Label>
                            <Form.Control type="text" placeholder="rvn01" name="formLoginName" onChange={this.handleAddEmployeeChange} />
                            <Form.Text className="text-muted">
                                Inlognaam is de naam waarmee er wordt ingelogd.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formfullName">
                            <Form.Label>Volledige naam</Form.Label>
                            <Form.Control type="text" placeholder="Jan Jansen" name="formFullName" onChange={this.handleAddEmployeeChange} />
                        </Form.Group>


                        <Form.Group controlId="formfullName">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Lachternaam@rocvantwente.nl" name="formEmail" onChange={this.handleAddEmployeeChange} />
                        </Form.Group>

                        <Form.Group id="formIsSuperUserCheckbox">
                            <Form.Check type="checkbox" label="is een superuser?" name="formIsSuperUser" onChange={this.handleAddEmployeeChange} />
                            <Form.Text className="text-muted">
                                Een superuser is een persoon die meer rechten heeft dan een 'normaal' persoon.
                            </Form.Text>
                        </Form.Group>
                    </Form>
                    <Alert variant='danger' hidden={this.state.addAlertIsHidden}>
                        {this.state.alertMessage}
                    </Alert>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.handleModalShowHide()}>
                        Sluit
                    </Button>
                    <Button variant="primary" onClick={this.addEmployee}>
                        Opslaan
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.showHideUpdateModel}>
                <Modal.Header closeButton onClick={() => this.handleUpdateModalShowHide()}>
                    <Modal.Title>Medewerker wijzigen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formLoginName">
                            <Form.Label>Inlognaam</Form.Label>
                            <Form.Control type="text" placeholder="rvn01" value={this.state.formUpdateLoginName} name="formUpdateLoginName" onChange={this.handleUpdateEmployeeChange} />
                            <Form.Text className="text-muted">
                                Inlognaam is de naam waarmee er wordt ingelogd.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formfullName">
                            <Form.Label>Volledige naam</Form.Label>
                            <Form.Control type="text" placeholder="Jan Jansen" value={this.state.formUpdateFullName} name="formUpdateFullName" onChange={this.handleUpdateEmployeeChange} />
                        </Form.Group>


                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="lachternaam@rocvantwente.nl" value={this.state.formUpdateEmail} name="formUpdateEmail" onChange={this.handleUpdateEmployeeChange} />
                        </Form.Group>

                        <Form.Group id="formIsSuperUserCheckbox">
                            <Form.Check type="checkbox" label="is een superuser?"
                                checked={this.state.formUpdateIsSuperUser}
                                name="formUpdateIsSuperUser"
                                onChange={this.handleUpdateCheckBox} />
                            <Form.Text className="text-muted">
                                Een superuser is een persoon die meer rechten heeft dan een 'normaal' persoon.
                            </Form.Text>
                        </Form.Group>
                    </Form>
                    <Alert variant='danger' hidden={this.state.addAlertIsHidden}>
                        {this.state.alertMessage}
                    </Alert>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.handleUpdateModalShowHide()}>
                        Sluit
                    </Button>
                    <Button variant="primary" onClick={this.updateEmployee}>
                        Opslaan
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Check modal */}
            <Modal show={this.state.showDeleteEmployeeModal} onHide={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Medewerker verwijderen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Weet je het zeker dat de medewerker {this.state.selectedEmployeeToDelete.fullName} wilt verwijderen?<br />
                        Deze mutatie kan niet meer ongedaan worden gemaakt!
                        </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleDeleteEmployeeModal( { fullName: ""})}>
                            Sluiten
                            </Button>
                        <Button variant="danger" onClick={() => this.removeEmployee(this.state.selectedEmployeeToDelete)}>
                            Verwijderen
                            </Button>
                    </Modal.Footer>
                </Modal>
        </Container>
    }
}

export default Dashboard;