import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import Card from 'react-bootstrap/Card'
import RegistrationsDevice from '../../components/RegistrationsDevice'
import ErrorMessagesStudent from '../../components/ErrorMessagesStudent'



class StudentControlPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            studentId: window.location.pathname.split('/').pop(),
            student: {
                firstName: "",
                insert: "",
                lastName: ""
            }
        }
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        fetch(process.env.REACT_APP_API_URL + "student/" + this.state.studentId,
        {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token')
            }
        }).then(response => response.json())
        .then(data => {
            console.log(data)
            this.setState({ student: data })
        })
        .catch(error => {
            console.log(error)
            // localStorage.clear();
            // location.href = '/'
        });
    }

    render() {
        return <Container fluid>
            <Row>
                <Col style={{margin:"0px", padding:"0px"}}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar'/>
                </Col>

                <Col>
                {/* Here comes the content of the page */}
                    <h1>Overzicht meldingen van {this.state.student.firstName} {this.state.student.insert} {this.state.student.lastName}</h1>

                    <Row>
                        <Col>
                            <Card border="info">
                                <Card.Header as="h6">App registraties</Card.Header>
                                <Card.Body>
                                    Overzicht alle tijdstippen dat de student de app heeft geopend.
                                    <RegistrationsDevice studentId={this.state.studentId}/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col>
                            <Card border="info">
                                <Card.Header as="h6">Alle meldingen</Card.Header>
                                <Card.Body>
                                    Overzicht van alle foutmeldingen die de student heeft.
                                    <ErrorMessagesStudent studentId={this.state.studentId}/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Container>
    }
}

export default StudentControlPage;