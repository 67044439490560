import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../stylesheets/login.css'
import '../../stylesheets/spinner.css'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Logo from '../../images/rvtlogorgb.png';
import Form from 'react-bootstrap/Form';
import queryString from 'query-string';



class Verify extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
        params : queryString.parse(this.props.location.search)
    };
    console.log(this.state.params.token)
    console.log("Current environment: " + process.env.REACT_APP_ENVIRONMENT)
  }

  btnAction = () => {
    console.log(this.state.params.token)
    console.log(this.state.params.email)
    window.location.href = process.env.REACT_APP_API_URL + "auth/clinicCallback?token=" + this.state.params.token + "&email=" + this.state.params.email + "&redirectUrl=" + process.env.REACT_APP_REDIRECT_URL_CLINIC
    // fetch(process.env.REACT_APP_API_URL + "auth/clinicCallback?token=" + this.state.params.token + "&email=" + this.state.params.email + "&redirectUrl=" + process.env.REACT_APP_REDIRECT_URL_CLINIC, {
    //     method: "GET",
    //     headers: { 'Content-Type': 'application/json' }
    //   })
    //     .then(data => {
    //         console.log(data)
    //       return data
    //     })
    //     .then(result => {
    //         window.location.
    //     }).catch(ex => {
    //       this.setState({showSpinner: true  });
    //     });
}
 
  render() {
    // const [showExternLogin, setShowLoginResults] = React.useState(false)
    

    return (
      <div className="center">
        <Container className="w-25 p-3">
          <img src={Logo} alt="Logo" className="w-100" />
          <h5 className="w-100 text-center">Inloggen VolgLink Sport op Maat</h5>
          <Form.Group>
            <Button variant="outline-primary" onClick={this.btnAction} block>Inloggen verifiëren</Button>{' '}
          </Form.Group>

        </Container>
      </div>
    );
  }
}

export default Verify;
