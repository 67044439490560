import React, { Component } from 'react';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'

class Classes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            classes: [],
            schoolYears: [],
            selectedSchoolYear: null
        };
    }

    componentWillMount() {
        this.getClasses()
        this.getSchoolYears()
    }
    getClasses() {
        fetch(process.env.REACT_APP_API_URL + "student/" + this.props.studentId,
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                console.log(data.classes)
                this.setState({ classes: data.classes })
            })
            .catch(error => {
                console.log(error)
                // localStorage.clear();
                // location.href = '/'
            });
    }
    handlerOnChangeSchoolYear = e => {
        var value = e.target.value
        var schoolYear = this.state.schoolYears.filter(x => x.id == value)[0]
        console.log(schoolYear)
        this.setState({ selectedSchoolYear: schoolYear })
    }

    getSchoolYears() {
        fetch(process.env.REACT_APP_API_URL + "schoolyear",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                var first = data[0]
                this.setState({ schoolYears: data, selectedSchoolYear: first })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    render() {
        return (
            <Col>
                <Row>
                    <h5>Klassen</h5>
                    <Form.Group as={Col} controlId="formGridState" xs={2}>
                        <Form.Control as="select" defaultValue={this.state.selectedSchoolYear} size="sm" onChange={this.handlerOnChangeSchoolYear}>
                            {
                                this.state.schoolYears.map(item => {
                                    return (
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row>
                    <Table>
                        <thead>
                            <th>Blok</th>
                            <th>Klas</th>
                        </thead>
                        <tbody>
                            {
                                this.state.classes.map(c => {
                                return (
                                    (this.state.selectedSchoolYear === null) ? "" :
                                        (this.state.selectedSchoolYear.id === c.period.schoolYear.id) ? 
                                        (<tr key={c.id}>
                                            <td>{c.period.name}</td>
                                            <td>{c.class.name}</td>
                                        </tr>) : ""
                                )})
                            }
                        </tbody>
                    </Table>
                </Row>
            </Col>
        )
    }
}

export default Classes;