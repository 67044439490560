import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import { FiEye } from "react-icons/fi";
import { Form } from 'react-bootstrap';
import LoadSymbol from '../../components/LoadSymbol'

class LastCriticalErrorMessages extends Component {

    constructor(props) {
        super(props);

        this.state = {
            messages: [{
                studentId: "",
                keyWord: ""
            }],
            last7Days: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
            last30Days: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(), 
            last90Days: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString(), 
            lastYear: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString(), 
            students: [{
                studentId: ""
            }],
            loading: true
        }
    }

    componentDidMount() {
        this.getStudent()
        // this.getErrorMessages(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString())
    }
    
    handleOnChangeFromDate = e => {
        this.setState({loading: true})
        this.getErrorMessages(e.target.value)
    }
    getErrorMessages(date) {

        fetch(process.env.REACT_APP_API_URL + "monitor/system?from="+date ,
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            })
            .then((resp) => {
                if(resp.status == 401) {
                    localStorage.clear();
                    location.href = '/'
                }
                
                return resp.json()
            })
            .then(data => {
                console.log(data)
                this.setState({ messages: data, loading: false});
            });
    }

    getStudent() {
       fetch(process.env.REACT_APP_API_URL + "student/all",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then((resp) => {
                if(resp.status == 401) {
                    localStorage.clear();
                    location.href = '/'
                }
                
                return resp.json()
            })
            .then(data => {
                this.setState({students: data}, () => this.getErrorMessages(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString()))
            })
            .catch(error => {
                console.log(error)
            });
    }

    findKeyWord(word) {
        word = word.replace('A_', '')
        switch(word){
            case "SYS_TIME":
                return (<Badge pill variant="danger">Tijdsverandering gedecteerd</Badge>)
                break;
            case "SYS_MAX_SPORTVISITS":
                return (<Badge pill variant="danger">Maximaal sportbezoeken</Badge>)
                break;
            default:
                return (<Badge pill variant="dark">{word}</Badge>)
            
        }
    }

    render() {
        return (
            <Row>
                <Col>
                <Card>
                        <Card.Body>
                            <Card.Title>Laatst kritieke foutmeldingen</Card.Title>
                            <Card.Subtitle>Mogelijke fraude foutmeldingen door het syteem gedecteerd.</Card.Subtitle>
                            <Card.Text>
                            <Form.Group controlId="addJoin.periodId">
                                <Form.Control name="periodId" as="select" onChange={this.handleOnChangeFromDate}>
                                    <option selected value={this.state.last7Days}>Afgelopen 7 dagen</option>
                                    <option value={this.state.last30Days}>Afgelopen 30 dagen</option>
                                    <option value={this.state.last90Days}>Afgelopen 90 dagen</option>
                                    <option value={this.state.lastYear}>Afgelopen jaar</option>
                                </Form.Control>
                            </Form.Group>
                                <Table borderless size="sm">

                                
                            {
                                this.state.loading ? (<LoadSymbol/>) : (
                                    
                                    this.state.messages.map(msg => {

                                        return (

                                            <tr key={msg.id}>
                                                    <td>
                                                    <a href={"student/control/" + msg.studentId} className="addons-item">
                                                        {this.findKeyWord(msg.keyWord)}
                                                    </a>
                                                    </td>
                                                    <td>
                                                        <a href={"student/control/" + msg.studentId} className="addons-item">
                                                            {this.state.students.filter(x => x.id == msg.studentId)[0]?.firstName} {this.state.students.filter(x => x.id == msg.studentId)[0]?.insert} {this.state.students.filter(x => x.id == msg.studentId)[0]?.lastName}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href={"student/" + msg.studentId} className="addons-item"><FiEye /></a>
                                                    </td>
                                            </tr>
                                        )
                                    })
                                )
                                }

                                {
                                    this.state.messages.length <= 0 ? ("Geen resultaten") : ""
                                }
                                </Table>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default LastCriticalErrorMessages;