import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import ExcelReader from '../../components/ExcelReader'

class Imports extends Component {

    

    render() {
        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <Row>
                        <h1>Importeren</h1>
                    </Row>
                    <Row>
                        <Col>
                            <ExcelReader/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    }
}

export default Imports;