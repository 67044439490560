import React, { Component } from 'react';
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { toastr } from 'react-redux-toastr'
import { RiDeleteBin6Line } from "react-icons/ri";
import { VscDeviceMobile } from "react-icons/vsc"
import { FaLaptopCode } from "react-icons/fa"
import PresentionsButtons from '../components/PresentionButtons'
import update from 'immutability-helper';

//Update parent: https://stackoverflow.com/questions/35537229/how-to-update-parents-state-in-react
class PresentionModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sportVisits : props.sportVisits
        }
    }

    handlerChangePresentionType = (id, type) => {
        console.log("Retrieve id: " + id + " changing to " + type)

        var data = this.props.join.sportVisits
        var commentIndex = data.findIndex(function (c) {
            return c.id == id;
        });

        var updatedComment = update(data[commentIndex], { presentionType: { $set: type } });

        var newData = update(data, {
            $splice: [[commentIndex, 1, updatedComment]]
        });
        this.props.join.sportVisits = newData

    }

    handlerAddSportVisits = () => {
        this.props.join.sportVisits.push({
            id: "Random" + this.props.join.sportVisits.length + 1,
            presentionType: "IsPresent",//Default value is IsPresent
            inCheckDateTime: new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0],
            outCheckDateTime: new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0],
            comment: "",
            isManually: true
        });
        
        this.forceUpdate()

        console.log(this.props.join.sportVisits)
    }

    removeSportVisit = (id) => {
        this.props.join.sportVisits = this.props.join.sportVisits.filter(item => item.id !== id)
        this.forceUpdate()
    }

    updateSportVisits = () => {
        var visits = this.props.join.sportVisits
        visits.map(item => {
            delete(item.id)
        })
        fetch(process.env.REACT_APP_API_URL + "join/" + this.props.join.id, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(visits)
        }).then(data => {
            return data.json()
        }).then(result => {
            toastr.success('Verwerkt', 'De presentie is verwerkt.')
            this.props.handlerPresentions('blabla')
        }).catch((error) => {
            console.error(error);
        });
    }

    onChangeHandlerSportVisitsValue(i, event) {
        let visits = this.props.join.sportVisits;
        visits[i][event.target.name] = event.target.value;
        this.props.join.sportVisits = visits
        this.forceUpdate()
    }

    render() {
        if (this.props.join == null) return (<div></div>)
        return (
            <Modal size="xl" show={this.props.show} onHide={() => this.props.handleShowPresentionJoinModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>Presentie: {this.props.join.period.name} | {this.props.join.clinic.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <th>Type</th>
                            <th style={{minWidth:'190px'}}>Presentie</th>
                            <th>Inchecktijd</th>
                            <th>Uitchecktijd</th>
                            <th>Opmerking</th>
                            <th>Verwijderen</th>
                        </thead>
                        <tbody>
                            {
                                this.props.join.sportVisits.map((visit, i) => {
                                    return (
                                        <tr key={visit.id}>
                                            <td>{visit.isManually ? <FaLaptopCode size={28}/> : <VscDeviceMobile size={28}/>}</td>
                                            <td>
                                                <PresentionsButtons
                                                    id={visit.id}
                                                    value={visit.presentionType}
                                                    handlerChangePresentionType={this.handlerChangePresentionType} />
                                            </td>
                                            <td>
                                                <Form.Group controlId="exampleForm.ControlInput1">
                                                    <Form.Control 
                                                    type="datetime-local" 
                                                    placeholder="dd-mm-yyyy hh:mm" 
                                                    value={visit.inCheckDateTime} 
                                                    name="inCheckDateTime"
                                                    onChange={this.onChangeHandlerSportVisitsValue.bind(this, i)}/>
                                                </Form.Group>
                                            </td>
                                            <td>
                                                <Form.Group controlId="exampleForm.ControlInput1">
                                                    <Form.Control 
                                                    type="datetime-local" 
                                                    placeholder="dd-mm-yyyy hh:mm" 
                                                    value={visit.outCheckDateTime}
                                                    name="outCheckDateTime"
                                                    onChange={this.onChangeHandlerSportVisitsValue.bind(this, i)} />
                                                </Form.Group>
                                            </td>
                                            <td>
                                                <Form.Group controlId="exampleForm.ControlInput1">
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="Opmerking" 
                                                    value={visit.comment}
                                                    name="comment"
                                                    onChange={this.onChangeHandlerSportVisitsValue.bind(this, i)} />
                                                </Form.Group>
                                            </td>
                                            <td><RiDeleteBin6Line onClick={() => this.removeSportVisit(visit.id)}/></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <Button className='mb-2' onClick={() => this.handlerAddSportVisits()}>+</Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.props.handleShowPresentionJoinModal()}>
                        Sluiten
          </Button>
                    <Button variant="primary" onClick={() => this.updateSportVisits()}>
                        Opslaan
          </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default PresentionModal;

//     "sportVisits": [{
//         "id": "736faad6-d1ae-4b43-c5a5-08d8e1b1bdd8",
//         "presentionType": "IsAbsent",
//         "inCheckDateTime": "2021-01-15T14:35:39.625778",
//         "outCheckDateTime": "2021-01-15T15:35:39.625778",
//         "comment": "Niks"
//     }]
// }]