import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import { toastr } from 'react-redux-toastr'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import { BsPencil } from "react-icons/bs"
import PresentionModal from '../../components/presentionModal'
import LoadSymbol from '../../components/LoadSymbol';
import PresentionsButtons from '../../components/PresentionButtons'
import update from 'immutability-helper';
import Button from 'react-bootstrap/Button'

class ClinicPresention extends Component {

    constructor(props) {
        super(props);
        var array = window.location.pathname.split('/');
        this.state = {
            clinicId: array.pop(),
            name: decodeURIComponent(array.pop()),
            periods: [],
            selectedPeriodId: "",
            joins: [],
            showPresentionJoinModal: false,
            selectedJoin: null,
            totalPresent: 0,
            totalPresentWith: 0,
            showLoadIcon: false,
            maxAmountPresentions: 5
        }


        this.handlerPresentions = this.handlerPresentions.bind(this)
        this.handleShowPresentionJoinModal = this.handleShowPresentionJoinModal.bind(this)
    }
    componentWillMount() {
        this.getPeriods()
    }

    getPeriods() {
        fetch(process.env.REACT_APP_API_URL + "period/v1",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ periods: data })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    onChangePeriod = e => {
        let periodId = e.target.value
        this.setState({selectedPeriodId: periodId, joins: [], showLoadIcon: true}, () => this.getJoins(this.state.clinicId, periodId));
        
    }

    getJoins(clinicId, periodId) {
        console.log("Get joins of clinicId: " + clinicId + " in periodId: " + periodId)

        fetch(process.env.REACT_APP_API_URL + "clinic/presention/" + clinicId +"/" + periodId,
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({ joins: data, showLoadIcon: false }, () => {this.calcTotals()})
            })
            .catch(error => {
                console.log(error);
                this.setState({showLoadIcon:false})
                // localStorage.clear();
                // location.href = '/'
            });
    }

    calcTotals() {
        var joins = this.state.joins
        var totalPresent = 0
        var totalPresentWith = 0

        var maxAmountPresentions = 5

        joins.map(join => {
            totalPresentWith = totalPresentWith + join.sportVisits.filter(x => x.presentionType === "IsPresentWith").length
            totalPresent = totalPresent + join.sportVisits.filter(x => x.presentionType === "IsPresent").length

            if(maxAmountPresentions < join.sportVisits.length){
                maxAmountPresentions = join.sportVisits.length
            }
        })



        console.log(maxAmountPresentions)

        this.setState({
            totalPresent: totalPresent,
            totalPresentWith: totalPresentWith,
            maxAmountPresentions : maxAmountPresentions
        })
    }

    handlerPresentions(someArg) {
        this.handleShowPresentionJoinModal()
    }

    handleShowPresentionJoinModal(obj) {
        this.setState({ showPresentionJoinModal: !this.state.showPresentionJoinModal, selectedJoin: obj })
    }

    handlerChangePresentionType = (id, type) => {
        console.log("Retrieve sportvisit id: " + id + " changing to " + type)

        var join = null
        this.state.joins.forEach(obj => {
            // console.log(obj.sportVisits.filter(x => x.id == id))
            if(obj.sportVisits.filter(x => x.id == id).length > 0){
                join = obj
                return
            }

        })

        if(join != null){
            //We have found a exists join. So its mean we can update the sportvisit
            console.log(join)

            var data = join.sportVisits
            var commentIndex = data.findIndex(function (c) {
                return c.id == id;
            });

            var updatedComment = update(data[commentIndex], { presentionType: { $set: type } });

            var newData = update(data, {
                $splice: [[commentIndex, 1, updatedComment]]
            });
            join.sportVisits = newData

            console.log(join)

            this.updateSportVisits(join.id, join.sportVisits)
        } else {
            //So we dont have found a join and its means that we must add a new sportvisit
            console.log("Add new sportvisit to join: " + id + " with presentionType: " + type)

            //find the join
            join = this.state.joins.filter(x => x.id == id)[0]
            
            if(join == null) {return}

            console.log(join)

            join.sportVisits.push({
                id: "Random" + join.sportVisits.length + 1,
                presentionType: type,
                inCheckDateTime: new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0],
                outCheckDateTime: new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0],
                comment: "",
                isManually: true
            });
            
            this.updateSportVisits(join.id, join.sportVisits)
        }
    }

    updateSportVisits(joinId, visits) {
        visits.map(item => {
            delete(item.id)
        })
        fetch(process.env.REACT_APP_API_URL + "join/" + joinId, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(visits)
        }).then(data => {
            return data.json()
        }).then(result => {
            toastr.success('Verwerkt', 'De presentie is verwerkt.')
        
            console.log(result)
            var join = this.state.joins.filter(x => x.id == result.id)[0]
        
            join.sportVisits = result.sportVisits


            var index = this.state.joins.findIndex(x => x.id == join.id)

            console.log("Get based on index" + index)

            var joins = this.state.joins

            joins[index] = join

            console.log(joins[index])

            this.setState({joins: joins}, () => {this.calcTotals()})

        }).catch((error) => {
            console.error(error);
        });
    }

    render() {
        return <Container fluid style={{width: "max-content", "min-width": "100%"}}>
            <Row>
                <Col style={{margin:"0px", padding:"0px"}}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100" style={{width: "max-content"}}>
                <Col xs={1.8}>
                    <SideNavBar className='sidebar'/>
                </Col>

                <Col>
                {/* Here comes the content of the page */}
                    <h1>Presentie sportkeuze: {this.state.name}</h1>

                    <Row>
                        <Col>
                        <Form.Group controlId="periodId">
                                <Form.Label>Selecteer een blok</Form.Label>
                                <Form.Control name="periodId" as="select" onChange={this.onChangePeriod} required>
                                    <option value="select">Selecteer blok</option>
                                    {
                                        this.state.periods.map(period => {
                                            return (
                                                <option value={period.id}>{period.name}</option>
                                            )
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                                Aantal aanwezig: {this.state.totalPresent}<br/>
                                Aantal geoorloofd afwezig: {this.state.totalPresentWith}<br/></Col>
                        <Col sm={8}>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {
                                this.state.showLoadIcon ? (
                                    <LoadSymbol/>
                                ) : (
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Studentnaam:</th>
                                        <th>Klas:</th>
                                        <th>Aantal aanwezig</th>
                                        <th>Aantal geoorloofd afwezig</th>
                                        <th>Presentie:</th>
                                        {
                                             Array(this.state.maxAmountPresentions).fill(1).map((el, i) =>
                                                <th>{++i}</th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.joins.length > 0 ? (
                                        this.state.joins.map(join =>
                                            <tr key={join.id}>
                                                <td>{join.student.firstName} {join.student.insert} {join.student.lastName}</td>
                                                <td>{join.student.classes.filter(c => c.active)[0]?.class.name}</td>
                                                <td>{join.sportVisits.filter(x => x.presentionType === 'IsPresent').length}</td>
                                                <td>{join.sportVisits.filter(x => x.presentionType === 'IsPresentWith').length}</td>
                                                <td><BsPencil onClick={() => this.handleShowPresentionJoinModal(join)}/></td>

                                                {
                                                    join.sportVisits.map(visit => 
                                                        <td>
                                                        <PresentionsButtons
                                                            id={visit.id}
                                                            value={visit.presentionType}
                                                            handlerChangePresentionType={this.handlerChangePresentionType}/></td>
                                                    )
                                                }
                                                {

                                                    (join.sportVisits.length < this.state.maxAmountPresentions) ?
                                                    (
                                                        Array(this.state.maxAmountPresentions - join.sportVisits.length).fill(1).map((el, i) =>
                                                        <td>
                                                        <PresentionsButtons
                                                        key={(join.sportVisits.length + i)}
                                                            id={join.id}
                                                            handlerChangePresentionType={this.handlerChangePresentionType}/>
                                                            </td>
                                                        )
                                                    ) : ("")
                                                }
                                            </tr>    
                                        )
                                        ) : ("Er zijn geen resultaten")
                                    }
                                </tbody>
                            </Table>
                            )
                        }
                        <Button style={{float: "right"}} onClick={()=> this.setState({maxAmountPresentions: (this.state.maxAmountPresentions +1)})}>Extra presentie</Button>

                        </Col>
                    </Row>
                </Col>
            </Row>

            <PresentionModal
                    handlerPresentions={this.handlerPresentions.bind(this)}
                    handleShowPresentionJoinModal={this.handleShowPresentionJoinModal.bind(this)}
                    show={this.state.showPresentionJoinModal}
                    join={this.state.selectedJoin} />
            />
        </Container>
    }
}

export default ClinicPresention;