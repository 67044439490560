import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'

import Button from 'react-bootstrap/Button'

class Schoolyear extends Component {
    constructor(props) {
        super(props);

        this.state = {
            schoolyear: {},
        };
    }
    

    componentDidMount() {
        this.getCurrentSchoolYear()
    }

    getCurrentSchoolYear() {
        fetch(process.env.REACT_APP_API_URL + "schoolyear/current",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => {
                if (response.status == 204) {
                    console.log("response is empty")
                } else {
                    return response.json()
                }
            }
            )
            .then(data => {
                this.setState({ schoolyear: data })
            })
            .catch(error => {
                localStorage.clear();
                location.href = '/'
            });
    }
    render() {
        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Schooljaar starten/afsluiten</h1>
                    <Row className="mt-5 p-5">
                        <p className="w-100 text-center" style={{ fontSize: 20 }}>
                            {
                                (this.state.schoolyear == null) ?
                                    (
                                        <p>Er is nog geen schooljaar aangemaakt. Druk op onderstaande knop om een schooljaar aan te maken.</p>
                                    ) : (
                                        <h4>Weet je zeker dat je het afgelopen schooljaar wilt afsluiten?<br/>
                                        Nadat je op de knop hebt geklikt is er geen weg meer terug.<br/>
                                        Alle blokken worden hierna definitief afgesloten!</h4>
                                    )
                            }
                        </p>
                    </Row>

                    <Row className="h-50 center">
                        <Button className="w-75 h-25 text-center" onClick={event =>  window.location.href='/startschoolyear'} variant="danger">
                            {
                                (this.state.schoolyear == null) ?
                                    (

                                        <h3>Schooljaar starten</h3>
                                    ) : (

                                        <h3>Schooljaar afsluiten</h3>
                                    )
                            }
                        </Button>
                    </Row>

                </Col>
            </Row>
        </Container>
    }
}

export default Schoolyear;