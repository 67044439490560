import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import JsCookie from 'js-cookie'
import { FiEye } from "react-icons/fi";

class LastViewedStudents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            students: []
        }
    }

    componentDidMount() {
        this.getStudents()
    }

    getStudents() {

        fetch(process.env.REACT_APP_API_URL + "monitor/lastviewstudent/employee/" + JsCookie.get("guid"),
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            })
            .then((resp) => resp.json())
            .then(data => {
                console.log(data)
                this.setState({ students: data });
            });
    }

    render() {
        return (
            <Row>
                <Col>
                <Card>
                        <Card.Body>
                            <Card.Title>Laatst bezochte studenten</Card.Title>
                            <Card.Text>
                                <Table borderless>

                                
                            {
                                    this.state.students.map(student => {

                                        return (

                                            <tr key={student.id}>
                                                    <td>
                                                    <a href={"student/" + student.student.id} className="addons-item">
                                                        {student.student.firstName} {student.student.insert} {student.student.lastName}</a> 
                                                    </td>
                                                    <td>
                                                        <a href={"student/" + student.student.id} className="addons-item"><FiEye /></a>
                                                    </td>
                                            </tr>
                                        )
                                    })
                                }

                                {
                                    this.state.students.length <= 0 ? ("Geen laatst bezochte studenten") : ""
                                }
                                </Table>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default LastViewedStudents;