import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import JsCookie from 'js-cookie'
import { FiEye } from "react-icons/fi";

class OwnClassesBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            classes: []
        }
    }

    componentDidMount() {
        this.getClasses()
    }

    getClasses() {

        fetch(process.env.REACT_APP_API_URL + "class/employee/" + JsCookie.get("guid"),
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            })
            .then((resp) => resp.json())
            .then(data => {
                console.log(data)
                this.setState({ classes: data });
            });
    }

    render() {
        const columns = [
            { 
                dataField: 'id', 
                text: 'id',
                hidden: true 
            },
            { 
                dataField: 'name', 
                text: 'Klascode',
                hidden: false,
                filter: textFilter()
            },
            {
                dataField: 'id',
                text: 'Bekijk',
                formatter: (cell) => {
                 return <a href={"class/" + cell} className="addons-item"><FiEye /></a>
               },
            }
        ]
        return (
            <Row>
                <Col>
                <Card>
                        <Card.Body>
                            <Card.Title>Eigen klassen</Card.Title>
                            <Card.Text>
                            <BootstrapTable bootstrap4 keyField='id' 
                            classes='table-hover'
                            data={ this.state.classes } 
                            columns={ columns } 
                            // defaultSorted={defaultSorted} 
                            pagination={ paginationFactory({sizePerPageList: [ {text: '10', value: 10}, {text: '50', value: 50}, {text: '100', value: 100}, {text: 'All', value: this.state.classes.length} ],}) }
                            filter={ filterFactory() }
                            />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default OwnClassesBox;