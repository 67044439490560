import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Logo from '../images/icon_som_80px.png'
import Image from 'react-bootstrap/Image'
import JsCookie from 'js-cookie'
import { MdReport } from "react-icons/md";
import SearchBar from '../components/SearchBar'

class TopNavBar extends Component {

    logOut() {
        localStorage.clear();
        location.href = '/'
    }

    render() {
        return <div>
            <Navbar collapseOnSelect expand="lg" variant="dark" style={{
                backgroundColor: '#222'
                }}>
                <Navbar.Brand href="#home">
                    <Image
                        alt=""
                        src={Logo}
                        style={{ width: "30px", height: "30px" }}
                        className="d-inline-block align-top"
                        roundedCircle
                    />{' '}
                    Sport op Maat</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto" >
                        <SearchBar/>
                    </Nav>
                    <Nav>
                        <Nav.Link href="mailto:support@impercode.com" style={{color: "#fff"}}><MdReport size={22}/> Probleem melden</Nav.Link>
                        <Nav.Link href="#deets" style={{color: "#fff"}}>Welkom {JsCookie.get('name')}</Nav.Link>
                        <Nav.Link href="#deets" style={{color: "#fff"}} onClick={() => this.logOut()}>Log uit</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    }
}

export default TopNavBar;