import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import Table from 'react-bootstrap/Table'
import { MdDeleteForever } from 'react-icons/md';
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import { toastr } from 'react-redux-toastr'
import Form from 'react-bootstrap/Form'

class SportdayJoin extends Component {

    constructor(props) {
        super(props);
        var array = window.location.pathname.split('/');

        this.state = {
            sportdayName: array.pop(),
            sportdayId: (array.pop()).replace(/%20/g, " "),
            students: [],
            joinedStudents: [],
            selectAll: false
        };
    }

    componentDidMount(){
        this.getAllJoinedStudents()
    }

    searchHandler = e => {
        const value = e.target.value;

        if (value.length >= 3) {
            this.searchStudent(value)
        }
    }

    searchStudent(keyword) {
        fetch(process.env.REACT_APP_API_URL + "student/" + keyword + "/search",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ students: data })
            })
            .catch(error => {
                console.log(error)
            });
    }

    getAllJoinedStudents() {

        fetch(process.env.REACT_APP_API_URL + "sportday/" + this.state.sportdayId,
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                this.setState({ joinedStudents: responseJson })
            })
            .catch((error) => {
                console.error(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    addStudent(student) {
        var array = this.state.joinedStudents
        var duplicatie = array.filter(x => x.student.id === student.id) 
        if(duplicatie.length > 0) {
            toastr.error('Student', 'Deze student is al toegevoegd.')
            return
        }
        //Create object
        var obj = {
            presentionType : "",
            student : student,
            sportday: {
                id : this.state.sportdayId
            }
        }
        var json = []
        json.push(obj)

        fetch(process.env.REACT_APP_API_URL + "sportday/students",
            {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(json)
            })
            .then((response) => response.json())
            .then((response) => {
                var join = response.pop()
                join.student = student
                array.push(join)
                this.setState({joinedStudents: array})
                toastr.success('Student toegevoegd', 'Je hebt een student aan de sportdag toegevoegd!')
            })
            .catch((error) => {
                console.error(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    removeStudent(joinSportdayId) {

        fetch(process.env.REACT_APP_API_URL + "sportday/student/" + joinSportdayId,
        {
            method: 'DELETE',
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((responseJson) => {

            var array = this.state.joinedStudents
            array = array.filter(x => x.id !== joinSportdayId)
            this.setState({joinedStudents: array})

            toastr.success('Student uitgeschreven', 'Je hebt een student bij de sportdag uitgeschreven!')
        })
        .catch((error) => {
            console.error(error);
            // localStorage.clear();
            // location.href = '/'
        });

    }

    selectAll(){
        var students = this.state.students
        this.setState({selectAll: !this.state.selectAll})
        students.forEach( x=> {
            this.addStudent(x)
        })
    }


    render() {
        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Inschrijven voor sportdag: {this.state.sportdayName.replace(/%20/g, " ")}</h1>
                    <Row>
                        <Col>
                            <h4>Ingeschreven studenten</h4>
                            <Table>
                                <thead>
                                    <th>Studentnummer</th>
                                    <th>Naam</th>
                                    <th>Klas</th>
                                    <th>Verwijderen</th>
                                </thead>
                                <tbody>
                                    {
                                        this.state.joinedStudents.map(x => {
                                            return (
                                                <tr key={x.student.id}>
                                                    <td>{x.student.studentId}</td>
                                            <td>{x.student.firstName + " " + x.student.insert + " " + x.student.lastName}</td>
                                                    <td>{x.student.classes[0] != null ? (x.student.classes[0].class.name) : "Niet bekend"}</td>
                                                    <td><MdDeleteForever onClick={() => this.removeStudent(x.id)}/></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                        <Col>
                            <h4>Toevoegen studenten</h4>
                            <Row>
                                <Col>
                                    <InputGroup size="sm" className="mt-2">
                                        <FormControl
                                            placeholder="Zoek student naam, klas"
                                            aria-label="text"
                                            aria-describedby="basic-addon2"
                                            onChange={this.searchHandler}
                                        />
                                        <InputGroup.Append style={{ backgroundColor: '#0064AD', }} >
                                            <InputGroup.Text id="basic-addon2" style={{ backgroundColor: '#0064AD', borderColor: '#0064AD', color: '#fff' }}>Zoek</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Row>
                                
                            <Row>
                                <Table>
                                    <thead>
                                        <th>Studentnummer</th>
                                        <th>Studentnaam</th>
                                        <th>Klas</th>
                                        <th onClick={() => this.selectAll()}>Selecteer <Form.Check type="checkbox" checked={this.state.selectAll}/></th>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.students.map(student => {
                                                return (
                                                    <tr key={student.id}>
                                                        <td>{student.studentId}</td>
                                                <td>{student.firstName} {student.insert} {student.lastName}</td>
                                                        <td>{student.classes[0] != null ? (student.classes[0].class.name) : "Niet bekend"}</td>
                                                        <td>
                                                            <Button onClick={() => this.addStudent(student)}>+</Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    }
}

export default SportdayJoin;