import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav'
import '../stylesheets/sidenavbar.css';
import JsCookie from 'js-cookie'

class SideNavBar extends Component {

    render() {

        return <div className="h-100">
            <Nav defaultActiveKey="/home" className="flex-column h-100" style={{
                backgroundColor: '#222'
            }}>
                <Nav.Link href="/Dashboard" className='list-item'>Dashboard</Nav.Link>
                <Nav.Link href="/students" className='list-item'>Studenten</Nav.Link>
                <Nav.Link href="/classes" className='list-item'>Klassen</Nav.Link>
                <Nav.Link href="/Clinic" className='list-item'>Sportkeuzes</Nav.Link>
                <Nav.Link href="/sportdays" className='list-item'>Sportdagen</Nav.Link>
                <Nav.Link href="/openjoins" className='list-item'>Inschrijvingen openen</Nav.Link>
                <Nav.Link href="/Notification" className='list-item'>Notificaties</Nav.Link>
                <Nav.Link href="/unlock" className='list-item'>Ontgrendel code</Nav.Link>
                <Nav.Link href="/manageclass" className='list-item'>Klassen beheren</Nav.Link>
                <Nav.Link href="/studentimages" className='list-item'>Afbeeldingen importeren</Nav.Link>

                {
                    JsCookie.get('isSuperUser') ? <Nav.Link href="/contentmanagement" className='list-item'>Info SoM beheren</Nav.Link> : ''
                }
                {
                    JsCookie.get('isSuperUser') ? <Nav.Link href="/imports" className='list-item'>Importeren</Nav.Link> : ''
                }
                {
                    JsCookie.get('isSuperUser') ? <Nav.Link href="/employee" className='list-item'>Medewerkers</Nav.Link> : ''
                }
                {
                    JsCookie.get('isSuperUser') ? <Nav.Link href="/schoolyear" className='list-item'>Schooljaar beheren</Nav.Link> : ''
                }
            </Nav>

        </div>
    }
}

export default SideNavBar;