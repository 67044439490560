import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Table from 'react-bootstrap/Table'
import { BiPrinter } from 'react-icons/bi'
import { FaRegEdit } from 'react-icons/fa'

class Classes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            classes: []
        }
    }

    searchHandler = e => {
        const value = e.target.value;

        if (value.length >= 1) {
            this.searchClass(value)
        }
    }

    searchClass(keyword) {
        fetch(process.env.REACT_APP_API_URL + "class/search/" + keyword,
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({ classes: data })
            })
            .catch(error => {
                console.log(error)
            });
    }

    onClickPresentionExtend(id) {
        fetch(process.env.REACT_APP_API_URL + "class/" + id + "/print/extend",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {
                var str = responseJson

                let buff = new Buffer(str, 'base64');
                let base64ToStringNew = buff.toString();
                console.log(base64ToStringNew)

                var winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
                winPrint.document.write(base64ToStringNew);
                winPrint.document.close();
            })
            .catch((error) => {
                console.error(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }
    onClickPresention(id) {
        fetch(process.env.REACT_APP_API_URL + "class/" + id + "/print",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {
                var str = responseJson

                let buff = new Buffer(str, 'base64');
                let base64ToStringNew = buff.toString();
                console.log(base64ToStringNew)

                var winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
                winPrint.document.write(base64ToStringNew);
                winPrint.document.close();
            })
            .catch((error) => {
                console.error(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    render() {
        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Klassen overzicht</h1>

                    <Row>
                        <Col>
                            <InputGroup size="sm" className="mt-2">
                                <FormControl
                                    placeholder="Zoek klas"
                                    aria-label="text"
                                    aria-describedby="basic-addon2"
                                    onChange={this.searchHandler}
                                />
                                <InputGroup.Append style={{ backgroundColor: '#0064AD', }} >
                                    <InputGroup.Text id="basic-addon2" style={{ backgroundColor: '#0064AD', borderColor: '#0064AD', color: '#fff' }}>Zoek</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Klas code</th>
                                        <th>SLB'er</th>
                                        <th>Presentielijst uitgebreid</th>
                                        <th>Presentielijst</th>
                                        <th>Live overzicht</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.classes.map(studentClass => {
                                            return (
                                                <tr key={studentClass.id}>
                                                    <td>{studentClass.name}</td>
                                                    <td>{studentClass.mentor}</td>
                                                    <td style={{ "textAlign": "center", "cursor": "pointer" }}>
                                                        <BiPrinter size={28} onClick={() => this.onClickPresentionExtend(studentClass.id)} />
                                                    </td>
                                                    <td style={{ "textAlign": "center", "cursor": "pointer" }}>
                                                        <BiPrinter size={28} onClick={() => this.onClickPresention(studentClass.id)} />
                                                    </td>
                                                    <td style={{ "textAlign": "center", "cursor": "pointer" }}>
                                                        <a href={"/class/" + studentClass.id} className="addons-item">
                                                            <FaRegEdit size={28} />
                                                        </a>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    }
}

export default Classes;