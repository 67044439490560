import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import { ImCheckmark } from 'react-icons/im'

class PresentionButtons extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ""
        }
    }

    handlePresentionChange(type) {
        console.log("Update presentiontype to: " + type)
        this.setState({ value: type }, () => {
            this.props.handlerChangePresentionType(this.props.id, this.state.value)
        })
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.value === "") {
            return {
                value: props.value
            }
        }
        return {
            value: current_state.value
        }
    }

    render() {
        // console.log(this.props.id)
        return (
            <div  style={{width: "max-content"}}>
                <Button
                    variant="success"
                    onClick={() => this.handlePresentionChange("IsPresent")}>
                    {this.state.value == "IsPresent" ? (<ImCheckmark />) : (<ImCheckmark style={{ visibility: "hidden" }} />)}
                </Button>
                <Button variant="warning" className="ml-2" style={{ color: '#fff' }} onClick={() => this.handlePresentionChange("IsPresentWith")}>
                {this.state.value == "IsPresentWith" ? (<ImCheckmark />) : (<ImCheckmark style={{ visibility: "hidden" }} />)}
                </Button>
                <Button variant="danger" className="ml-2" onClick={() => this.handlePresentionChange("IsAbsent")}>

                {this.state.value == "IsAbsent" ? (<ImCheckmark />) : (<ImCheckmark style={{ visibility: "hidden" }} />)}

                </Button>
            </div>
        )
    }
}

export default PresentionButtons;