import React, { Component } from 'react';

import DatePickerReact from "react-datepicker";
import nl from 'date-fns/locale/nl';
import { registerLocale, setDefaultLocale } from "react-datepicker";
registerLocale('nl', nl)

import "react-datepicker/dist/react-datepicker.css";

/*DateTimePicker
Als props is required the onChange

Props:
Name        Type        isRequired      Return
onChange    callBack    required        Iso datetimestring
dateFormat  string      required        nvt
placeholder string      required        nvt
showTime    boolean     required        nvt
index       integer     not             index of picker
*/
class DatePicker extends Component {

    constructor(props) {
        super(props);

        this.state = {
            startDate: null,
        }
    }


    handleDateChange = e => {
        var date = e;
        var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
        console.log(isoDateTime)
        this.setState({ startDate: e })
        
        this.props.onChange(isoDateTime, this.props.index)
    }

    render() {
        return (
                <DatePickerReact
                    className="form-control w-100"
                    selected={(this.state.startDate != null) ? (this.state.startDate) : ("")}
                    onChange={this.handleDateChange}
                    showTimeSelect={this.props.showTime}
                    dateFormat={this.props.dateFormat}
                    locale="nl"
                    placeholderText={this.props.placeholder}
                    name="yeah"
                    index={this.props.index}
                    autoComplete="off"
                    required
                />
        )
    }
}

export default DatePicker;