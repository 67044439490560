import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import { toastr } from 'react-redux-toastr'

import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

class Notification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
            showClinicSelect: false,
            showStudentSelect: false,
            students: [{
                checked: false
            }],
            clinics: [],
            checkedAllStudents: false,
            title: "",
            body: "",
            periods:[],
            selectedPeriodId: "select"
        };
    }

    componentWillMount() {
        this.getNotifications()
        this.getPeriods()
    }

    getPeriods() {
        fetch(process.env.REACT_APP_API_URL + "period/v1",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ periods: data })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    getNotifications() {
        fetch(process.env.REACT_APP_API_URL + "notification",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => { this.setState({ notifications: data }) })
            .catch(error => {
                localStorage.clear();
                location.href = '/'
            });
    }

    onClickViewClinic() {
        this.setState({
            showClinicSelect: true,
            showStudentSelect: false,
            checkedAllStudents: false,
            students: []
        })
    }

    onClickViewStudents() {
        this.setState({
            showClinicSelect: false,
            showStudentSelect: true,
            clinics: []
        })
    }

    onChangeSearchClinic = e => {

    }

    searchHandler = e => {
        const value = e.target.value;

        if (value.length >= 3) {
            this.searchStudent(value)
        }
    }

    searchHandlerClinic = e => {
        const value = e.target.value;

        if (value.length >= 1) {
            this.searchClinic(value)
        }
    }

    searchClinic(keyword) {
        fetch(process.env.REACT_APP_API_URL + "clinic/" + keyword + "/search",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ clinics: data })
            })
            .catch(error => {
                console.log(error)
            });
    }

    searchStudent(keyword) {
        fetch(process.env.REACT_APP_API_URL + "student/" + keyword + "/search",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ students: data })
            })
            .catch(error => {
                console.log(error)
            });
    }

    onChangeAllStudents = e => {
        var checked = e.target.checked
        this.setState({ checkedAllStudents: checked })
    }

    onChangeTitle = e => {
        var val = e.target.value
        this.setState({ title: val })
    }
    onChangeBody = e => {
        var val = e.target.value
        this.setState({ body: val })
    }

    onChangeSelectStudent = e => {
        var checked = e.target.checked
        var id = e.target.id

        var students = this.state.students
        var idx = students.findIndex(x => x.id == id)
        students[idx].checked = checked

        this.setState({ students: students })
    }

    onChangeSelectClinic = e => {
        var checked = e.target.checked
        var id = e.target.id

        var clinics = this.state.clinics
        var idx = clinics.findIndex(x => x.id == id)
        clinics[idx].checked = checked
        this.setState({ clinics: clinics })
    }

    onClickSendNotifications() {
        var title = this.state.title
        var body = this.state.body
        var isClinic = this.state.showClinicSelect
        var isStudents = this.state.showStudentSelect
        var isAll = this.state.checkedAllStudents

        var obj = {
            title: title,
            body: body,
            studentsNumbers: []
        }

        if (isClinic) {
            var periodId = this.state.selectedPeriodId
            var clinics = this.state.clinics

            clinics.map(clinic => {
                this.sendNotificationToClinicStudents(obj, clinic.id, periodId)
            })
        } else if (isStudents && !isAll) {
            var students = this.state.students

            var studentIds = []

            students.map(student => {
                if (student.checked) {
                    studentIds.push(student.id)
                }
            })
            obj.studentsNumbers = studentIds

            this.sendNotification(obj)

        } else if (isStudents && isAll) {
            this.sendNotification(obj)
        }


    }

    sendNotificationToClinicStudents(obj, clinicId, periodId) {
        fetch(process.env.REACT_APP_API_URL + "notification/clinic/" + clinicId + "/" + periodId,
            {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson == true) {
                    toastr.success('Notificatie verstuurd', 'Notificicatie is verstuurd.')
                    this.getNotifications()
                } else {
                    toastr.error('Notificatie niet verstuurd', 'Het is niet gelukt om de notificiaties te versturen.')
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    sendNotification(obj) {
        fetch(process.env.REACT_APP_API_URL + "notification",
            {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson == true) {
                    toastr.success('Notificatie verstuurd', 'Notificicatie is verstuurd.')
                    this.getNotifications()
                } else {
                    toastr.error('Notificatie niet verstuurd', 'Het is niet gelukt om de notificiaties te versturen.')
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    onChangeSelectPeriod = e => {
        var val = e.target.value

        this.setState({selectedPeriodId: val})
    }

    render() {
        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Notificaties</h1>

                    <Row>
                        <Col xs={4}>
                            <h3>Notificatie maken</h3>

                            <Form>
                                <Form.Group controlId="formTitle">
                                    <Form.Label>Titel:</Form.Label>
                                    <Form.Control type="text" placeholder="Titel" onChange={this.onChangeTitle} />
                                </Form.Group>
                                <Form.Group controlId="formBody">
                                    <Form.Label>Inhoud notificatie</Form.Label>
                                    <Form.Control as="textarea" rows={3} onChange={this.onChangeBody} />
                                </Form.Group>
                            </Form>
                            <hr></hr>
                            <h5>Voor wie is de notificatie bedoelt?</h5>
                            <Row>
                                <Col xs={4}>
                                    <Button onClick={() => this.onClickViewClinic()}>Clinic</Button>
                                </Col>
                                <Col xs={4}>
                                    <Button onClick={() => this.onClickViewStudents()}>Studenten</Button>
                                </Col>
                                <Col>
                                    {
                                        this.state.showClinicSelect == true || this.state.showStudentSelect == true ? (
                                            <Button onClick={() => this.onClickSendNotifications()} variant="outline-primary">Versturen</Button>
                                        ) : ("")
                                    }
                                </Col>
                            </Row>
                            <hr></hr>
                            {
                                this.state.showClinicSelect == true || this.state.showStudentSelect == true ? (
                                    <div>
                                        <Row>
                                            <Col>
                                                {
                                                    this.state.showStudentSelect == true ? (
                                                        // Students 
                                                        <div>
                                                            <Form.Group controlId="formBasicCheckbox">
                                                                <Form.Check type="checkbox" label="Alle studenten?" onChange={this.onChangeAllStudents} />
                                                            </Form.Group>

                                                            {
                                                                this.state.checkedAllStudents == false ? (
                                                                    <InputGroup size="sm" className="mt-2">
                                                                        <FormControl
                                                                            placeholder="Zoek student"
                                                                            aria-label="text"
                                                                            aria-describedby="basic-addon2"
                                                                            onChange={this.searchHandler}
                                                                        />
                                                                        <InputGroup.Append style={{ backgroundColor: '#0064AD', }} >
                                                                            <InputGroup.Text id="basic-addon2" style={{ backgroundColor: '#0064AD', borderColor: '#0064AD', color: '#fff' }}>Zoek</InputGroup.Text>
                                                                        </InputGroup.Append>
                                                                    </InputGroup>
                                                                ) : ("")
                                                            }

                                                        </div>
                                                    ) : (
                                                            // Clinics
                                                            <div>
                                                                <Form.Group controlId="exampleForm.ControlSelect1" onChange={this.onChangeSelectPeriod}>
                                                                    <Form.Label>Selecteer blok</Form.Label>
                                                                    <Form.Control as="select">
                                                                        <option value="select">Selecteer</option>
                                                                        {
                                                                            this.state.periods.map(period => {
                                                                                return (
                                                                                    <option value={period.id}>{period.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Form.Control>
                                                                </Form.Group>
                                                                <InputGroup size="sm" className="mt-2">

                                                                    <FormControl
                                                                        placeholder="Zoek clinic"
                                                                        aria-label="text"
                                                                        aria-describedby="basic-addon2"
                                                                        onChange={this.searchHandlerClinic}
                                                                    />
                                                                    <InputGroup.Append style={{ backgroundColor: '#0064AD', }} >
                                                                        <InputGroup.Text id="basic-addon2" style={{ backgroundColor: '#0064AD', borderColor: '#0064AD', color: '#fff' }}>Zoek</InputGroup.Text>
                                                                    </InputGroup.Append>
                                                                </InputGroup>
                                                            </div>
                                                        )
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {
                                                    this.state.checkedAllStudents == false ? (
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Naam</th>
                                                                    <th>Selecteer</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.students.map(student => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{student.firstName} {student.insert} {student.lastName}</td>
                                                                                <td>
                                                                                    <Form.Group controlId="formBasicCheckbox">
                                                                                        <Form.Check type="checkbox" id={student.id} onChange={this.onChangeSelectStudent} />
                                                                                    </Form.Group>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    this.state.clinics.map(clinic => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{clinic.name}</td>
                                                                                <td>
                                                                                    <Form.Group controlId="formBasicCheckbox">
                                                                                        <Form.Check type="checkbox" id={clinic.id} onChange={this.onChangeSelectClinic} />
                                                                                    </Form.Group>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    ) : ("")
                                                }

                                            </Col>
                                        </Row>
                                    </div>
                                ) : ("")
                            }
                        </Col>
                        <Col>
                            {/* Here comes the overview of notifcations */}
                            <h3>Overzicht</h3>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Titel</th>
                                        <th>Body</th>
                                        <th>Ontvangen</th>
                                        <th>Gelezen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.notifications.map(obj =>
                                            <tr>
                                                <td>{obj.title}</td>
                                                <td>{obj.body}</td>
                                                <td>{obj.total}</td>
                                                <td>{obj.totalRead}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>

                        </Col>
                    </Row>

                </Col>
            </Row>
        </Container>
    }
}

export default Notification;