import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import BootstrapTable from 'react-bootstrap-table-next';
import { MdDeleteForever } from 'react-icons/md';
import { FiPrinter } from 'react-icons/fi'
import { BsPersonPlus } from 'react-icons/bs'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { toastr } from 'react-redux-toastr'

class Sportdays extends Component {

    constructor(props) {
        super(props)

        this.state = {
            sportdays: [],
            showHideAddModal: false,
            sportday: {
                name: "",
                date: "",
                points: 0
            }
        }
    }

    componentDidMount() {
        this.getSportdays()
    }

    handleAddModalShowHide() {
        this.setState({ showHideAddModal: !this.state.showHideAddModal })
    }

    onChangeAddSportdayInput = e => {
        const sportday = this.state.sportday
        var name = e.target.name;
        var value = e.target.value;


        sportday[name] = value
        this.setState({ sportday: sportday });
    }

    getSportdays() {
        fetch(process.env.REACT_APP_API_URL + "sportday",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                this.setState({ sportdays: responseJson })
            })
            .catch((error) => {
                console.error(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    getPresentionList(id) {
        fetch(process.env.REACT_APP_API_URL + "sportday/" + id + "/print",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {
                    var str = responseJson

                    let buff = new Buffer(str, 'base64');
                    let base64ToStringNew = buff.toString();
                    console.log(base64ToStringNew)

                    var winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
                    winPrint.document.write(base64ToStringNew);
                    winPrint.document.close();
            })
            .catch((error) => {
                console.error(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    addSportday() {
        var sportday = this.state.sportday
        fetch(process.env.REACT_APP_API_URL + "sportday",
            {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(sportday)
            })
            .then((response) => response.json())
            .then((responseJson) => {
                var array = this.state.sportdays

                array.push(responseJson)

                this.setState({ sportdays: array })
                toastr.success('Sportdag toegevoegd', 'Je hebt een nieuwe sportdag toegevoegd!')
            })
            .catch((error) => {
                console.error(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    removeSportDay(id) {
        var sportday = this.state.sportdays.filter(item => item.id === id)[0]
        fetch(process.env.REACT_APP_API_URL + "sportday/" + id,
            {
                method: 'DELETE',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(sportday)
            })
            .then((response) => response.json())
            .then((responseJson) => {
                var array = this.state.sportdays

                array = array.filter(x => x.id !== id)
                this.setState({ sportdays: array })


                toastr.success('Sportdag verwijderd', 'Je hebt een sportdag verwijderd!')
            })
            .catch((error) => {
                console.error(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    onSubmitAddSportday = e => {
        event.preventDefault();

        this.addSportday()
    }

    render() {
        const columns = [{
            dataField: 'id',
            text: 'sportdayId',
            sort: false,
            hidden: true
        }, {
            dataField: 'name',
            text: 'Sportdag',
            sort: true
        },{
            dataField: 'points',
            text: 'Hoeveelheid punten',
            sort: false
        }, {
            dataField: 'date',
            text: 'Datum',
            sort: true,
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return `${('0' + dateObj.getDate()).slice(-2)}-${('0' + (dateObj.getMonth() + 1)).slice(-2)}-${dateObj.getFullYear()}`;
            }
        }, {
            dataField: 'print',
            text: 'Afdrukken',
            formatter: (cell, row) => <FiPrinter />,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    let id = row.id
                    this.getPresentionList(id)
                },
            }
        }, {
            dataField: 'join',
            text: 'Inschrijven',
            formatter: (cell, row) => <a href={"/sportday/" + row.id + "/" + row.name} className="addons-item"><BsPersonPlus /></a>,
        }, {
            dataField: 'remove',
            text: 'Verwijderen',
            formatter: (cell, row) => <MdDeleteForever />,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    let id = row.id
                    this.removeSportDay(id)
                },
            }
        }];

        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Sportdagen</h1>
                    <Row>
                        <Col>
                            <Button onClick={() => this.handleAddModalShowHide()}>+</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BootstrapTable
                                bootstrap4
                                keyField='id'
                                bordered={false}
                                columns={columns} data={this.state.sportdays} />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Modal show={this.state.showHideAddModal}>
                <Modal.Header closeButton onClick={() => this.handleAddModalShowHide()}>
                    <Modal.Title>Sportdag toevoegen</Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.onSubmitAddSportday}>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formAddClinicName">
                                <Form.Label>Naam</Form.Label>
                                <Form.Control type="text" placeholder="sportdag naam" name="name" onChange={this.onChangeAddSportdayInput} required />
                            </Form.Group>
                            <Form.Group controlId="formAddClinicName">
                                <Form.Label>Datum</Form.Label>
                                <Form.Control type="date" placeholder="dd-mm-yyyy" name="date" onChange={this.onChangeAddSportdayInput} required />
                            </Form.Group>
                            <Form.Group controlId="formAddClinicName">
                                <Form.Label>Te behalen punten</Form.Label>
                                <Form.Control type="number" placeholder="0" min="0" name="points" onChange={this.onChangeAddSportdayInput} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleAddModalShowHide()}>
                            Sluit
                    </Button>
                        <Button variant="primary" type="submit" >
                            Toevoegen
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </Container>
    }
}

export default Sportdays;