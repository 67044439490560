import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'

import {createStore, combineReducers} from 'redux'
import {reducer as toastrReducer} from 'react-redux-toastr'
const reducers = {
  // ... other reducers ...
  toastr: toastrReducer // <- Mounted at toastr.
}
const reducer = combineReducers(reducers)
const store = createStore(reducer)

ReactDOM.render(
  <Router>
    <div className="App">
      <Routes />
      <Provider store={store}>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          getState={(state) => state.toastr} // This is the default
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick />
      </Provider>
    </div>
  </Router>,
  document.getElementById('root')
);
