import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {AiOutlineInfoCircle} from "react-icons/ai";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import Badge from 'react-bootstrap/Badge'
import ListGroup from 'react-bootstrap/ListGroup'
import {Maps} from '../components/MapContainer'


class ErrorMessagesStudent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMessages: [],
            coordinates: [0.0,0.0]
        }
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        fetch(process.env.REACT_APP_API_URL + "monitor/" + this.props.studentId,
        {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token')
            }
        }).then(response =>{
            if(response.status == 401) {
                localStorage.clear();
                location.href = '/'
            }
            
            return response.json()})
        .then(data => {
            console.log(data)
            this.setState({ errorMessages: data })
        })
        .catch(error => {
            console.log(error)
            // localStorage.clear();
            // location.href = '/'
        });
    }

    findKeyWord(word) {
        word = word.replace('A_', '')
        switch(word){
            case "LOCATION_CHECKIN":
                return (<Badge pill variant="danger">Incheck, niet de juiste locatie</Badge>)
                break;
            case "LOCATION_CHECKOUT":
                return (<Badge pill variant="danger">Uitcheck, niet de juiste locatie</Badge>)
                break;
            case "CHECKIN_ERROR":
                return (<Badge pill variant="danger">Incheck probleem</Badge>)
                break;
            case "CHECKOUT_ERROR":
                return (<Badge pill variant="danger">Uitcheck probleem</Badge>)
                break;
            case "TIME_CHANGED":
                return (<Badge pill variant="danger">Tijdsverandering</Badge>)
                break;
            case "OVER_TIME":
                return (<Badge pill variant="warning">Nooit uitgecheckt</Badge>)
                break;
            case "MAX_SPORTVISIT_DAY":
                return (<Badge pill variant="warning">Al gesport vandaag</Badge>)
                break;
            case "LOGIN_ERROR":
                return (<Badge pill variant="Danger">Probleem met inloggen</Badge>)
                break;
            case "QR_CODE":
                return (<Badge pill variant="warning">Probleem met de QR-Code</Badge>)
                break;
            case "NETWORK_ERROR":
                return (<Badge pill variant="danger">Netwerk problemen</Badge>)
                break;
            case "NO_JOINS":
                return (<Badge pill variant="warning">Niet ingeschreven</Badge>)
                break;
            case "NO_PERMISSION_CAMERA":
                return (<Badge pill variant="warning">Geen camera toestemming</Badge>)
                break;
            case "NO_LOCATION_PERMISSION":
                return (<Badge pill variant="warning">Geen locatie toestemming</Badge>)
                break;
            case "MAX_SPORTVISITS_PERIOD":
                return (<Badge pill variant="warning">Maximaal sportbezoeken</Badge>)
                break;
            case "NO_ACTIVE_PERIOD":
                return (<Badge pill variant="warning">Geen actief blok beschikbaar</Badge>)
                break;
            case "NOT_JOINED_THIS_PERIOD":
                return (<Badge pill variant="warning">Niet ingeschreven voor dit blok</Badge>)
                break;
            case "INFO":
                return (<Badge pill variant="info">Informatie</Badge>)
                break;
            case "CRITICAL":
                return (<Badge pill variant="danger">KRITIEK</Badge>)
                break;
            case "MANUEL_CHECKOUT":
                return (<Badge pill variant="danger">Handmatige uitcheck</Badge>)
                break;
            case "ATTEMPT_CHECKOUT_BEFORE_TIME":
                return (<Badge pill variant="warning">Op uitcheck knop gedrukt</Badge>)
                break;
            case "CORRECT_LOCATION_CHECKIN":
                return (<Badge pill variant="info">Incheck locatie is correct</Badge>)
                break;
            case "CORRECT_LOCATION_CHECKOUT":
                return (<Badge pill variant="info">Uitcheck locatie is correct</Badge>)
                break;
            case "SYS_TIME":
                return (<Badge pill variant="danger">!!!Tijdsverandering gedecteerd!!!</Badge>)
                break;
            case "SYS_MAX_SPORTVISITS":
                return (<Badge pill variant="danger">!!!Maximaal sportbezoeken!!!</Badge>)
                break;
            default:
                return (<Badge pill variant="dark">{word}</Badge>)
            
        }
    }

    findSolutionByKeyword(word) {
        word = word.replace('A_', '')
        switch(word){
            case "LOCATION_CHECKIN":
                return (
                    <ListGroup>
                        <ListGroup.Item>Student is mogelijk niet op de juiste locatie</ListGroup.Item>
                        <ListGroup.Item>WiFi of vliegtuigstand een keer aan en uit doen kan helpen</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "LOCATION_CHECKOUT":
                return (
                    <ListGroup>
                        <ListGroup.Item>Met abonnement kan het zijn dat de student vergeten heeft uit te checken</ListGroup.Item>
                        <ListGroup.Item>Student is mogelijk niet op de juiste locatie</ListGroup.Item>
                        <ListGroup.Item>WiFi of vliegtuigstand een keer aan en uit doen kan helpen</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "CHECKIN_ERROR":
                return (
                    <ListGroup>
                        <ListGroup.Item>Algemen foutmelding, als het fout gaat met inchecken</ListGroup.Item>
                        <ListGroup.Item>Nogmaals proberen biedt mogelijk een oplossing</ListGroup.Item>
                        <ListGroup.Item>Bij aanhouden van het probleem, neem contact op!</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "CHECKOUT_ERROR":
                return (
                    <ListGroup>
                        <ListGroup.Item>Algemen foutmelding, als het fout gaat met uitchecken</ListGroup.Item>
                        <ListGroup.Item>Nogmaals proberen biedt mogelijk een oplossing</ListGroup.Item>
                        <ListGroup.Item>Bij aanhouden van het probleem, neem contact op!</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "TIME_CHANGED":
                return (
                    <ListGroup>
                        <ListGroup.Item>Probleem komt vaak voor bij Android, door kleine verschillen in tijd</ListGroup.Item>
                        <ListGroup.Item>Mogelijk is de tijd wel veranderd, controleer de servertijd en de tijd van de mobiel</ListGroup.Item>
                        <ListGroup.Item>!Wanneer de student geen internet heeft, is er altijd een verschil in tijd, omdat dit later wordt verwerkt!</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "OVER_TIME":
                return (
                    <ListGroup>
                        <ListGroup.Item>Deze melding treedt op wanneer de student langer dan 4 uur is ingecheckt</ListGroup.Item>
                        <ListGroup.Item>Student heeft bij het vorige bezoek niet uitcheckt</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "MAX_SPORTVISIT_DAY":
                return (
                    <ListGroup>
                        <ListGroup.Item>Student heeft vandaag al gesport, en kan niet meer sporten vandaag</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "LOGIN_ERROR":
                return (
                    <ListGroup>
                        <ListGroup.Item>Vaak nogeens opnieuw inloggen, dan verdwijnt het probleem</ListGroup.Item>
                        <ListGroup.Item>Werkt dat niet, neem dan contact op!</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "QR_CODE":
                return (
                    <ListGroup>
                        <ListGroup.Item>QR-code is niet juist, afhankelijk van de omschrijving</ListGroup.Item>
                        <ListGroup.Item>Een QR-code die het systeem registreert ziet er zo uit: 6B29FC40-CA47-1067-B31D-00DD010662DA:6B29FC40-CA47-1067-B31D-00DD010662DA Voor de dubbelepunt komt de sportkeuzeId en na de dubbelepunt het blokId</ListGroup.Item>
                        <ListGroup.Item>Student de juiste QR-code laten scannen</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "NETWORK_ERROR":
                return (
                    <ListGroup>
                        <ListGroup.Item>Moet niet voorkomen!</ListGroup.Item>
                        <ListGroup.Item>Neem direct contact op!</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "NO_JOINS":
                return (
                    <ListGroup>
                        <ListGroup.Item>Controleer de inschrijving</ListGroup.Item>
                        <ListGroup.Item>Neem contact op, als de student wel ingeschreven is</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "NO_PERMISSION_CAMERA":
                return (
                    <ListGroup>
                        <ListGroup.Item>Student moet nog toestemming verlenen voor de camera</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "NO_LOCATION_PERMISSION":
                return (
                    <ListGroup>
                        <ListGroup.Item>Student moet nog toestemming verlenen voor het ophalen van de locatiegegevens</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "MAX_SPORTVISITS_PERIOD":
                return (
                    <ListGroup>
                        <ListGroup.Item>Student heeft het maximaal aantal sportbezoeken voor een blok bereikt</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "NO_ACTIVE_PERIOD":
                return (
                    <ListGroup>
                        <ListGroup.Item>Student probeert gegevens op te halen van een blok, maar er is op dit moment geen blok actief</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "NOT_JOINED_THIS_PERIOD":
                return (
                    <ListGroup>
                        <ListGroup.Item>Student is in dit blok niet ingeschreven</ListGroup.Item>
                        <ListGroup.Item>Zorg dat de student is ingeschreven</ListGroup.Item>
                        <ListGroup.Item>Als de student wel ingeschreven is, neem dan contact op!</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "INFO":
                return (
                    <ListGroup>
                        <ListGroup.Item>Alleen informatie</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "CRITICAL":
                return (
                    <ListGroup>
                        <ListGroup.Item>NEEM DIRECT CONTACT OP!</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "MANUEL_CHECKOUT":
                return (
                    <ListGroup>
                        <ListGroup.Item>Kan niet op voorkomen</ListGroup.Item>
                        <ListGroup.Item>Komt alleen voor in de ontwikkelomgeving</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "ATTEMPT_CHECKOUT_BEFORE_TIME":
                return (
                    <ListGroup>
                        <ListGroup.Item>Informatieve melding, omdat de student op uitchecken klikt, terwijl de tijd nog niet voorbij is</ListGroup.Item>
                        <ListGroup.Item>Als een student veel op deze knop drukt, dan KAN er mogelijk sprake zijn dat de student fraudeert</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "CORRECT_LOCATION_CHECKIN":
                return (
                    <ListGroup>
                        <ListGroup.Item>Informatieve melding, omdat de student op de juiste locatie was</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "CORRECT_LOCATION_CHECKOUT":
                return (
                    <ListGroup>
                        <ListGroup.Item>Informatieve melding, omdat de student op de juiste locatie was</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "SYS_TIME":
                return (
                    <ListGroup>
                        <ListGroup.Item>Student heeft de tijd gewijzigd op het apparaat. Hierdoor loopt het niet meer synchroon met de server</ListGroup.Item>
                        <ListGroup.Item>Het sportbezoek is ongeldig verklaard</ListGroup.Item>
                    </ListGroup>
                )
                break;
            case "SYS_MAX_SPORTVISITS":
                return (
                    <ListGroup>
                        <ListGroup.Item>Student heeft het maximaal aantal sportbezoeken per blok overschreven</ListGroup.Item>
                        <ListGroup.Item>Het sportbezoek is ongeldig verklaard, omdat de server dit heeft gedecteerd.</ListGroup.Item>
                    </ListGroup>
                )
                break;
                
            default:
                return "Geen specifieke oplossingen"
            
        }
    }


    render() {
        var categoryFilter = textFilter({
            placeholder: "Zoek categorie"
        })

        const keywords = {
            'LOCATION_CHECKIN': 'Incheck, niet de juiste locatie',
            'LOCATION_CHECKOUT': 'Uitcheck, niet de juiste locatie',
            'CHECKIN_ERROR': 'Incheck probleem',
            'CHECKOUT_ERROR': 'Uitcheck probleem',
            'TIME_CHANGED': 'Tijdsverandering',
            'OVER_TIME': 'Nooit uitgecheckt',
            'MAX_SPORTVISIT_DAY': 'Maximaal sportbezoeken',
            'LOGIN_ERROR': 'Probleem met inloggen',
            'QR_CODE': 'Probleem met de QR-Code',
            'NETWORK_ERROR': 'Netwerk problemen',
            'NO_JOINS': 'Niet ingeschreven',
            'NO_PERMISSION_CAMERA': 'Geen camera toestemming',
            'NO_LOCATION_PERMISSION': 'Geen locatie toestemming',
            'MAX_SPORTVISITS_PERIOD': 'Maximaal sportbezoeken',
            'NO_ACTIVE_PERIOD': 'Geen actief blok beschikbaar',
            'NOT_JOINED_THIS_PERIOD': 'Niet ingeschreven voor dit blok',
            'INFO': 'Informatie',
            'CRITICAL': 'KRITIEK',
            'MANUEL_CHECKOUT': 'Handmatige uitcheck',
            'ATTEMPT_CHECKOUT_BEFORE_TIME': 'Op uitcheck knop gedrukt',
            'CORRECT_LOCATION_CHECKIN': 'Incheck locatie is correct',
            'CORRECT_LOCATION_CHECKOUT': 'Uitcheck locatie is correct',
            'SYS_TIME': 'Tijdsverandering volgens server',
            'SYS_MAX_SPORTVISITS' : 'Maximaal aantal sportbezoeken bereikt, volgens server'
        }

        const columns = [
            { 
                dataField: 'id', 
                text: 'id',
                hidden: true 
            },
            { 
                dataField: 'keyWord', 
                text: 'Categorie',
                formatter: (cell) => {
                    return this.findKeyWord(cell)
                },
                filter: selectFilter({
                    options: keywords
                  })
            },
            { 
                dataField: 'message', 
                text: 'Probleem beschrijving' 
            },
            { 
                dataField: 'isCheckIn', 
                text: 'Was de student ingecheckt?', 
                formatter: (cell) => {
                    if(cell) {
                        //true, than student was checkedInt
                        return "Ja"
                    } else { return "Nee"}
                } 
            },
            { 
                dataField: 'isOffline', 
                text: 'had de student netwerkverbinding?' , 
                formatter: (cell) => {
                    if(cell) {
                        //true, than student was offline
                        return "Geen internet"
                    } else { return "Wel internet"}
                } 
            },
            { 
                dataField: 'dateCreated', 
                text: 'Registratietijd server',
                sort: true,
                formatter: (cell) => {
                    let dateObj = cell;
                    if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                    }
                    return `${('0' + dateObj.getDate()).slice(-2)}-${('0' + (dateObj.getMonth() + 1)).slice(-2)}-${dateObj.getFullYear()}` + ` ${('0' + dateObj.getHours()).slice(-2)}:${('0' + (dateObj.getMinutes() + 1)).slice(-2)}:${('0' + (dateObj.getSeconds() + 1)).slice(-2)}`;
                } 
            },
            { 
                dataField: 'dateTimeDevice', 
                text: 'Registratietijd student',
                sort: true,
                formatter: (cell) => {
                    let dateObj = cell;
                    if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                    }
                    return `${('0' + dateObj.getDate()).slice(-2)}-${('0' + (dateObj.getMonth() + 1)).slice(-2)}-${dateObj.getFullYear()}` + ` ${('0' + dateObj.getHours()).slice(-2)}:${('0' + (dateObj.getMinutes() + 1)).slice(-2)}:${('0' + (dateObj.getSeconds() + 1)).slice(-2)}`;
                } 
            }, 
            {
                text: "Meer informatie",
                formatter: (cell, row) => {
                 return (<center><AiOutlineInfoCircle/></center>)
               },
               style: { cursor: "pointer"}
            }
        ]

        const expandRow = {
            renderer: (row) => (
                // <Alert  variant="primary">
                <div>
                    <p>Omschrijving: {row.message}</p>
                    <p>{ (row.isCheckIn) ? ("Student is ingecheckt toen de foutmelding werd gemaakt") : ("Student was NIET ingecheckt toen de foutmelding werd gemaakt")  }</p>
                    <p>{ (row.isOffline) ? ("Student had geen internet, dus gegevens worden pas later ingeladen") : ("Student had internet, dus alle gegevens zijn direct beschikbaar")}</p>
                    <p>Oplossingen:</p>
                    <p>{this.findSolutionByKeyword(row.keyWord)}</p>
                    {
                        (row.latitude != 0 && row.longitude != 0) ? (
                            <Maps center={[row.latitude, row.longitude]}/>
                        ) : ""
                    }
                    </div>
                // </Alert>
            ),
            onlyOneExpanding: true
          };

        const defaultSorted = [{
            dataField: 'dateCreated', // if dataField is not match to any column you defined, it will be ignored.
            order: 'desc' // desc or asc
          }];

        return (
            <div>
                <BootstrapTable bootstrap4 keyField='id' 
                    classes='table-hover'
                    data={ this.state.errorMessages } 
                    columns={ columns } 
                    defaultSorted={defaultSorted} 
                    pagination={ paginationFactory({sizePerPageList: [ {text: '10', value: 10}, {text: '50', value: 50}, {text: '100', value: 100}, {text: 'All', value: this.state.errorMessages.length} ],}) }
                    expandRow={ expandRow }
                    filter={ filterFactory() }
                    />
            </div>
        )
    }
}

export default ErrorMessagesStudent;