import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import PresentionsButtons from '../PresentionButtons'
import { MdDeleteForever } from 'react-icons/md';
import { BiSave } from 'react-icons/bi';
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import moment from 'moment'

class Sportdays extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sportdays: [],
            showAddSportdayModal: false,
            activeSportdays: [],
            selectedSportdayId: "",
            schoolYears: [],
            selectedSchoolYear: null
        }
    }

    componentDidMount() {
        this.getSportdays()
        this.getActiveSportday()
        this.getSchoolYears()
    }

    getSchoolYears() {
        fetch(process.env.REACT_APP_API_URL + "schoolyear",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                var first = data[0]
                this.setState({ schoolYears: data, selectedSchoolYear: first }, () => { console.log(this.state.selectedSchoolYear) })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }


    getActiveSportday() {
        fetch(process.env.REACT_APP_API_URL + "sportday",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({ activeSportdays: data })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    addSportday() {
        var sportday = this.state.activeSportdays.filter(x => x.id === this.state.selectedSportdayId)[0]


        if (sportday !== null || sportday !== "undefined") {

            var obj = {
                presentionType: "",
                sportday: sportday,
                student: { id: this.props.studentId }
            }

            var array = []
            array.push(obj)

            fetch(process.env.REACT_APP_API_URL + "sportday/students", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(array)
            }).then(data => {
                return data.json()
            }).then(result => {
                var array = this.state.sportdays
                array.push(result[0])

                this.setState({ sportdays: array })

                toastr.success('Toegevoegd', 'Je hebt een nieuwe sportdag toegewezen')

            }).catch((error) => {
                console.error(error);
            });

        }

    }

    handleShowAddJoinModal() {
        this.setState({ showAddSportdayModal: !this.state.showAddSportdayModal })
    }

    removeSportday(id) {
        fetch(process.env.REACT_APP_API_URL + "sportday/student/" + id,
            {
                method: 'DELETE',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                var array = this.state.sportdays
                array = array.filter(x => x.id !== id)
                this.setState({ sportdays: array })
                toastr.success('Verwijderd', 'Je hebt een sportdag verwijderd.')
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    getSportdays() {
        fetch(process.env.REACT_APP_API_URL + "sportday/" + this.props.studentId + "/student",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({ sportdays: data })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    handlerChangePresentionType = (id, type) => {
        console.log("Retrieve id: " + id + " changing to " + type)

        var joins = this.state.sportdays;
        var obj = joins.filter(x => x.id === id)[0]

        obj.presentionType = type;

        fetch(process.env.REACT_APP_API_URL + "sportday/" + id + "/join", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        }).then(data => {
            return data.json()
        }).then(result => {
            toastr.success('Gewijzigd', 'De presentie is verwerkt.')

        }).catch((error) => {
            console.error(error);
        });


    }

    onChangeHandlerAddSportday = e => {
        this.setState({ selectedSportdayId: e.target.value })
    }

    onChangeComment = e => {
        var id = e.target.id
        var val = e.target.value
        // console.log("Change comment of " + id + " with value " + val)
        var joins = this.state.sportdays
        var index = joins.findIndex(x => x.id === id)
        joins[index].comment = val
        this.setState({ sportdays: joins })
    }

    saveComment(id) {
        var joins = this.state.sportdays

        var join = joins.filter(x => x.id === id)[0]

        fetch(process.env.REACT_APP_API_URL + "sportday/" + id + "/join", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(join)
        }).then(data => {
            return data.json()
        }).then(result => {
            toastr.success('Gewijzigd', 'De opmerking is gewijzigd.')

        }).catch((error) => {
            console.error(error);
        });
    }

    handlerOnChangeSchoolYear = e => {
        var value = e.target.value
        var schoolYear = this.state.schoolYears.filter(x => x.id == value)[0]

        this.setState({ selectedSchoolYear: schoolYear })
    }

    render() {
        return (
            <Col>
                <Row>
                    <h5>Sportdagen</h5>
                    <Form.Group as={Col} controlId="formGridState" xs={2}>
                        <Form.Control as="select" defaultValue={this.state.selectedSchoolYear} size="sm" onChange={this.handlerOnChangeSchoolYear}>
                            {
                                this.state.schoolYears.map(item => {
                                    return (
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>Sportdag</th>
                                <th>Datum</th>
                                <th>Presentie</th>
                                <th>Opmerking</th>
                                <th>Verwijderen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.sportdays.map(join => {
                                    return (
                                        (this.state.selectedSchoolYear === null) ? "" : (
                                            (this.state.selectedSchoolYear.id !== join.schoolYear.id) ? "" : (
                                                <tr key={join.id}>
                                                    <td>{join.sportday.name}</td>
                                                    <td>{moment(join.sportday.date).format("DD-MM-YYYY")}</td>
                                                    <td>
                                                        <PresentionsButtons
                                                            id={join.id}
                                                            value={join.presentionType}
                                                            handlerChangePresentionType={this.handlerChangePresentionType} />
                                                    </td>
                                                    <td>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Prepend onClick={() => this.saveComment(join.id)}>
                                                                <InputGroup.Text id="basic-addon1"><BiSave /></InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <FormControl
                                                                id={join.id}
                                                                value={join.comment}
                                                                placeholder="Opmerking"
                                                                aria-describedby="basic-addon1"
                                                                onChange={this.onChangeComment}
                                                            />
                                                        </InputGroup>
                                                    </td>
                                                    <td><MdDeleteForever onClick={() => this.removeSportday(join.id)} /></td>
                                                </tr>))
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Row>
                <Row>
                    <Button onClick={() => this.handleShowAddJoinModal()}>+</Button>
                </Row>


                <Modal show={this.state.showAddSportdayModal} onHide={false}>

                    <Modal.Header closeButton>
                        <Modal.Title>Voeg sportdag toe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="formGridState">
                            <Form.Control as="select" onChange={this.onChangeHandlerAddSportday}>
                                <option>Selecteer</option>
                                {
                                    this.state.activeSportdays.map(sportday => {
                                        return (
                                            <option value={sportday.id}>{sportday.name} | {moment(sportday.date).format("DD-MM-YYYY")}</option>
                                        )
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleShowAddJoinModal()}>
                            Sluiten
                            </Button>
                        <Button onClick={() => this.addSportday()}>
                            Opslaan
                            </Button>
                    </Modal.Footer>
                </Modal>
            </Col>
        )
    }
}

export default Sportdays;