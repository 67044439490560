import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Grid } from 'react-loading-icons'

class LoadSymbol extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }
    render() {
        return (
            <Row className="justify-content-md-center">
            <Col xs lg="2">
            </Col>
            <Col md="auto">
                            <Grid fill="#0a63a7"/></Col>
            <Col xs lg="2">
            </Col>
            </Row>
        )
    }
}

export default LoadSymbol;