import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { toastr } from 'react-redux-toastr'
import moment from 'moment'
import DatePicker from '../../components/DatePicker'

class StartSchoolyear extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startYear: "",
            endYear: "",
            schoolyear: {
                name: "",
                startDate: "",
                endDate: ""
            },
            isCreatePeriod: false,
            periods: [{
                counter: 0,
                name: "",
                startDate: "",
                endDate: ""
            }],
            periodCounter: 1,
            isComplete: false
        }
    }
    handleYearChange = event => {
        let nam = event.target.name;
        let val = event.target.value;

        this.setState({ [nam]: val });
    }

    handleAddSchoolyearChange = event => {
        const schoolyear = { ...this.state.schoolyear };

        let nam = event.target.name;
        let val = event.target.value;

        schoolyear[nam] = val

        this.setState({ schoolyear: schoolyear });
    }

    mySubmitHandler = (event) => {
        event.preventDefault();
        let startYear = this.state.startYear
        let endYear = this.state.endYear

        const schoolyear = { ...this.state.schoolyear };
        schoolyear["name"] = startYear + "-" + endYear
        this.setState({ schoolyear: schoolyear, isCreatePeriod: true })
    }

    addPeriod = event => {
        let array = this.state.periods

        array.push({
            counter: this.state.periodCounter,
            name: "",
            startDate: "",
            endDate: ""
        })

        this.setState({ periods: array, periodCounter: (this.state.periodCounter + 1) });
    }

    removePeriod(index) {
        let newArray = this.state.periods.filter(item => item.counter !== index)
        console.log(newArray)
        this.setState({ periods: newArray });
    }

    onChangePeriodStartDate = (date, i) => {
        console.log("Index: " + i + " for startdate" + " with value: " + date)
        let periods = [...this.state.periods];

        let nam = "startDate";
        let val = date;

        periods[i][nam] = val;
        this.setState({ periods }, () => console.log(this.state.periods));
    }

    onChangePeriodEndDate = (date, i) => {
        console.log("Index: " + i + " for endDate" + " with value: " + date)
        let periods = [...this.state.periods];

        let nam = "endDate";
        let val = date;

        periods[i][nam] = val;
        this.setState({ periods }, () => console.log(this.state.periods));
    }

    handlePeriodChange(i, event) {
        console.log(event)
        let periods = [...this.state.periods];

        let nam = event.target.name;
        let val = event.target.value;

        periods[i][nam] = val;
        this.setState({ periods }, () => console.log(this.state.periods));
    }

    periodSubmitHandler = (event) => {
        event.preventDefault();
        this.setState({ isComplete: true })
    }

    createSchoolyear = e => {
        var schoolyear = { ...this.state.schoolyear };
        console.log(schoolyear);

        schoolyear.startDate = moment(schoolyear.startDate, ["MM-DD-YYYY", "DD-MM-YYYY"]).format("MM-DD-YYYY")
        schoolyear.endDate = moment(schoolyear.endDate, ["MM-DD-YYYY", "DD-MM-YYYY"]).format("MM-DD-YYYY")
        console.log(schoolyear);

        fetch(process.env.REACT_APP_API_URL + "schoolyear", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(schoolyear)
        }).then(response => {
            if (response.ok) return response.json();

            return response.text().then(response => { throw new Error(response) })
        })
        .then(schoolyear => {
            console.log(schoolyear)
            
            let array = this.state.periods

            array.forEach(item => {
                item["schoolyear"] = schoolyear
                item.startDate = item.startDate
                item.endDate = item.endDate
            })
            this.setState({periods: array}, () => {
                this.createPeriods()
            })


        })
        .catch(error => {
            toastr.error('Klas toevoegen', error.message)
        console.log(error)
        });
    }

    createPeriods(){
        fetch(process.env.REACT_APP_API_URL + "period", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.periods)
        }).then(data => {
            return data.json()
        })
        .then(periods => {
            console.log(periods)
            toastr.success('Schooljaar gestart', 'Je hebt het nieuwe schooljaar gestart')

            location.href = '/Dashboard'
        })
        .catch(error => {
            console.log(error)
        });
    }

    render() {
        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Schooljaar starten</h1>
                    {
                        !this.state.isCreatePeriod ? (
                            <div>
                                <h4>In dit scherm kun je een nieuw schooljaar opstarten.</h4>
                                <Row>
                                    <Col></Col>
                                    <Col xs={7}>
                                        <Form className="ml-5 " onSubmit={this.mySubmitHandler}>
                                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                                <Form.Label column sm={2}>
                                                    Schooljaar
                            </Form.Label>
                                                <Col>
                                                    <Form.Control type="number" placeholder="2021" min="2019" name="startYear" onChange={this.handleYearChange} required />
                                                </Col>
                                                -
                                    <Col>
                                                    <Form.Control type="number" placeholder="2022" min="2019" name="endYear" onChange={this.handleYearChange} required />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="handleAddSchoolyearChange">
                                                <Form.Label column sm={2}>
                                                    Start datum
                                    </Form.Label>
                                                <Col>
                                                    <Form.Control type="date" placeholder="dd-mm-yyyy" name="startDate" onChange={this.handleAddSchoolyearChange} required />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="handleAddSchoolyearChange">
                                                <Form.Label column sm={2}>
                                                    Eind datum
                                    </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control type="date" placeholder="dd-mm-yyyy" name="endDate" onChange={this.handleAddSchoolyearChange} required />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Col>
                                                    <Button type="submit" className="w-100">Schooljaar opslaan</Button>
                                                </Col>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </div>
                        ) : (
                                !this.state.isComplete ? (
                                    <Form onSubmit={this.periodSubmitHandler}>
                                        <h4>In dit scherm kun je blokken aanmaken.</h4>
                                        <Row>
                                            <Col></Col>
                                            <Col xs={7}>
                                                {
                                                    this.state.periods.map((val, i) => {
                                                        return (
                                                            <Form.Row>
                                                                <Col>
                                                                    <Form.Label>Naam blok</Form.Label>
                                                                    <Form.Control type="text" placeholder="Periode" name="name" value={val.name} onChange={this.handlePeriodChange.bind(this, i)} required />
                                                                </Col>
                                                                <Col>
                                                                    <Form.Label>Start datum</Form.Label>
                                                                    <DatePicker 
                                                                        placeholder="dd-mm-yyyy"
                                                                        dateFormat="dd-MM-yyyy"
                                                                        onChange={this.onChangePeriodStartDate} 
                                                                        name="startDate"
                                                                        showTime={false}
                                                                        index={i}/>
                                                                    {/* <Form.Control type="date" placeholder="dd-mm-yyyy" name="startDate" value={val.startDate} onChange={this.handlePeriodChange.bind(this, i)} required /> */}
                                                                </Col>
                                                                <Col>
                                                                    <Form.Label>Eind datum</Form.Label>
                                                                    <DatePicker 
                                                                        placeholder="dd-mm-yyyy"
                                                                        dateFormat="dd-MM-yyyy"
                                                                        onChange={this.onChangePeriodEndDate} 
                                                                        name="startDate"
                                                                        showTime={false}
                                                                        index={i}/>
                                                                    {/* <Form.Control type="date" placeholder="dd-mm-yyyy" name="endDate" value={val.endDate} onChange={this.handlePeriodChange.bind(this, i)} required /> */}
                                                                </Col>
                                                                <Col>
                                                                    <Button className='mt-3' onClick={() => this.removePeriod(val.counter)} variant="danger">-</Button>
                                                                </Col>
                                                            </Form.Row>
                                                        )
                                                    })
                                                }

                                                <Button className='mt-3' onClick={this.addPeriod}>+</Button>

                                                <Row>
                                                    <Button type="submit" className="w-100 mt-5 h-25">Opslaan</Button>
                                                </Row>
                                            </Col>
                                            <Col></Col>
                                        </Row>
                                    </Form>
                                ) : (
                                        <div>
                                            <h4>Je hebt het einde van deze wizard bereikt.<br />
                                                Je kunt nu het schooljaar starten en de klassen toevoegen.<br />
                                                Heel veel succes dit jaar!!!<br />
                                                <Button className="w-50 mt-5" size="lg" onClick={this.createSchoolyear}>Schooljaar {this.state.schoolyear.name} starten</Button>
                                            </h4>
                                        </div>
                                    )
                            )
                    }

                </Col>
            </Row>
        </Container>
    }
}

export default StartSchoolyear;