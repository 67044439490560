import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import { toastr } from 'react-redux-toastr'

import Button from 'react-bootstrap/Button'

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { BsPencil } from 'react-icons/bs';
import { GrOverview } from 'react-icons/gr';
import { MdDeleteForever } from 'react-icons/md';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import AddClinic from '../../components/AddClinic'
import UpdateClinic from '../../components/UpdateClinic'
import LoadSymbol from '../../components/LoadSymbol';

class Clinic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clinics: [],
            showHideAddModal: false,
            showHideUpdateModal: false,
            addAlertIsHidden: true,
            comments: [],
            commentIndex: 0,
            clinic: {
                id: '',
                name: "",
                street: "",
                number: "",
                zipCode: "",
                place: "",
                minSportTime: 60,
                isVisble: null,
                isFullYear: null,
                contactPersonMail: "",
                contactPersonName: "",
                latitude: 0.0,
                longitude: 0.0,
                employee: {
                    id: "",
                    fullName: "",
                    loginName: "",
                    isSuperUser: ""
                },
                comments: [],
                employeeId: "select"
            },
            employees: [],
            addClinicValidation: false,
            updateClinicValidation: false,
            checkedPrints: [],
            periods: [],
            selectedPeriod: null,
            showDeleteClinicModal: false,
            selectedClinicToDelete: {}
        };
    }

    componentWillMount() {
        this.renderMyData();
        this.getActivePeriods();
    }

    handleAddModalShowHide() {
        this.setState({ showHideAddModal: !this.state.showHideAddModal })
    }

    handleUpdateModalShowHide() {
        this.setState({ showHideUpdateModal: !this.state.showHideUpdateModal })
    }

    handleDeleteModalShowHide() {
        this.setState({ showDeleteClinicModal: !this.state.showDeleteClinicModal })
    }

    renderMyData() {
        fetch(process.env.REACT_APP_API_URL + "clinic/v1/all",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                },
            })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                this.setState({ clinics: responseJson })
            })
            .catch((error) => {
                console.error(error);
                localStorage.clear();
                location.href = '/'
            });
    }

    resetForms() {
        this.setState({
            addClinicValidation: false,
            updateClinicValidation: false
        })

        this.setState({
            clinic: {
                id: '',
                name: "",
                street: "",
                number: "",
                zipCode: "",
                place: "",
                minSportTime: 60,
                isVisble: null,
                isFullYear: null,
                IsOnlyGPS: false,
                contactPersonMail: "",
                contactPersonName: "",
                latitude: 0.0,
                longitude: 0.0,
                employee: {
                    id: "select",
                    fullName: "",
                    loginName: "",
                    isSuperUser: ""
                },
                comments: [],
                employeeId: "select"
            }
        })
    }

    onChangePeriodSelect = e => {
        var id = e.target.value
        var periods = this.state.periods

        var idx = periods.findIndex(x => x.id == id)

        var period = periods[idx]
        this.setState({ selectedPeriod: period })
    }

    onClickPrintLists() {
        var array = []
        var clinics = this.state.clinics
        var period = this.state.selectedPeriod
        if (period == null) { return; }

        clinics.forEach(x => {
            if (x.checked) {
                array.push(x)
            }
        })


        var obj = {
            clinics: array,
            period: period
        }

        console.log(obj)

        fetch(process.env.REACT_APP_API_URL + "clinic/print",
            {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj)
            }).then(response => response.json())
            .then((responseJson) => {
                var str = responseJson

                let buff = new Buffer(str, 'base64');
                let base64ToStringNew = buff.toString();
                console.log(base64ToStringNew)

                var winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
                winPrint.document.write(base64ToStringNew);
                winPrint.document.close();
            })
            .catch(error => {
                console.error(error);
                // localStorage.clear();
                // location.href = '/'
            });


    }

    onChangeSelect = e => {
        let id = e.target.id
        let checked = e.target.checked

        var array = this.state.clinics

        var idx = array.findIndex(x => x.id === id)

        array[idx].checked = checked

        this.setState({ clinics: array })
    }

    onChangeSelectAll() {
        var array = this.state.clinics
        var i;
        for(i=0; i < array.length; i++){
            if(array[i].checked) {
                array[i].checked = false
            } else {
                array[i].checked = true
            }
        }

        this.setState({ clinics: array })
    }

    getActivePeriods() {
        fetch(process.env.REACT_APP_API_URL + "period/active",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => this.setState({ periods: data }))
            .catch(error => {
                localStorage.clear();
                location.href = '/'
            });
    }

    addClinicCallBack = (result) => {
        var newArray = this.state.clinics.slice();
        newArray.push(result);
        this.handleAddModalShowHide()
        this.setState({ clinics: newArray })
    }

    updateClinicCallback = (result) => {
        const index = this.state.clinics.findIndex(element => element.id == result.id)
        var newArray = this.state.clinics.slice();
        newArray[index] = result;
        this.setState({ clinics: newArray })

        this.handleUpdateModalShowHide()

        toastr.success('Sportkeuze gewijzigd', 'Je hebt een sportkeuze gewijzigd!')
    }

    deleteClinic() {
        var id = this.state.selectedClinicToDelete.id
        fetch(process.env.REACT_APP_API_URL + "clinic/v1/" + id, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            }
        }).then(data => {
            return data.json()
        }).then(result => {
            if(result) {

            const index = this.state.clinics.findIndex(element => element.id == id)
            this.state.clinics.splice(index, 1);
            this.setState({ clinics: this.state.clinics, selectedClinicToDelete: {} }, () => this.handleDeleteModalShowHide())


            toastr.success('Sportkeuze verwijderd', 'Je hebt een sportkeuze verwijderd!')
        } else {
            toastr.warning('Clinic', 'De sportkeuze is niet verwijderd!')
        }

        }).catch((error) => {
            console.error(error);
        });
    }

    render() {

        const columns = [{
            dataField: 'id',
            text: 'clinicId',
            sort: false,
            hidden: true
        }, {
            dataField: 'name',
            text: 'Naam',
            sort: true,
            filter: textFilter({
                placeholder: 'Zoek name'
            })
        }, {
            dataField: 'employee.fullName',
            text: 'Contactpersoon Sportbureau',
            sort: true,
            filter: textFilter({
                placeholder: 'Zoek persoon'
            })
        }, {
            dataField: 'change',
            text: 'Wijzig',
            formatter: (cell, row) => <BsPencil />,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    console.log(row)
                    row.employeeId = row.employee.id
                    this.setState({ clinic: row, comments: row.comments }, function () {
                        this.handleUpdateModalShowHide()
                    });
                },
            }
        }, {
            dataField: 'remove',
            text: 'Verwijderen',
            formatter: (cell, row) => <MdDeleteForever />,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    console.log(row)
                    
                    this.setState({selectedClinicToDelete: row}, () => this.handleDeleteModalShowHide())
                },
            }
        }, {
            dataField: 'overView',
            text: 'Overzicht',
            formatter: (cell, row) =><GrOverview />,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    console.log(row)

                    window.open("/clinicPresention/" + encodeURIComponent(row.name) + "/" + row.id) 
                },
            }
        }, {
            dataField: 'select',
            text: 'Selecteer alles',
            formatter: (cell, row) => <Form.Check type="checkbox" onChange={this.onChangeSelect} id={row.id} checked={row.checked}/>,
            headerEvents: {
                onClick: (e, column, columnIndex) => { 
                    this.onChangeSelectAll()
                }
              },
              events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    console.log(row)
                },
            }
        }];

        const defaultSorted = [{
            dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
            order: 'asc' // desc or asc
        }];

        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Sportkeuze</h1>
                    <Row>
                        <Col>
                            <Button onClick={() => this.handleAddModalShowHide()}>Voeg een sportkeuze toe</Button>
                            <Form inline className="float-right">
                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                    Blok:
                            </Form.Label>
                                <Form.Control
                                    onChange={this.onChangePeriodSelect}
                                    as="select"
                                    className="my-1 mr-sm-2"
                                    id="inlineFormCustomSelectPref"
                                    custom
                                >
                                    <option value="select">Selecteer</option>
                                    {
                                        this.state.periods.map(period => {
                                            return (
                                                <option value={period.id}>{period.name}</option>
                                            )
                                        })
                                    }
                                </Form.Control>
                                <Button className="" onClick={() => this.onClickPrintLists()}>Afdrukken</Button>
                            </Form>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {
                                this.state.clinics.length > 0 ? (
                                <BootstrapTable
                                bootstrap4
                                keyField='id'
                                bordered={false}
                                data={this.state.clinics}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                filter={filterFactory()} />
                                ) : (<LoadSymbol/>)
                            }

                            
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Modal show={this.state.showHideAddModal} size="xl">
                <Modal.Header closeButton onClick={() => this.handleAddModalShowHide()}>
                    <Modal.Title>Sportkeuze toevoegen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddClinic addClinicCallBack={this.addClinicCallBack} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.handleAddModalShowHide()}>
                        Sluit
                    </Button>
                    {/* <Button variant="primary" onClick={this.addClinic}>
                        Toevoegen
                    </Button> */}
                </Modal.Footer>
            </Modal>

            {/* 
            Update Model 
            */}
            <Modal show={this.state.showHideUpdateModal} size="xl">
                <Modal.Header closeButton onClick={() => this.handleUpdateModalShowHide()}>
                    <Modal.Title>Sportkeuze Wijzigen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UpdateClinic clinic={this.state.clinic} updateClinicCallback={this.updateClinicCallback} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.handleUpdateModalShowHide()}>
                        Sluit
                    </Button>
                    {/* <Button variant="primary" onClick={this.updateClinic} >
                        Wijzig
                    </Button> */}
                </Modal.Footer>
            </Modal>

{/*
Modal for showing check message to remove clinic
*/}
            <Modal show={this.state.showDeleteClinicModal} onHide={false}>

                    <Modal.Header closeButton>
                        <Modal.Title>Sportkeuze verwijderen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Weet je het zeker dat deze sportkeuze wilt verwijderen?<br />
                        Wanneer er sportbezoeken zijn geregisteerd, kan de sportkeuze niet worden verwijderd.<br />
                        Deze mutatie kan niet meer ongedaan worden gemaakt!
                        </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleDeleteModalShowHide()}>
                            Sluiten
                            </Button>
                        <Button variant="danger" onClick={() => this.deleteClinic()}>
                            Verwijderen
                            </Button>
                    </Modal.Footer>
                </Modal>
        </Container>
    }
}

export default Clinic