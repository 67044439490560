import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr'
//https://www.freecodecamp.org/news/how-to-add-drag-and-drop-in-react-with-react-beautiful-dnd/
import { DragDropContext, Droppable, Draggable, resetServerContext } from 'react-beautiful-dnd';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { MdDeleteForever } from 'react-icons/md';
import Button from 'react-bootstrap/Button'
import { BiSave } from 'react-icons/bi';
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Form from 'react-bootstrap/Form'

class ClassContentPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content : [...this.props.class.classContentPages].sort((a,b) => a.orderNumber > b.orderNumber ? 1 : -1),
            showHide: false,
            title: "",
            formData: null
        }
    }

    handleModalShowHide() {
        this.setState({ showHide: !this.state.showHide })
    }


    onChangeTitle = e => {
        var nam = e.target.name
        var val = e.target.value

        this.setState({ [nam]: val });

    }

    OnchangeUpdateTitle = e => {
        var id = e.target.id
        var val = e.target.value

        var array = this.state.content
        var idx = array.findIndex(x => x.id === id)

        array[idx].title = val;

        this.setState({ content: array })
    }

    onChangeFile = e => {
        var nam = e.target.name
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file)

        this.setState({ [nam]: formData })
    }

    handleOnDragEnd = result => {
        if (!result.destination) return;
        console.log(result)
        var array = this.state.content
        var id = result.draggableId
        var item = array.filter(x => x.id === id)

        var reOderItem = array.splice(result.source.index, 1)

        console.log(reOderItem[0])
        reOderItem[0].orderNumber = result.destination.index

        //Update
        fetch(process.env.REACT_APP_API_URL + "class/content/" + id, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(reOderItem[0])
        }).then(data => {
            return data.json()
        }).then(res => {
            array.splice(result.destination.index, 0, reOderItem[0])

            this.saveChanges(array)
            resetServerContext()

            toastr.success('Geupdate', 'Je hebt een content pagina geupdate')

        }).catch((error) => {
            console.error(error);
        });
    }

    saveChanges(array) {

        array.map((content, idx) => {
            content.orderNumber = idx
        })

        this.setState({ content: array });
    }

    addContent() {
        var array = this.state.content

        var formData = this.state.formData
        formData.append("title", this.state.title)
        formData.append("orderNumber", array.length)

        fetch(process.env.REACT_APP_API_URL + "class/" + this.props.class.id + "/content", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                "role": "RW1wbG95ZWU="
            },
            body: formData
        }).then(data => {
            return data.json()
        })
            .then(result => {
                array.push(result)
                this.saveChanges(array)
                toastr.success('Content toegevoegd', 'Je hebt een nieuw content toegevoegd!')
            });
    }

    removeContent(id) {
        fetch(process.env.REACT_APP_API_URL + "class/content/" + id,
            {
                method: 'DELETE',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token'),
                    "role": "RW1wbG95ZWU="
                }
            }).then(response => response.json())
            .then(data => {
                if (data) {
                    var array = this.state.content.filter(x => x.id !== id)
                    array = array.filter(x => x.id !== id)
                    this.saveChanges(array)

                    toastr.success('Content verwijderd', 'Je hebt een content verwijderd!')
                } else {
                    toastr.danger('Oeps!?', 'Er is iets misgegaan.')
                }
            })
            .catch(error => {
                localStorage.clear();
                location.href = '/'
            });
    }

    onClickUpdateTitle(id) {
        var array = this.state.content
        var obj = array.filter(x => x.id === id)[0]
        console.log(obj)
        fetch(process.env.REACT_APP_API_URL + "class/content/" + id, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        }).then(data => {
            return data.json()
        }).then(result => {

            toastr.success('Geupdate', 'Je hebt een content pagina geupdate')

        }).catch((error) => {
            console.error(error);
        });
    }


    render() {
        const mystyle = {
            // backgroundColor: "lightgrey",
            padding: "10px",
            border: "1px solid gray",
            borderRadius: "10px"
        };

        const btnStyle = {
            cursor: "pointer"
        }
        return (
            <div>
                <Button onClick={() => this.handleModalShowHide()}>+</Button>
            <DragDropContext droppableId="characters" onDragEnd={this.handleOnDragEnd}>
                <Droppable droppableId="characters">
                    {(provided) => (
                        <ul style={{ "list-style-type": "none", "padding-left": "0px" }} className="characters"  {...provided.droppableProps} ref={provided.innerRef}>
                            {this.state.content.map((content, index) => {
                                return (
                                    <Draggable key={content.id} draggableId={content.id} index={index}>
                                        {(provided) => (
                                            <li style={{ "margin-left": "0px" }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <Row style={mystyle} className="m-2">
                                                    <Col>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Prepend style={btnStyle} onClick={() => this.onClickUpdateTitle(content.id)}>
                                                                <InputGroup.Text id="basic-addon1"><BiSave /></InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <FormControl
                                                                id={content.id}
                                                                value={content.title}
                                                                aria-describedby="basic-addon1"
                                                                onChange={this.OnchangeUpdateTitle}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col>
                                                        <MdDeleteForever className="float-right mt-3" style={btnStyle}  onClick={() => this.removeContent(content.id)} />
                                                    </Col>
                                                </Row>
                                            </li>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </ul>
                    )}

                </Droppable>
            </DragDropContext>

            <Modal show={this.state.showHide} >
                <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                    <Modal.Title>Content toevoegen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formLoginName">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Jaarplanning" name="title" onChange={this.onChangeTitle} />
                        </Form.Group>

                        <Form.Group controlId="formfullName">
                            <Form.Label>Bestand toevoegen</Form.Label>
                            <Form.Control type="file" name="formData" onChange={this.onChangeFile} accept="application/pdf"/>
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.handleModalShowHide()}>
                        Sluit
                    </Button>
                    <Button variant="primary" onClick={() => this.addContent()}>
                        Opslaan
                    </Button>
                </Modal.Footer>
            </Modal>
            </div>
        )
    }
}

export default ClassContentPage;