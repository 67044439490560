import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { BiEdit, BiSave } from 'react-icons/bi'
import { RiDeleteBin5Line } from 'react-icons/ri'

import JsCookie from 'js-cookie'

class Comments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            comments: [],
            commentPointer: 0
        }
    }

    componentDidMount() {
        this.getComments()
    }

    onChangeText = idx => (e) => {
        var comments = this.state.comments
        var value = e.target.value

        comments[idx].text = value


        console.log(comments)

        this.setState({comments: comments})
    }

    getComments() {
        fetch(process.env.REACT_APP_API_URL + "studentcomment/student/" + this.props.studentId, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            }
        }).then(data => {
            return data.json()
        }).then(result => {
            console.log(result)
            this.setState({ comments: result.data})
        }).catch((error) => {
            console.error(error);
        });
    }

    onClickEdit(idx) { 
        var comments = this.state.comments

        comments[idx].isEdit = true

        this.setState({comments: comments})

    }

    addNewComment() {
        var pointer = this.state.commentPointer
        var comments = this.state.comments

        var obj = {
            id: pointer,
            text: "",
            isEdit: true,
            updateDateTime: new Date(),
            employee: {
                id: JsCookie.get('guid'),
                fullName: JsCookie.get('name')
            }
        }

        comments.push(obj)

        this.setState({ comments: comments, commentPointer: (pointer + 1)})

    }

    saveComment(idx) {
        var comments = this.state.comments

        comments[idx].isEdit = false

        if(Number.isInteger(+comments[idx].id)){
            //Its not a guid, do add
            this.addComment(comments[idx])
        } else {
            //Its a guid, do update
            this.updateComment(comments[idx])
            
        }

        this.setState({comments: comments})
    }

    addComment(comment) {
        delete comment.id
        fetch(process.env.REACT_APP_API_URL + "studentcomment/student/" + this.props.studentId, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(comment)
        }).then(data => {
            return data.json()
        }).then(result => {
            console.log(result)
            toastr.success('Verwerkt', 'Opmerking is toegevoegd.')
            this.getComments()
        }).catch((error) => {
            console.error(error);
        });
    }

    updateComment(comment) {
        fetch(process.env.REACT_APP_API_URL + "studentcomment/" + comment.id, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(comment)
        }).then(data => {
            return data.json()
        }).then(result => {
            console.log(result)
            toastr.success('Verwerkt', 'Opmerking is gewijzigd')
            this.getComments()
        }).catch((error) => {
            console.error(error);
        });
    }

    deleteComment(idx) {
        var comments = this.state.comments

        fetch(process.env.REACT_APP_API_URL + "studentcomment/" + comments[idx].id, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
        }).then(data => {
            return data.json()
        }).then(result => {
            console.log(result)
            toastr.success('Verwerkt', 'Opmerking is verwijderd')
            this.getComments()
        }).catch((error) => {
            console.error(error);
        });
    }
    render() {
        return (
            <Col>
                <Row>
                    <h4>Opmerkingen</h4>
                </Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>Opmerking</th>
                                <th>Medewerker</th>
                                <th>Bewerkingsdatum</th>
                                <th>Bewerken</th>
                                <th>Verwijderen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.comments.map((comment, idx) => {
                                    return (<tr>
                                        <td>
                                            {
                                                !comment.isEdit ? (
                                                    <Form.Control as="textarea" placeholder="Opmerking" value={comment.text} readOnly/>
                                                ) : (
                                                    <Form.Control as="textarea" placeholder="Opmerking" value={comment.text} onChange={this.onChangeText(idx)}/>
                                                )
                                            }
                                        </td>
                                        <td>{comment.employee?.fullName}</td>
                                        <td>{new Date(comment.updateDateTime).toLocaleString()}</td>
                                        <td>
                                            {
                                                !comment.isEdit ? (<BiEdit size={28} style={{cursor:'pointer'}} onClick={() => this.onClickEdit(idx)} />) : (<BiSave size={28} style={{cursor:'pointer'}} onClick={() => this.saveComment(idx)}/>)
                                            }
                                            
                                            
                                        </td>

                                <td>
                                    <RiDeleteBin5Line size={28} style={{cursor:'pointer'}} onClick={() => this.deleteComment(idx)}/>
                                    </td>
                                    </tr>)
                                })
                            }
                        </tbody>
                    </Table>

                <Row>
                    <Button className='mb-2' onClick={() => this.addNewComment()}>+</Button>
                </Row>
            </Col>
        )
    }
}

export default Comments;

// {
//     "code": 0,
//     "status": true,
//     "message": null,
//     "data": [
//         {
//             "id": "f36a2086-912b-401f-08e3-08d9a7b783fa",
//             "text": "Hier komt het commentaar",
//             "updateDateTime": "0001-01-01T00:00:00",
//             "createDateTime": "0001-01-01T00:00:00",
//             "student": {
//                 "id": "4953d447-2cf4-4082-d517-08d94ae96788",
//                 "studentId": "viggo7",
//                 "firstName": "Rik",
//                 "insert": " ",
//                 "lastName": "astudent4",
//                 "address": "Ergens 1",
//                 "zipCode": "7462 VG",
//                 "place": "RIJSSEN",
//                 "schoolMail": "Rik--astudent4@roc.nl",
//                 "mobileNumber": null,
//                 "phoneNumber": "10001",
//                 "photoUrl": null,
//                 "createDate": "2021-05-07T12:41:36.457306",
//                 "updateDate": "2021-05-07T12:41:36.495714",
//                 "active": true,
//                 "classes": []
//             },
//             "employee": {
//                 "id": "cc19ad10-1159-4490-bf90-094e18c8c6a7",
//                 "loginName": "belfort",
//                 "fullName": "Rik",
//                 "isSuperUser": true,
//                 "updateDate": "2021-07-11T00:00:00",
//                 "createDate": "2021-07-11T00:00:00",
//                 "clinics": null
//             }
//         }
//     ]
// }