import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

class RegistrationsDevice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            registrations: []
        }
    }

    componentWillMount() {
        this.getData()
    }

    getData() {
        fetch(process.env.REACT_APP_API_URL + "registration/" + this.props.studentId,
        {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token')
            }
        }).then(response => response.json())
        .then(data => {
            console.log(data)
            this.setState({ registrations: data })
        })
        .catch(error => {
            console.log(error)
            // localStorage.clear();
            // location.href = '/'
        });
    }

    render() {
        const columns = [
            { dataField: 'registrationDateTimeServer', text: 'Registratietijd server',
            sort: true,
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return `${('0' + dateObj.getDate()).slice(-2)}-${('0' + (dateObj.getMonth() + 1)).slice(-2)}-${dateObj.getFullYear()}` + ` ${('0' + dateObj.getHours()).slice(-2)}:${('0' + (dateObj.getMinutes() + 1)).slice(-2)}:${('0' + (dateObj.getSeconds() + 1)).slice(-2)}`;
            } },
            { dataField: 'registrationDateTime', text: 'Registratietijd student',
            sort: true,
            formatter: (cell) => {
                let dateObj = cell;
                if (typeof cell !== 'object') {
                    dateObj = new Date(cell);
                }
                return `${('0' + dateObj.getDate()).slice(-2)}-${('0' + (dateObj.getMonth() + 1)).slice(-2)}-${dateObj.getFullYear()}` + ` ${('0' + dateObj.getHours()).slice(-2)}:${('0' + (dateObj.getMinutes() + 1)).slice(-2)}:${('0' + (dateObj.getSeconds() + 1)).slice(-2)}`;
            } },
            { dataField: 'model', text: 'Soort mobiel' },
            { dataField: 'softwareVersion', text: 'Versie van software' },
            { dataField: 'appVersion', text: 'Versie van de SoM app' },
          ]

        const defaultSorted = [{
            dataField: 'registrationDateTimeServer', // if dataField is not match to any column you defined, it will be ignored.
            order: 'desc' // desc or asc
          }];
        return (
            <div>
                <BootstrapTable bootstrap4 keyField='id' data={ this.state.registrations } columns={ columns } defaultSorted={defaultSorted} pagination={ paginationFactory({sizePerPageList: [ {text: '1', value: 1}, {text: '5', value: 5}, {text: '10', value: 10}, {text: 'All', value: this.state.registrations.length} ],}) }/>
            </div>
        )
    }
}

export default RegistrationsDevice;