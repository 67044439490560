import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import { toastr } from 'react-redux-toastr'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Table from 'react-bootstrap/Table'

class StudentImages extends Component {

    constructor(props) {
        super(props);

        this.state = {
            students: [],
            fileLabel: "Voeg je document toe"
        }
    }
    searchHandler = e => {
        const value = e.target.value;

        if (value.length >= 3) {
            this.searchStudent(value)
        }
    }

    searchStudent(keyword) {
        fetch(process.env.REACT_APP_API_URL + "student/" + keyword + "/search",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ students: data })
            })
            .catch(error => {
                console.log(error)
            });
    }

    onChangeImage = e => {
        var id = e.target.id
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file)

        fetch(process.env.REACT_APP_API_URL + "student/" + id + "/image", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token')
            },
            body: formData
        }).then(data => {
            return data.arrayBuffer()
        })
            .then(result => {
                toastr.success('Afbeelding gewijzigd', 'Je hebt de afbeelding gewijzigd.')
            });
    }


    render() {
        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Studenten afbeeldingen</h1>
                    <Row>
                        <Col>
                            <InputGroup size="sm" className="mt-2">
                                <FormControl
                                    placeholder="Zoek student naam, klas"
                                    aria-label="text"
                                    aria-describedby="basic-addon2"
                                    onChange={this.searchHandler}
                                />
                                <InputGroup.Append style={{ backgroundColor: '#0064AD', }} >
                                    <InputGroup.Text id="basic-addon2" style={{ backgroundColor: '#0064AD', borderColor: '#0064AD', color: '#fff' }}>Zoek</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Studentnummer</th>
                                        <th>Studentnaam</th>
                                        <th>Klas</th>
                                        <th>Upload</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.students.map(student => {
                                            return (
                                                <tr key={student.id}>
                                                    <td>{student.studentId}</td>
                                                    <td>{student.firstName} {student.insert} {student.lastName}</td>
                                                    <td>{student.classes[0] != null ? (student.classes[0].class.name) : "Niet bekend"}</td>
                                                    <td>
                                                        <input type="file" id={student.id} accept="image/*" onChange={this.onChangeImage} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    }
}

export default StudentImages;