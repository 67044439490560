import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import PresentionsButtons from '../PresentionButtons'
import Button from 'react-bootstrap/Button'
import { MdDeleteForever } from 'react-icons/md';

class Tests extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tests: [],
            schoolYears: [],
            selectedSchoolYear: null,
            showAddTestModal: false
        }
    }

    componentDidMount() {
        this.getTests()
        this.getSchoolYears()
    }

    getTests() {

        fetch(process.env.REACT_APP_API_URL + "test/" + this.props.studentId + "/student",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ tests: data })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }


    handlerOnChangeSchoolYear = e => {
        var value = e.target.value
        var schoolYear = this.state.schoolYears.filter(x => x.id == value)[0]
        console.log(schoolYear)
        this.setState({ selectedSchoolYear: schoolYear })
    }

    getSchoolYears() {
        fetch(process.env.REACT_APP_API_URL + "schoolyear",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                var first = data[0]
                this.setState({ schoolYears: data, selectedSchoolYear: first })
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    handlerChangePresentionType = (id, type) => {
        console.log("Retrieve id: " + id + " changing to " + type)
        var tests = this.state.tests
        var test = tests.filter(test => test.id === id)[0]
        test.presentionType = type


        if(Number.isInteger(id)){
            this.handleAddTest(test)
            return
        }
        
        this.updateTest(test)
    }


    handleAddTest(test) {
        console.log(test)
        delete test.id
        fetch(process.env.REACT_APP_API_URL + "test/" + this.props.studentId, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(test)
        }).then(data => {
            return data.json()
        }).then(result => {
            console.log(result)
            toastr.success('Verwerkt', 'De presentie is verwerkt.')
            this.getTests()
        }).catch((error) => {
            console.error(error);
        });
    }

    updateTest(test) {
        fetch(process.env.REACT_APP_API_URL + "test/" + test.id + "/" + this.props.studentId, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(test)
        }).then(data => {
            return data.json()
        }).then(result => {
            console.log(result)
            toastr.success('Verwerkt', 'De presentie is verwerkt.')
        }).catch((error) => {
            console.error(error);
        });
    }

    addTest() {
        var tests = this.state.tests
        var obj = {
            id: (tests.length +1),
            testType :"Physical",
            presentionType : "",
            schoolYear: this.state.selectedSchoolYear
        }

        tests.push(obj)

        this.setState({tests: tests})
    }

    handleChangeTestType = (id, e) => {
        var val = e.target.value


        var tests = this.state.tests
        var test = tests.filter(test => test.id === id)[0]

        test.testType = val

        
        this.updateTest(test)

    }

    deleteTest(testId) {
        fetch(process.env.REACT_APP_API_URL + "test/" + testId, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            }
        }).then(data => {
            return data.json()
        }).then(result => {
            console.log(result)
            this.getTests()
            toastr.success('Verwijderd', 'De test is verwijderd.')
        }).catch((error) => {
            console.error(error);
        });
    }

    render() {
        return (
            <Col>
                <Row>
                    <h7>Schooljaar:</h7>
                    <Form.Group as={Col} controlId="formGridState" xs={2}>
                        <Form.Control as="select" defaultValue={this.state.selectedSchoolYear} size="sm" onChange={this.handlerOnChangeSchoolYear}>
                            {
                                this.state.schoolYears.map(item => {
                                    return (
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </Row>

                {
                    this.state.tests.map(test => {


                        return (
                            (this.state.selectedSchoolYear === null) ? "" :
                                ((this.state.selectedSchoolYear.id === test.schoolYear.id) ?
                                    (<Row className="mb-2">
                                        <Col md="auto">
                                        <Form.Control as="select"  defaultValue={test.testType} onChange={(e) => this.handleChangeTestType(test.id, e)}>
                                            <option value="Digital">Digitale test</option>
                                            <option value="Physical">Fysieke test</option>
                                        </Form.Control>
                                        </Col>
                                        <Col md="auto">
                                            <PresentionsButtons
                                                id={test.id}
                                                value={test.presentionType}
                                                handlerChangePresentionType={this.handlerChangePresentionType} />
                                        </Col>
                                        <Col>
                                            <MdDeleteForever size={24} onClick={() => this.deleteTest(test.id)}/>
                                        </Col>
                                    </Row>) : "")
                        )

                    })
                }

                <Row>
                    <Button className='mb-2' onClick={() => this.addTest()}>+</Button>
                </Row>
            </Col>
        )
    }
}

export default Tests;