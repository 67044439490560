import React, { Component } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'

class Points extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allPoints: {}
        }
    }

    componentDidMount() {
        this.getPointsByStudent()
    }

    getPointsByStudent() {
        fetch(process.env.REACT_APP_API_URL + "student/criteria/"+ this.props.studentId +"/todo",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({allPoints: data})
            })
            .catch(error => {
                console.log(error);
                // localStorage.clear();
                // location.href = '/'
            });
    }

    render() {
        return (
            <Col>
                <Row>
                    <h5>Voortgang</h5>
                </Row>
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                {/* <th>Jaargang</th> */}
                                <th>Totaal</th>
                                <th>Minimaal</th>
                                <th>Sportbezoeken</th>
                                <th>Sportdagen</th>
                                <th>TJLS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {/* <td>Totaal</td> */}
                                <td>{this.state.allPoints.currentSportPoints + this.state.allPoints.currentSportDaysPoints + this.state.allPoints.currentTestPoints}</td>
                                <td>{this.state.allPoints.minimalSportDaysPoints + this.state.allPoints.minimalSportPoints +this.state.allPoints.minimalTestPoints}</td>
                                <td>{this.state.allPoints.currentSportPoints}</td>
                                <td>{this.state.allPoints.currentSportDaysPoints}</td>
                                <td>{this.state.allPoints.currentTestPoints}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
            </Col>
        )
    }
}

export default Points;
