import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import { toastr } from 'react-redux-toastr'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsPencil } from "react-icons/bs"
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ClassContentPage from '../../components/ClassContentPage'


class ManageClass extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showAddClassModal: false,
            showUpdateClassModal: false,
            showContentModal: false,
            periods: [],
            criterions: [],
            totalPoints: 0,
            totalPointsUpdate: 0,
            class: {
                name: "",
                mentor: "",
                minPointsPass: 0.0,
                minPointsGood: 0.0,
                periods: [],
                criterions: [],
                employee : { 
                    id: ""
                }
            },
            classes: [],
            searchResults: [],
            error: "",
            updateClass: {
                name: "",
                criterions: [],
                periods:[],
                employee : { 
                    id: ""
                }
            },
            employees: []
        }
    }

    initClass() {
        this.setState({
            class: {
                name: "",
                mentor: "",
                minPointsPass: 0.0,
                minPointsGood: 0.0,
                periods: [],
                criterions: [],
                employee : { 
                    id: ""
                }
            }, 
            totalPoints: 0,
            updateClass: {
                criterions: [],
                periods:[]
            }
        })
    }

    componentWillMount() {
        this.getPeriods()
        this.getCriterions()
        this.getClasses()
        this.getEmployees()
    }

    getEmployees() {
        fetch(process.env.REACT_APP_API_URL + "employee",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => this.setState({ employees: data }))
            .catch(error => {
                localStorage.clear();
                location.href = '/'
            });
    }

    getClasses() {
        fetch(process.env.REACT_APP_API_URL + "class",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ classes: data })
            })
            .catch(error => {
                console.log(error)
            });
    }

    addClassHandler = (e) => {
        const name = e.target.name
        const val = e.target.value
        const classObj = { ...this.state.class };

        classObj[name] = val

        this.setState({ class: classObj });
    }
    addClassEmployeeHandler = (e) => {
        const val = e.target.value
        const classObj = { ...this.state.class };

        classObj.employee.id = val

        this.setState({ class: classObj });
    }

    criteriaHandler = (e) => {
        const value = e.target.value

        var prevArray = this.state.criterions
        prevArray.map(item => {
            if (item.criteria.id === e.target.id) {
                item.minPoints = value
            }
        })

        this.setState({ criterions: prevArray }, () => this.calcTotalPoints())
    }

    calcTotalPoints() {
        var totalPoints = 0;

        this.state.criterions.map(x => {
            if (typeof (x.minPoints) !== "undefined") {
                totalPoints += parseInt(x.minPoints)
            }
        })
        this.setState({ totalPoints: parseInt(totalPoints) })
    }

    handleAddModalShowHide() {
        this.setState({ showAddClassModal: !this.state.showAddClassModal })
    }

    handleContentModalShowHide() {
        this.setState({ showContentModal: !this.state.showContentModal })
    }

    handleUpdateModalShowHide() {
        this.setState({ showUpdateClassModal: !this.state.showUpdateClassModal })
    }

    getCriterions() {
        fetch(process.env.REACT_APP_API_URL + "criteria",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                var array = []

                data.forEach(element => {
                    var criteria = { criteria: element, minPoints: 0.0 }
                    array.push(criteria)
                });
                this.setState({ criterions: array })
            })
            .catch(error => {
                console.log(error)
            });
    }

    getPeriods() {
        fetch(process.env.REACT_APP_API_URL + "period/v1",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                this.setState({ periods: data })
            })
            .catch(error => {
                console.log(error)
            });
    }

    selectPeriodHandler = (e) => {
        const id = e.target.name;
        const isChecked = e.target.checked;
        var prevPeriods = this.state.periods

        prevPeriods.map(element => {
            if (element.id === id) {
                element.checked = !element.checked
            }
        })

        this.setState({ periods: prevPeriods });
    }

    classSubmitHandler = (event) => {
        event.preventDefault();
        const classObj = { ...this.state.class }
        classObj.periods = []
        classObj.criterions = this.state.criterions

        var periods = this.state.periods

        periods.map(element => {
            if (element.checked) {
                classObj.periods.push({ period: element })
            }
        })
        this.addClass(classObj)
    }

    addClass(classObj) {
        console.log(classObj)
        fetch(process.env.REACT_APP_API_URL + "class", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(classObj)
        }).then(response => {
            if (response.ok) return response.json();

            return response.text().then(response => { throw new Error(response) })
        }).then(result => {
            this.initClass()
            toastr.success('Klas toegevoegd', 'Je hebt een nieuwe klas toegevoegd!')
            this.getClasses()
        }).catch((error) => {
            toastr.error('Klas toevoegen', error.message)
            console.log(error)
        });
    }

    deactivateClass(classObj) {
        console.log("Deactive: "+ classObj);
        fetch(process.env.REACT_APP_API_URL + "class/" + classObj.id + "/deactivate", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(classObj)
        }).then(response => {
            if (response.ok) return response.json();
            return response.text().then(response => { throw new Error(response) })
        }).then(result => {
            this.updateUI(result)
            toastr.success('Klas gedeactiveerd', 'Je hebt een klas gedeactiveerd!')
        }).catch((error) => {
            toastr.error('Klas gedeactiveerd', error.message)
            console.log(error)
        });
    }

    onClickUpdateClass(c){
        this.setState({updateClass: c}, () => {
            this.calcTotalPointsUpdate() 
            this.handleUpdateModalShowHide()
            var periods = []
            this.state.periods.map(period => {
                if(this.state.updateClass.periods.filter(x => x.period.id === period.id).length > 0) {
                    period.checked = true
                }    
                periods.push(period)
            })

            this.setState({periods: periods})
    })
    }

    onClickContentClass(c){
        this.setState({updateClass: c}, () => {
            this.handleContentModalShowHide()
    })
    }

    onChangeUpdateClass = (e) => {
        const name = e.target.name
        const val = e.target.value
        const classObj = { ...this.state.updateClass };

        classObj[name] = val

        this.setState({ updateClass: classObj });
    }

    updateClassEmployeeHandler = (e) => {
        const val = e.target.value
        const classObj = { ...this.state.updateClass };

        classObj.employee = {id : val}

        this.setState({ updateClass: classObj });
    }

    onChangeUpdatecriteria = (e) => {
        const value = e.target.value

        var prevArray = this.state.updateClass.criterions
        prevArray.map(item => {
            if (item.criteria.id === e.target.id) {
                item.minPoints = value
            }
        })

        var obj = this.state.updateClass
        obj["criterions"] = prevArray

        this.setState({ updateClass: obj }, () => this.calcTotalPointsUpdate())
    }

    calcTotalPointsUpdate() {
        var totalPoints = 0;

        this.state.updateClass.criterions.map(x => {
            if (typeof (x.minPoints) !== "undefined") {
                totalPoints += parseInt(x.minPoints)
            }
        })
        
        this.setState({ totalPointsUpdate: parseInt(totalPoints) })
    }

    updateClassSubmitHandler = (event) => {
        event.preventDefault();
        const classObj = { ...this.state.updateClass }
        classObj.periods = []

        var periods = this.state.periods

        periods.map(element => {
            if (element.checked) {
                classObj.periods.push({ period: element,classId:classObj.id })
            }
        })
        console.log(classObj)
        this.updateClass(classObj)
    }

    updateClass(classObj) {
        console.log(classObj)
        fetch(process.env.REACT_APP_API_URL + "class/"+classObj.id, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(classObj)
        }).then(response => {
            if (response.ok) return response.json();

            return response.text().then(response => { throw new Error(response) })
        }).then(result => {
            this.initClass()
            this.handleUpdateModalShowHide()
            this.updateUI(result)
            toastr.success('Klas wijzigen', 'Je hebt een klas gewijzigd!')
        }).catch((error) => {
            toastr.error('Klas wijzigen', error.message)
            console.log(error)
        });
    }

    updateUI(obj) {
        console.log(obj)
        var classes = this.state.classes

        var idx = classes.findIndex(item => item.id === obj.id)

        classes[idx] = obj

        this.setState({classes: classes})
    }

    render() {
        const columns = [{
            dataField: 'id',
            text: 'id',
            hidden: true
          }, {
            dataField: 'name',
            text: 'Klascode',
            filter: textFilter()
          }, {
            dataField: 'mentor',
            text: "SLB'er"
          }, {
            dataField: 'schoolYear.name',
            text: 'Schooljaar'
          }, {
            dataField: 'active',
            text: 'Status',
            sort: true,
            formatter: (cell) => {
             return cell ? ("Actief") : "Inactief"
           },
          }, {
            dataField: 'name',
            text: 'Wijzigen',
            formatter: (cell, row) => {
                console.log(row)
             return row.active ? (<BsPencil onClick={() => this.onClickUpdateClass(row)}/>) : "NVT"
           }
          }, {
            dataField: 'name',
            text: 'Content',
            formatter: (cell, row) => {
                console.log(row)
             return row.active ? (<BsPencil onClick={() => this.onClickContentClass(row)}/>) : "NVT"
           }
          }, {
            dataField: 'name',
            text: 'Deactiveren',
            formatter: (cell, row) => {
             return row.active ? (<RiDeleteBin6Line onClick={() => this.deactivateClass(row)}/>) : "NVT"
           }
          }];

          const defaultSorted = [{
            dataField: 'active',
            order: 'desc'
          }];

        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Klassen beheren</h1>
                    <Row>
                        <Col>
                            <Button onClick={() => this.handleAddModalShowHide()}>+</Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <BootstrapTable bootstrap4 keyField='id' data={ this.state.classes } columns={ columns } filter={ filterFactory() } defaultSorted={ defaultSorted } />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal show={this.state.showAddClassModal}>
                <Form onSubmit={this.classSubmitHandler}>
                    <Modal.Header closeButton onClick={() => this.handleAddModalShowHide()}>
                        <Modal.Title>Klas toevoegen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Algemeen</h5>
                        <hr />
                        <Form.Group controlId="name">
                            <Form.Label>Klas code</Form.Label>
                            <Form.Control type="text" placeholder="code" name="name" value={this.state.class.name} onChange={this.addClassHandler} required />
                        </Form.Group>

                        <Form.Group controlId="mentor">
                            <Form.Label>SLB'er</Form.Label>
                            <Form.Control type="text" placeholder="naam van de SLB'er" value={this.state.class.mentor} name="mentor" onChange={this.addClassHandler} required />
                        </Form.Group>

                        <Form.Group controlId="addEmployee">
                                    <Form.Label>Contactpersoon sportbureau </Form.Label>
                                    <Form.Control name="employee" value={this.state.class?.employee?.id} onChange={this.addClassEmployeeHandler}as="select" required custom>
                                        <option value="select">Selecteer</option>
                                        {
                                            this.state.employees.map(item => {
                                                return (
                                                    <option value={item.id}>{item.fullName}</option>
                                                )
                                            })
                                        }
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        Geef hier aan wie de contactpersoon vanuit het sportbureau is
                                    </Form.Text>
                                </Form.Group>

                        <h5>Opbouw punten</h5>
                        <hr />
                        {
                            this.state.criterions.map(item => {
                                return (
                                    <Form.Group as={Row} controlId={item.criteria.id}>
                                        <Form.Label column sm={9}>
                                            Aantal te behalen punten door {item.criteria.subject}
                                        </Form.Label>
                                        <Col sm={3}>
                                            <Form.Control type="number" min="0" value={item.minPoints} name={item.criteria.id} required onChange={this.criteriaHandler} />
                                        </Col>
                                    </Form.Group>
                                )
                            })
                        }

                        <Form.Group as={Row} controlId="total">
                            <Form.Label column sm={9}>
                                <b>Totaal aantal punten</b>
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="number" min="0" value={this.state.totalPoints} disabled />
                            </Col>
                        </Form.Group>

                        <h5>Verdeling punten</h5>
                        <hr />
                        <Form.Group as={Row} controlId="minPointsPass">
                            <Form.Label column sm={9}>
                                Minmaal aantal punten voor een voldoende
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="number" min="0" value={this.state.class.minPointsPass} required name="minPointsPass" onChange={this.addClassHandler} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="minPointsGood">
                            <Form.Label column sm={9}>
                                Minmaal aantal punten voor een goed
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="number" min="0"  value={this.state.class.minPointsGood}  required name="minPointsGood" onChange={this.addClassHandler} />
                            </Col>
                        </Form.Group>

                        <h5>Doet mee aan de perioden</h5>
                        <hr />
                        {
                            this.state.periods.map((period, idx) => {
                                return (<Form.Group as={Row} controlId={period.id}>
                                    <Form.Label column sm={3}>
                                        {period.name}
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control type="checkbox" name={period.id} onChange={this.selectPeriodHandler} />
                                    </Col>
                                </Form.Group>)
                            })
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleAddModalShowHide()}>
                            Sluit
                    </Button>
                        <Form.Group>
                            <Button type='submit'>
                                Toevoegen
                             </Button>
                        </Form.Group>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={this.state.showUpdateClassModal}>
                <Form onSubmit={this.updateClassSubmitHandler}>
                    <Modal.Header closeButton onClick={() => this.handleUpdateModalShowHide()}>
                        <Modal.Title>Klas wijzigen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Algemeen</h5>
                        <hr />
                        <Form.Group controlId="name">
                            <Form.Label>Klas code</Form.Label>
                            <Form.Control type="text" placeholder="code" name="name" value={this.state.updateClass.name} onChange={this.onChangeUpdateClass} required />
                        </Form.Group>

                        <Form.Group controlId="mentor">
                            <Form.Label>SLB'er</Form.Label>
                            <Form.Control type="text" placeholder="naam van de SLB'er" value={this.state.updateClass.mentor} name="mentor" onChange={this.onChangeUpdateClass} required />
                        </Form.Group>

                        <Form.Group controlId="updateEmployee">
                                    <Form.Label>Contactpersoon sportbureau </Form.Label>
                                    <Form.Control name="employee" value={this.state.updateClass?.employee?.id} onChange={this.updateClassEmployeeHandler}as="select" required custom>
                                        <option value="select">Selecteer</option>
                                        {
                                            this.state.employees.map(item => {
                                                return (
                                                    <option value={item.id}>{item.fullName}</option>
                                                )
                                            })
                                        }
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        Geef hier aan wie de contactpersoon vanuit het sportbureau is
                                    </Form.Text>
                                </Form.Group>

                        <h5>Opbouw punten</h5>
                        <hr />
                        {
                            this.state.updateClass.criterions.map(item => 
                               
                                    <Form.Group as={Row} controlId={item.criteria.id}>
                                        <Form.Label column sm={9}>
                                            Aantal te behalen punten door {item.criteria.subject}
                                        </Form.Label>
                                        <Col sm={3}>
                                            <Form.Control type="number" min="0" value={item.minPoints} name={item.criteria.id} required onChange={this.onChangeUpdatecriteria} />
                                        </Col>
                                    </Form.Group>
                            )
                        }

                        <Form.Group as={Row} controlId="total">
                            <Form.Label column sm={9}>
                                <b>Totaal aantal punten</b>
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="number" min="0" value={this.state.totalPointsUpdate} disabled />
                            </Col>
                        </Form.Group>

                        <h5>Verdeling punten</h5>
                        <hr />
                        <Form.Group as={Row} controlId="minPointsPass">
                            <Form.Label column sm={9}>
                                Minmaal aantal punten voor een voldoende
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="number" min="0" required value={this.state.updateClass.minPointsPass} name="minPointsPass" onChange={this.onChangeUpdateClass} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="minPointsGood">
                            <Form.Label column sm={9}>
                                Minmaal aantal punten voor een goed
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control type="number" min="0" required value={this.state.updateClass.minPointsGood}  name="minPointsGood" onChange={this.onChangeUpdateClass} />
                            </Col>
                        </Form.Group>

                        <h5>Doet mee aan de perioden</h5>
                        <hr />
                        {
                            this.state.periods.map((period, idx) => 
                                    <Form.Group as={Row} controlId={period.id}>
                                        <Form.Label column sm={3}>
                                            {period.name}
                                        </Form.Label>
                                        <Col sm={9}>
                                            {
                                                (period.checked) ? 
                                                <Form.Control type="checkbox" name={period.id} onChange={this.selectPeriodHandler} checked/>
                                                :
                                                <Form.Control type="checkbox" name={period.id} onChange={this.selectPeriodHandler} />

                                            }
                                        </Col>
                                    </Form.Group>
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleUpdateModalShowHide()}>
                            Sluit
                    </Button>
                        <Form.Group>
                            {/* <Button variant='danger'>
                                Functie uitgeschakeld
                             </Button> */}
                            <Button type='submit'>
                                Wijzigen
                             </Button>
                        </Form.Group>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={this.state.showContentModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Content beheren Klas: {this.state.updateClass.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <ClassContentPage class={this.state.updateClass}/>
                </Modal.Body>
                <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleContentModalShowHide()}>
                            Sluiten
                            </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    }
}

export default ManageClass;