import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../stylesheets/login.css'
import '../stylesheets/spinner.css'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Logo from '../images/rvtlogorgb.png';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import history from '../history';
import cookies from 'js-cookie';
import {CgSpinnerTwoAlt} from "react-icons/cg"

//Authentication
import {
  RedirectRequestHandler,
  LocalStorageBackend, DefaultCrypto
} from '@openid/appauth';
import {
  AuthorizationServiceConfiguration,
  AuthorizationRequest,
  FetchRequestor,
} from '@openid/appauth';
import { NoHashQueryStringUtils } from '../noHashQueryStringUtils'


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      display: "d-none",
      email: '',
      displayDangerAlert: 'd-none',
      displaySuccessAlert: 'd-none',
      successMessage: '',
      showSpinner: false
    };

    console.log("Current environment: " + process.env.REACT_APP_ENVIRONMENT)
  }

  changeDisplay = () => {

    if (this.state.display == "d-none") {
      this.setState({ display: "d-block" });
    } else {
      this.setState({ display: "d-none" });
      this.setState({ displayDangerAlert: "d-none" });
      this.setState({ displaySuccessAlert: "d-none" });
    }
  }

  externLoginButtion = (event) => {
    event.preventDefault();
    var mail = this.state.email;

    if (mail == '') {
      //Mail is empty
      this.setState({ displayDangerAlert: "d-block" });
    } else {
      //Mail isn't empty
      this.setState({ displayDangerAlert: "d-none", showSpinner: true });

      fetch(process.env.REACT_APP_API_URL + "auth/clinic", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ EmailAddress: mail, RedirectUrl: process.env.REACT_APP_REDIRECT_URL_MAIN + "/verify" })
      })
        .then(data => {
          return data.json()
        })
        .then(result => {
          this.setState({ successMessage: result.msg });
          this.setState({ displaySuccessAlert: "d-block", showSpinner: false  });
        }).catch(ex => {
          this.setState({showSpinner: true  });
        });
    }
  }

  ChangeEmailExtern = (event) => {
    this.setState({ email: event.target.value });
  }

  render() {
    const authorizationHandler =
      new RedirectRequestHandler(
        new LocalStorageBackend(),
        new NoHashQueryStringUtils(),
        window.location,
        new DefaultCrypto()
      );

    this.externLogin = (
      <div>
        <Form.Group className={this.state.display}>
          <a href="https://sportopmaat.impercode.com/manuels/Handleiding%20VolgLink.pdf" target="_blank"  className="float-right">gebruikershandleiding</a><br/>
          <Form.Label>E-mailadres:</Form.Label>
          <Form.Control type="email" placeholder="E-mailadres" onChange={this.ChangeEmailExtern} />
        </Form.Group>
        <Button variant="outline-primary" onClick={this.externLoginButtion} className={this.state.display} disabled={this.state.showSpinner} block>
          {
            this.state.showSpinner && (<CgSpinnerTwoAlt className="icon-spin mr-2"/>)
          }
          Inloggen</Button>{' '}
      </div>
    )

    this.dangerAlert = (<Alert variant="danger" className={this.state.displayDangerAlert}>
      E-mail mag niet leeg zijn.
                      </Alert>)

    this.successAlert = (<Alert variant="success" className={this.state.displaySuccessAlert}>
      {this.state.successMessage}
    </Alert>)

    // const [showExternLogin, setShowLoginResults] = React.useState(false)
    function employeeBtn() {
      AuthorizationServiceConfiguration.fetchFromIssuer(process.env.REACT_APP_ISSUER, new FetchRequestor())
        .then((response) => {
          console.log(response)

          const authRequest = new AuthorizationRequest({
            client_id: process.env.REACT_APP_CLIENTID,
            redirect_uri: process.env.REACT_APP_REDIRECT_URL,
            scope: 'openid',
            response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
            // extras: environment.extra
          });

          authorizationHandler.performAuthorizationRequest(response, authRequest);

        })
        .catch(error => {
          console.log(error);
        });
    }

    return (
      <div className="center">
        <Container className="w-25 p-3">
          <img src={Logo} alt="Logo" className="w-100" />
          <h5 className="w-100 text-center">Inloggen BackOffice Sport op Maat</h5>
          <Form.Group>
            <Button variant="outline-primary" onClick={employeeBtn} block>Inloggen medewerkers ROCvT</Button>{' '}
            <Button variant="outline-primary" onClick={this.changeDisplay} block>Inloggen externe bedrijven/verenigingen</Button>{' '}
          </Form.Group>
          {this.externLogin}

          {this.successAlert}
          {this.dangerAlert}

        </Container>
      </div>
    );
  }
}

export default Login;
