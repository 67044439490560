import React from 'react'
import { Redirect } from 'react-router-dom'
import JsCookie from 'js-cookie'
import Cookies from 'universal-cookie';
import * as QueryString from "query-string"

class ProtectedExternRoute extends React.Component {
    
    constructor(props){  
        super(props);  
        // console.log("Congratulations we are in the router")
        // const cookies = new Cookies();

        // const parsed = QueryString.parse(location.search);

        // var token = parsed.token
        // var id = parsed.id
        // var name = parsed.name

        // if(token !== undefined)
        // {
        //     // cookies.set('somexterntoken', token, { path: '/' });
        //     // cookies.set('som.extern.token', token, { path: '/'});
        // }

        // if(id !== undefined)
        // {
        //     cookies.set('id', id, { path: '/' });
        // }
        // if(name !== undefined)
        // {
        //     cookies.set('name', name, { path: '/' });
        // }


      }  

    render() {
        // const cookies = new Cookies();
        const Component = this.props.component;
        var isAuthenticated = true;

        if(JsCookie.get('som.extern.token') === undefined) {
            isAuthenticated = true
        }
       
        return isAuthenticated ? (
            <Component />
        ) : (
            <Redirect to={{ pathname: '/' }} />
        );
    }
}

export default ProtectedExternRoute;